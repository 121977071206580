import React from 'react';
import Dialog from '../../shared/components/Dialog';
import Spinner from '../../shared/components/Spinner';
import Alert from '../../shared/components/Alert';
import {injectIntl, FormattedMessage} from 'react-intl';

const ForgotPassword = ({
  cancel,
  requestPasswordReset,
  loading,
  email,
  setEmail,
  message,
  error,
  intl
}) => (
  <Dialog
    onConfirm={requestPasswordReset}
    onDismiss={cancel}
    enableConfirm={!loading && !!email && !message && !error}
    size="small"
    confirmLabel="Submit"
    title= {intl.formatMessage({id:'pages.forgot_password_title'})}>
    <p>
    <FormattedMessage id="pages.forgot_password_prompt"/>
    </p>
    <input
      className="form-control"
      type="text"
      placeholder="Email"
      value={email}
      onChange={e => setEmail(e.target.value)}
    />
    <p>
      <FormattedMessage id="pages.forgot_password_tel"/>
      <a href="tel:888.328.2454">888.328.2454</a>
    </p>
    <Alert error={error} success={message} />
    {loading && <Spinner />}
  </Dialog>
);

export default injectIntl(ForgotPassword);