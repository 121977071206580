import {makeStyles, withStyles} from '@material-ui/core/styles';
import {StepConnector} from '@material-ui/core';

export const workOrderTypeStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
    // margin: '20px 0 20px'
  },
  paper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: 20,
    paddingLeft: 0,
    justifyContent: 'space-around'
  },
  stepLabel: {
    '& span': {
      fontFamily: 'Nunito',
      fontSize: '1.5rem',
      '& span.MuiStepLabel-label.MuiStepLabel-active': {
        fontWeight: '700',
        color: '#797979'
      }
    },
    '& span.value': {
      fontSize: '90%',
      marginLeft: '2em',
      color: '#797979',
      fontWeight: '700'
    }
  },
  finalButton: {
    backgroundColor: '#5b8a72',
    fontFamily: 'Nunito',
    fontSize: '1.2rem',
    '&:hover': {
      backgroundColor: '#464f41'
    }
  },
  activeStep: {
    backgroundColor: '#e4002b',
    color: '#e4002b'
  },
  categoryContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '90%',
    justifyContent: 'space-between',
    margin: '20px 0 20px'
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& div.new-ticket': {
      width: '100%'
    }
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 20,
    width: '100%',
    marginTop: 20
  },
  line: {
    width: 5,
    height: 28,
    backgroundColor: '#e4002b',
    borderRadius: 1,
    marginRight: 10
  },
  date: {
    fontFamily: 'Nunito',
    fontSize: '2.3rem',
    fontWeight: 600,
    lineHeight: 1.33,
    textAlign: 'left',
    color: '#686b6b',
    margin: 0
  }
}));

export const StyledStepperConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)'
  },
  active: {
    '& $line': {
      borderColor: '#e4002b'
    }
  },
  completed: {
    '& $line': {
      borderColor: '#e4002b'
    }
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1
  }
})(StepConnector);
