import React from 'react';
import {Paper} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Link} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import moment from 'moment';

export default function MobileWorkOrders({rows, totalCount, loadMoreRows}) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {rows.map(
        (
          {
            TicketNumber,
            ReceivedDate,
            StoreNumber,
            Status,
            CreateDate,
            Urgency,
            Area,
            DescriptionOfWorkShort,
            ...rest
          },
          index
        ) => (
          <Link
            key={rest.id}
            to={`/ticket/${rest.id}`}
            style={{width: '100%'}}
            className={classes.link}>
            <Paper
              className={classes.paperContainer}
              key={`mi${rest.TicketNumber}${index}`}
              elevation={6}>
              <div className={classes.headerContainer}>
                <div className={classes.leftHeaderContainer}>
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                    <div style={{marginBottom: 3}}>
                      <span className={classes.statusSpan}>
                        {Status ? Status : 'Undefined'}
                      </span>
                      <span style={{color: '#686b6b'}}>#{TicketNumber}</span>
                    </div>
                    <div>
                      <span style={{color: '#686b6b'}}>{Urgency}</span>
                    </div>
                  </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  <span style={{color: '#686b6b'}}>
                    {moment(CreateDate).fromNow()}
                  </span>
                  <span style={{color: '#686b6b'}}>{Area}</span>
                </div>
              </div>
              <div className={classes.titleContainer}>
                {/*
                <h3 className={classes.titleLabel}>
                  {StoreNumber ? StoreNumber : 'Store Number'}
                </h3>
                */}
                {/* <h4 className={classes.subtitleLabel}>
                {Area}
                </h4> */}
                <h4>{DescriptionOfWorkShort}</h4>
              </div>
            </Paper>
          </Link>
        )
      )}
      {rows.length < totalCount && (
        <button onClick={loadMoreRows} className={classes.loadMore}>
          <FormattedMessage id="tickets.load_more" />...
        </button>
      )}
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(1),
    justifyContent: 'center',
    alignItems: 'center'
  },
  link: {
    width: '90%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  paperContainer: {
    width: '98%',
    borderRadius: 20,
    minHeight: 120,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  titleContainer: {
    marginTop: theme.spacing(3)
  },
  titleLabel: {
    fontFamily: 'Nunito',
    fontSize: '1.8rem',
    margin: 0,
    lineHeight: 1.33,
    color: '#e4002b',
    textShadow: '0 1.5px 3px #00000029',
    fontWeight: '500'
  },
  subtitleLabel: {
    fontFamily: 'Nunito',
    fontSize: '1.2rem',
    lineHeight: 1.36,
    color: '#686b6b'
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between'
  },
  leftHeaderContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  statusSpan: {
    backgroundColor: '#ededed',
    width: 35,
    height: 15,
    fontFamily: 'Nunito',
    fontSize: '1.2rem',
    fontWeight: 600,
    color: '#686b6b',
    padding: '3px 5px 3px 5px'
  },
  loadMore: {
    textTransform: 'uppercase'
  }
}));
