import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import InputWrapper from './InputWrapper';
import {styles} from '../styles';

/**
 * <Textarea />
 */
const Textarea = props => {
  const classes = props.classes;
  //var inputType = props.type ? props.type : 'text';
  const inputProps = props.input ? props.input : props;
  // carry over some of the props, even if we were given an "input" prop
  if (props.maxLength) inputProps.maxLength = props.maxLength;
  if (props.readOnly) inputProps.readOnly = props.readOnly;
  return (
    <InputWrapper {...props}>
      <textarea
        className={classes.textarea}
        {...inputProps}
        rows={props.rows}
      />
    </InputWrapper>
  );
};

export default withStyles(styles)(Textarea);
