// queries for the ACI cascading dropdown

import {gql, graphql, compose} from 'react-apollo';
import update from 'immutability-helper';

const CATEGORY_QUERY = gql`
  query GetCategories($area: String!) {
    getCategories(area: $area)
  }
`;

const ISSUE_QUERY = gql`
  query GetIssues($area: String!, $category: String!) {
    getIssues(area: $area, category: $category)
  }
`;

// HOC used to provide the Category and Issue picklist in the corresponding fieldMetadata
// (the Area picklist is already provided with the form metadata since it's constant)
export default compose(
  // x => props => {
  //   console.log(props)
  //   return x
  // },
  graphql(CATEGORY_QUERY, {
    options: ({area}) => ({
      variables: {
        area
      }
    }),
    skip: ({fieldMetadata, area}) =>
      !fieldMetadata || !fieldMetadata.Category || !area,
    props: ({ownProps, data}) => ({
      loadingCategories: data.loading,
      error: ownProps.error || data.error,
      disableCategory:
        !data.loading && data.getCategories && data.getCategories.length === 0,
      fieldMetadata:
        ownProps.fieldMetadata &&
        update(ownProps.fieldMetadata, {
          Category: {picklist: {$set: data.getCategories}}
        })
    })
  }),
  graphql(ISSUE_QUERY, {
    options: ({area, category}) => ({
      variables: {
        area,
        category
      }
    }),
    skip: ({fieldMetadata, area, category}) =>
      !fieldMetadata || !fieldMetadata.Issue || !area || !category,
    props: ({ownProps, data}) => ({
      loadingIssues: data.loading,
      error: ownProps.error || data.error,
      fieldMetadata:
        ownProps.fieldMetadata &&
        update(ownProps.fieldMetadata, {
          Issue: {picklist: {$set: data.getIssues}}
        })
    })
  })
);
