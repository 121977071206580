import React from 'react';
import styled from 'styled-components';
import BlankProfile from './blank-profile.jpg';
import {FormattedMessage, injectIntl} from 'react-intl';

const Contact = ({contact, intl}) => (
  <Container className="col-xs-12 col-sm-6">
    <div className="panel">
      <div className="panel-body">
        <div className="media">
          <div className="pull-left">
            <img
              alt={intl.formatMessage({id: 'contact.photo'})}
              className="thumb media-object"
              src={contact.ProfilePicture || BlankProfile}
            />
          </div>
          <div className="media-body">
            <h4>{contact.DisplayName}</h4>
            <address>
              <strong>Cushman &amp; Wakefield</strong>
              <br />
              128 N. 1<sup>st</sup> St
              <br />
              Wichita, KS 67030
              <br />
              {contact.Phone && (
                <Info>
                  <abbr title={intl.formatMessage({id: 'contact.phone'})}>
                    {' '}
                    <FormattedMessage id="contact.phone_abbr" />{' '}
                  </abbr>
                  <a href={'tel:' + contact.Phone.replace(/[^0-9]/g, '')}>
                    {contact.Phone}
                  </a>
                </Info>
              )}
              {/* {contact.Mobile && */}
              {/*     <Info> */}
              {/*       <abbr title="Mobile">M:</abbr>{contact.Mobile} */}
              {/*     </Info> */}
              {/* } */}
              {contact.Email && (
                <Info>
                  <abbr title="Email">E:</abbr>
                  <a href={'mailto:' + contact.Email}>{contact.Email}</a>
                </Info>
              )}
            </address>
          </div>
        </div>
      </div>
    </div>
  </Container>
);

const Container = styled.div`
  .panel {
    margin-top: 30px;
    // max-width: 60rem;
  }
  abbr {
    margin-right: 1em;
  }
  .media .pull-left {
    max-width: 40%;
  }
  .media img {
    width: 100%;
  }
`;
const Info = styled.div`
  margin-top: 0.8em;
`;

export default injectIntl(Contact);
