// global style constants
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {primaryColor, secondaryColor} from '../../constants';
import transitionProps from '../hoc/transitionProps.js';
import switchByProp from '../../utils/switchByProp';
import ClearButton from './ClearButton';

export const PositioningContainer = styled.div`
  position: relative;
`;

export const FadeTransition = transitionProps(styled.div`
  transition: opacity 0.3s;
  display: ${switchByProp({
    entering: 'block',
    entered: 'block',
    exiting: 'block',
    default: 'none'
  })};
  opacity: ${switchByProp({entering: 0, entered: 1, default: 0})};
`);

export const CurtainTransition = transitionProps(styled.div`
  transition: max-height 0.3s;
  max-height: ${switchByProp({
    entering: ({maxHeight}) => maxHeight || '500px',
    exiting: ({maxHeight}) => maxHeight || '500px',
    entered: 'none',
    default: 0
  })};
  overflow: hidden;
`);

// for a button with 3 states: expanded, active, default
export const ThreeStateButton = styled(ClearButton)`
  color: ${switchByProp({expanded: 'blue', active: 'red', default: 'inherit'})};
`;

export const Hr = styled.hr`
  clear: both;
  height: 10px;
  margin: 0 0 10px 0;
  border-top: none;
  border-bottom: 1px solid #eee;
`;
export const LinkInForm = styled(Link)`
  label {
    cursor: pointer;
  }
  margin: 0.2em 0 0 0;
  display: block;
`;
export const LabelInForm = styled.label`
  display: block;
  margin: 0.2em 0 0 0;
`;

export const LoginHeader = styled.h2`
  background-color: ${primaryColor} !important;
`;
export const LoginButton = styled.button`
  background-color: ${secondaryColor} !important;
`;
export const LogoutButton = styled.a`
  background-color: ${secondaryColor} !important;
`;
export const Overlay = styled(FadeTransition)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
`;
export const ModalPanel = styled(FadeTransition)`
  position: absolute;
  top: 3rem;
  right: 1rem;
  // avoid taking full width on the grid screen
  width: calc(100% - 2rem);
  max-width: 85rem;
  z-index: 20;
`;
export const styles = {
  input: {
    height: 35,
    paddingLeft: 10
  },
  textarea: {
    paddingLeft: 10
  },
  paperCard: {
    padding: '8px 9px 23px 8.5px',
    margin: '6.5px 0',
    borderRadius: '20px',
    backgroundColor: '#fff'
  }
};
