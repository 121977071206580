import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {Button} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import {STEP_DETAILS} from './constants';

function NavigationButtons({
  activeStep,
  handleBack,
  handleSubmit,
  activeCategory
}) {
  const classes = useStyles();
  return (
    <div className={classes.actionsContainer}>
      {activeStep !== 0 ? (
        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          className={classes.button}>
          Back
        </Button>
      ) : null}
      {activeStep === STEP_DETAILS ? (
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={activeCategory === null}
          className={clsx(classes.nextButton, {
            [classes.finalButton]: activeStep === 2,
            [classes.nextButton]: activeStep !== 2
          })}>
          <FormattedMessage id="tickets.save" />
        </Button>
      ) : null}
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  button: {
    fontFamily: 'Nunito',
    fontSize: '1.2rem',
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  nextButton: {
    fontFamily: 'Nunito',
    fontSize: '1.2rem',
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: '#e4002b',
    '&:hover': {
      backgroundColor: '#a6192e'
    }
  },
  finalButton: {
    backgroundColor: '#5b8a72',
    fontFamily: 'Nunito',
    fontSize: '1.2rem',
    '&:hover': {
      backgroundColor: '#464f41'
    }
  },
  actionsContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

NavigationButtons.propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

export default NavigationButtons;
