/*
 * Client side validation of selections on the Insert Ticket screen
 */
const EMAIL_REGEX = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/;
export default function validateNewTicket(values, {intl, fieldMetadata}) {
  const errors = {};
  // some hard coded validations...
  if (!values.Urgency)
    errors.Urgency = intl.formatMessage({
      id: 'tickets.validate_new_ticket_error'
    });
  if (!values.Account || !values.Account.value)
    errors.Account = intl.formatMessage({
      id: 'tickets.validate_new_ticket_error'
    });
  if (!values.Contact || !values.Contact.value)
    errors.Contact = intl.formatMessage({
      id: 'tickets.validate_new_ticket_error'
    });
  if (!values.DescriptionOfWorkInput)
    errors.DescriptionOfWorkInput = intl.formatMessage({
      id: 'tickets.validate_new_ticket_error'
    });
  if (
    !values.StoreContactPhone &&
    (fieldMetadata && fieldMetadata.StoreContactPhone.required !== false)
  )
    errors.StoreContactPhone = intl.formatMessage({
      id: 'tickets.validate_new_ticket_error'
    });
  if (!values.StoreContactEmail || !EMAIL_REGEX.test(values.StoreContactEmail))
    errors.StoreContactEmail = intl.formatMessage({
      id: 'tickets.validate_invalid_email'
    });
  if (!values.RequestedByName)
    errors.RequestedByName = intl.formatMessage({
      id: 'tickets.validate_new_ticket_error'
    });
  else if (!/\w+\s+\w+/.test(values.RequestedByName))
    errors.RequestedByName = intl.formatMessage({
      id: 'tickets.utils_validate_new_ticket_name'
    });
  // validation of required fields, set from metadata
  if (fieldMetadata) {
    for (let k in fieldMetadata) {
      if (fieldMetadata[k].required && !values[k] && !errors[k]) {
        errors[k] = intl.formatMessage({
          id: 'tickets.validate_new_ticket_error'
        });
      }
    }
  }
  if (
    values.SelfPerformedTicket &&
    (!values.VendorContact || !values.VendorContact.value)
  ) {
    errors.VendorContact = intl.formatMessage({
      id: 'tickets.validate_new_ticket_error'
    });
  }
  return errors;
}
