import React from 'react';
import {Link, Route} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Spinner from '../../shared/components/Spinner';
import Logo from '../../shared/components/Logo';
import TextInput from '../../shared/components/TextInput';
import ForgotPasswordPage from '../containers/ForgotPasswordPageContainer';
import ResetPasswordPage from '../containers/ResetPasswordPageContainer';
import LanguageSwitcher from '../components/LanguageSwitcher';
import {injectIntl, FormattedMessage} from 'react-intl';
import PrimaryButton from '../../shared/components/PrimaryButton';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 60,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
      marginTop: 20,
      height: '100%'
    }
  },
  container: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  loginView: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: 80,
    boxShadow: '6px 3px 10px 6px #CCCBCA',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    [theme.breakpoints.down('sm')]: {
      height: 47,
      position: 'relative',
      display: 'flex'
    }
  },
  logoLabel: {
    color: '#A6192E',
    fontSize: 28,
    fontFamily: 'Nunito'
  },
  leftImage: {
    height: 500,
    maxWidth: '100%',
    objectFit: 'cover',
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20
  },
  buttonContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    marginTop: 40
  },
  formGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 500,
    position: 'relative',
    flexDirection: 'column'
  },
  paper: {
    width: '100%',
    borderRadius: 20
  },
  labelContainer: {
    marginTop: 20
  },
  helpLabel: {
    fontFamily: 'Nunito',
    fontWeight: 'normal',
    fontSize: 12.8,
    textAlign: 'left',
    color: '#707070'
  },
  callLabel: {
    fontFamily: 'Nunito',
    fontWeight: 'normal',
    fontSize: 12.8,
    textAlign: 'left',
    color: '#e4002b'
  },
  bottomInfo: {
    marginTop: 10,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center'
    }
  },
  callAndLanguageContainer: {
    display: 'flex',
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  }
}));

const SignInPage = ({
  user,
  setUser,
  password,
  setPassword,
  login,
  error,
  loading,
  intl
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.loginView}>
        <Logo />
      </div>
      <Grid container className={classes.root} spacing={2}>
        <Grid
          item
          xs={11}
          sm={11}
          md={10}
          lg={8}
          xl={7}
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            flexDirection: 'column'
          }}>
          <Paper elevation={2} className={classes.paper}>
            <Grid container>
              <Hidden smDown>
                <Grid item md={6} xl={6} style={{margin: 0, padding: 0}}>
                  <img
                    src={'/img/login.jpg'}
                    className={`img-fluid ${classes.leftImage}`}
                    alt={intl.formatMessage({id: 'pages.sign_in_title'})}
                  />
                </Grid>
              </Hidden>
              <Grid
                item
                sm={12}
                md={6}
                xs={12}
                xl={6}
                className={classes.formGrid}>
                <div style={{width: '100%', justifyContent: 'flex-start'}}>
                  <img src={'/img/cw_logo_color.png'} />
                </div>
                <form style={{width: '90%'}}>
                  <div className="login-wrap">
                    <TextInput
                      name={intl.formatMessage({id: 'pages.sign_in_login'})}
                      onChange={e => setUser(e.target.value)}
                      value={user}
                    />
                    <TextInput
                      name={intl.formatMessage({id: 'pages.sign_in_password'})}
                      onChange={e => setPassword(e.target.value)}
                      type="password"
                      value={password}
                    />
                    {error && <p className="error">{error}</p>}
                    {loading && <Spinner />}
                    <Link to="/login/forgotPassword">
                      <FormattedMessage id="pages.sign_in_forgot_password" />
                    </Link>
                    <div className={classes.buttonContainer}>
                      <PrimaryButton onClick={login}>
                        <FormattedMessage id="shared.submit" />
                      </PrimaryButton>
                    </div>
                    <Hidden smDown>
                      <LanguageSwitcher
                        intl={intl}
                        style={{position: 'absolute', top: 20, right: 20}}
                      />
                    </Hidden>
                  </div>
                  <Route
                    path="/login/forgotPassword"
                    component={ForgotPasswordPage}
                  />
                  <Route
                    path="/login/resetPassword/:token"
                    component={ResetPasswordPage}
                  />
                </form>
              </Grid>
            </Grid>
          </Paper>
          <div className={classes.bottomInfo}>
            <Hidden smDown>
              <div style={{display: 'flex', flex: 1, width: '100%'}} />
            </Hidden>
            <div className={classes.callAndLanguageContainer}>
              <span className={classes.labelContainer}>
                <span className={classes.helpLabel}>
                  Need help logging in?{' '}
                </span>
                <a
                  href="tel:18883282454"
                  aria-roledescription="phone number"
                  className={classes.callLabel}>
                  Call 1-888-328-2454
                </a>
              </span>
              <Hidden mdUp>
                <LanguageSwitcher
                  intl={intl}
                  style={{margin: '10px 0px 10px 0px'}}
                />
              </Hidden>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default injectIntl(SignInPage);
// vim ft=javascript.jsx
