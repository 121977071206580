import forEach from 'lodash/forEach';
import Authentication from '../../auth/utils/Authentication';

// Attachment service
const Attachments = {
  // fetch an authenticated URL from the API server
  getDownloadURL(attachmentId) {
    return '/api/attachments/' + encodeURIComponent(attachmentId);
    // return 'https://www.dmv.ca.gov/web/eng_pdf/dl600.pdf'
    // return Authentication.call('attachment', 'POST', {
    //   id: attachmentId
    // }).then(response => response.url)
  },

  // upload a file, reporting progress and returning a promise
  async uploadFiles(files, onProgress, intl) {
    // return new Promise(resolve => {
    //   let progress = 0
    //   const i = setInterval(() => {
    //     progress += 10
    //     onProgress()
    //     if(progress == 100) {
    //       clearInterval(i)
    //       resolve(Array.prototype.slice.call(files).map(() => ({FileName: 'a file.txt'})))
    //     }
    //   }, 200)
    // })

    return Authentication.call('attachments/upload', intl, 'POST').then(
      ({url}) =>
        new Promise((resolve, reject) => {
          const xhr = new window.XMLHttpRequest();
          xhr.open('POST', url);
          xhr.responseType = 'text';
          if ('upload' in xhr) {
            xhr.upload.addEventListener('progress', e => {
              if (e.lengthComputable && e.total) {
                const progress = Math.floor(e.loaded * 100 / e.total);
                if (progress < 100) {
                  // don't report 100% - we need to do that only when complete
                  onProgress();
                }
              }
            });
          }
          xhr.addEventListener('error', () => {
            console.warn(
              intl.formatMessage({id: 'tickets.attachment_upload_error'}),
              xhr.response
            );
            reject(intl.formatMessage({id: 'tickets.attachment_upload_error'}));
          });
          xhr.addEventListener('load', () => {
            if (xhr.status !== 200) {
              reject(
                intl.formatMessage({id: 'tickets.attachment_upload_error'}) +
                  xhr.statusText
              );
            } else {
              const response = JSON.parse(xhr.response);
              resolve(response);
            }
          });
          xhr.addEventListener('loadstart', () => {
            onProgress(0);
          });
          const form = new FormData();
          forEach(files, f => form.append('files', f));
          xhr.send(form);
        })
    );
  }
};

export default Attachments;
