import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  image: {
    maxHeight: 80,
    [theme.breakpoints.down('md')]: {
      maxHeight: 47
    }
  }
}));

export default function Logo({userId = 'default'}) {
  const classes = useStyles();
  return <img src={`/api/logo/${userId}`} className={classes.image} />;
}
