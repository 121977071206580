import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {Paper, Hidden, ButtonBase} from '@material-ui/core';
import {NavLink, withRouter} from 'react-router-dom';
import PrimaryButton from '../../shared/components/PrimaryButton';
import {Badge, BadgeButton} from '../../shared/components/Badge';

const DashboardAction = ({
  title = 'Testing',
  actionName = 'New',
  to = '/',
  icon: Icon,
  history,
  count = null
}) => {
  const classes = styles();
  return (
    <NavLink to={to}>
      <Paper className={classes.paper} elevation={3}>
        <Icon style={{color: '#e4002b', fontSize: '4.6rem'}} />
        <h1 className={classes.headerTitle}>
          <span>{title}</span>
          <Hidden smUp>
            {count ? <Badge className={classes.badge}>{count}</Badge> : null}
          </Hidden>
        </h1>
        <Hidden smDown>
          <div className={classes.buttonsContainer}>
            <PrimaryButton
              style={{
                width: '100%',
                flex: count !== null ? 0.5 : 1,
                marginRight: count !== null ? 10 : 0
              }}
              onClick={() => history.push(to)}>
              {actionName}
              {count ? (
                <Badge inverse className={classes.badge}>
                  {count}
                </Badge>
              ) : null}
            </PrimaryButton>
          </div>
        </Hidden>
      </Paper>
    </NavLink>
  );
};

const styles = makeStyles(theme => ({
  container: {
    backgroundColor: 'transparent',
    border: 'none'
  },
  badge: {
    marginLeft: '1em'
  },
  paper: {
    borderRadius: 10,
    width: 320,
    minHeight: 250,
    display: 'flex',
    margin: '10px 20px 10px 20px',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 20,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-around'
    }
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: 'solid 0.3px #b2b2b2',
    width: '100%',
    alignItems: 'center',
    paddingLeft: 10,
    paddingTop: 10,
    paddingBottom: 10
  },
  headerTitle: {
    display: 'flex',
    fontFamily: 'Nunito',
    fontSize: '2.2rem',
    lineHeight: 1.33,
    color: '#686b6b',
    margin: 0,
    width: '80%',
    textAlign: 'center',
    '& > span': {
      flexGrow: 1
    }
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%'
  },
  microButton: {
    flex: 0.5
  }
}));

DashboardAction.propTypes = {
  title: PropTypes.string.isRequired,
  actionName: PropTypes.string.isRequired,
  to: PropTypes.string,
  icon: PropTypes.object.isRequired,
  count: PropTypes.number
};

export default withRouter(DashboardAction);
