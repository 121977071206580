import React from 'react';
import Tooltip from 'rc-tooltip';
import {injectIntl} from 'react-intl';
import 'rc-tooltip/assets/bootstrap_white.css';

import styled from 'styled-components';

const HelpButton = ({content, tooltipPlacement = 'left', intl}) => (
  <Tooltip
    overlay={<ContentWrapper>{content}</ContentWrapper>}
    placement={tooltipPlacement}>
    <Style
      className="glyphicon glyphicon-question-sign help-button"
      aria-label={intl.formatMessage({id: 'shared.help'})}
    />
  </Tooltip>
);

const Style = styled.i`
  opacity: 0.8;
  display: inline-block;
  font-size: 1.25rem !important;
  margin-left: 1rem;
`;

const ContentWrapper = styled.div`
  max-width: 300px;
`;

export default injectIntl(HelpButton);
