import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl, FormattedMessage} from 'react-intl';

// import Checkbox from '../../shared/components/inputs/Checkbox'
// import FormField from '../../shared/components/inputs/FormField'
import Dialog from '../../shared/components/Dialog';
import Spinner from '../../shared/components/Spinner';

const ApproveEstimateDialog = ({
  cancel,
  handleSubmit,
  error,
  submitting,
  dirty,
  intl,
  fieldMetadata,
  selfPerformed
}) => (
  <Dialog
    onDismiss={cancel}
    enableConfirm={!submitting && dirty}
    size="small"
    title={intl.formatMessage({id: 'tickets.approve_estimate_dialog_title'})}
    confirmLabel={intl.formatMessage({id: 'tickets.approve_estimate_dialog_confirm'})}
    onConfirm={handleSubmit}
    error={error}>
    {submitting && <Spinner loadingText="Saving Work Order..." />}
    <FormattedMessage id="tickets.approve_estimate_dialog_prompt" />
  </Dialog>
);

ApproveEstimateDialog.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  fieldMetadata: PropTypes.object.isRequired
};

export default injectIntl(ApproveEstimateDialog);
