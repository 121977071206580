import React from 'react';
import PropTypes from 'prop-types';
import {Button} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
// import {Add as AddIcon} from '@material-ui/icons';
import AddIcon from 'react-entypo-icons/lib/entypo/Folder';

import SubPanel from '../../shared/components/SubPanel';
import formatReadableFileSize from '../../utils/formatReadableFileSize';
import {injectIntl, FormattedMessage} from 'react-intl';
import formatDate from '../../utils/formatDate';
import formatTime from '../../utils/formatTime';
import getTZHelpText from '../../utils/getTZHelpText';
import PrimaryButton from '../../shared/components/PrimaryButton';

const useStyles = makeStyles(theme => ({
  tr: {
    cursor: 'pointer'
  },
  button: {
    alignSelf: 'flex-end'
  },
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    marginBottom: 20
  },
  line: {
    backgroundColor: '#acacac',
    height: 1,
    width: '100%'
  },
  attachmentBox: {
    display: 'flex',
    padding: '10px',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  attachmentDescription: {
    flexGrow: 1,
    fontWeight: 'bold',
    color: '#0093b2'
  },
  attachmentDate: {
    flexGrow: 0,
    fontSize: '85%',
    opacity: 0.9,
    alignSelf: 'flex-end'
  }
}));

/*
 * <TicketAttachmentSection />
 * Ticket attachments, it's a very simple list right now - we just fetch all those records along with the ticket
 */
const TicketAttachmentSection = ({
  attachments,
  ticketId,
  urlRoot,
  downloadFile,
  readOnly,
  intl,
  timeZone,
  showUploader
}) => {
  const classes = useStyles();
  return (
    <section className={classes.container}>
      <div className={classes.header}>
        <h2>Attachments</h2>
        <div className={classes.line} />
      </div>
      {attachments.length ? (
        attachments.map(ta => (
          <Attachment
            key={ta.id}
            classes={classes}
            data={ta}
            timeZone={timeZone}
            downloadFile={downloadFile}
            intl={intl}
          />
        ))
      ) : (
        <p>
          <FormattedMessage id="tickets.attachments_section_no_attachment" />
        </p>
      )}
      <PrimaryButton
        variant="contained"
        color="primary"
        onClick={showUploader}
        className={classes.button}>
        <AddIcon />
      </PrimaryButton>
    </section>
  );
};

const Attachment = ({data, timeZone, downloadFile, intl, classes}) => (
  <div className={classes.attachmentBox} onClick={() => downloadFile(data.id)}>
    <span className={classes.attachmentDescription}>{data.Description}</span>
    <span
      title={getTZHelpText(timeZone, intl)}
      className={classes.attachmentDate}>
      {formatTime(data.TimeLocal) || formatDate(data.AttachDate)}
    </span>
  </div>
);
// <td>{formatReadableFileSize(data.FileSize)}</td>

const toolbar = showUploader => [
  {
    label: 'New File',
    icon: 'plus',
    action: showUploader
  }
];

TicketAttachmentSection.propTypes = {
  attachments: PropTypes.array.isRequired,
  downloadFile: PropTypes.func.isRequired
};

export default injectIntl(TicketAttachmentSection);
