import {connect} from 'react-redux';
import reduxForm from 'redux-form/lib/reduxForm';
import pick from 'lodash/pick';
import formValueSelector from 'redux-form/lib/formValueSelector';
import actions from 'redux-form/lib/actions';
import {compose, withProps, withHandlers} from 'recompose';
import updateTicketMutation from '../hoc/updateTicketMutation';
import TicketEditDialog from '../components/TicketEditDialog';
// because we need the picklists - the ticket detail query only gets the labels
import insertFieldsMetadata from '../hoc/insertFieldsMetadata';
import aciOptions from '../hoc/aciOptions';
import loadingSpinner from '../../shared/hoc/loadingSpinner';

export const FORM_NAME = 'EditTicket';

const selector = formValueSelector(FORM_NAME);

export default compose(
  updateTicketMutation,
  withProps(({ticket}) => ({
    initialValues: pick(ticket, [
      'Area',
      'Category',
      'Issue',
      'Urgency',
      'VendorCompletedDate'
    ])
  })),
  reduxForm({
    form: FORM_NAME,
    validate: values => {
      const errors = {};
      return errors;
    }
  }),
  insertFieldsMetadata,
  loadingSpinner,
  connect(
    state => ({
      area: selector(state, 'Area'),
      category: selector(state, 'Category')
    }),
    {
      setComplete: () => actions.change(FORM_NAME, 'Status', 'Completed')
    }
  ),
  aciOptions,
  withHandlers({
    closeTicket: ({setComplete, handleSubmit}) => () => {
      setComplete();
      setTimeout(handleSubmit, 150);
    }
  })
)(TicketEditDialog);
