import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {withHandlers} from 'recompose';

import {primaryColor} from '../../../constants';

// A flat selection list
const SelectionList = ({value, onChange, columns = 2, items}) => {
  const cols = [],
    itemsPerCol = Math.ceil(items.length / columns);
  for (let i = 0, col = -1; i < items.length; i++) {
    if (i % itemsPerCol === 0) {
      col++;
      cols.push([]);
    }
    cols[col].push(items[i]);
  }

  return (
    <SelectionListContainer>
      {cols.map((col, i) => (
        <Column
          key={i}
          numCols={columns}
          items={col}
          onChange={onChange}
          value={value}
        />
      ))}
    </SelectionListContainer>
  );
};
SelectionList.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  // optional number of columns
  columns: PropTypes.number,
  items: PropTypes.arrayOf(PropTypes.string).isRequired
};
const SelectionListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const Column = ({items, onChange, value, numCols}) => (
  <ColumnContainer numCols={numCols}>
    {items.map(s => (
      <Item key={s} title={s} onChange={onChange} selected={value === s} />
    ))}
  </ColumnContainer>
);
const ColumnContainer = styled.div`
  flex: 0 0 ${({numCols}) => 100 / numCols}%;
  @media (max-width: 768px) {
    flex-basis: 100%;
  }
`;
const Item = withHandlers({
  onClick: ({onChange, title}) => e => onChange(title)
})(({onClick, selected, title}) => (
  <ItemContainer selected={selected} onClick={onClick}>
    {title}
  </ItemContainer>
));
const ItemContainer = styled.a`
  padding: 5px;
  display: block;
  background-color: #fff;
  transition: background-color 0.1s;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  ${({selected}) =>
    selected ? 'background-color: ' + primaryColor + ' !important' : ''};
`;
export default SelectionList;
