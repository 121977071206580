import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import {createStore, combineReducers, applyMiddleware} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import {
  ApolloClient,
  ApolloProvider,
  createNetworkInterface
} from 'react-apollo';
import formReducer from 'redux-form/lib/reducer';
import 'core-js/es6/symbol';

import IntlProvider from './locales/IntlProvider';
import authReducer from './auth/reducers';
import sharedReducer from './shared/reducers';
import ticketsReducer from './tickets/reducers';
import Routes from './routes';

// const networkInterface = createBatchingNetworkInterface({
// don't batch, because it might time out on Heroku...
const networkInterface = createNetworkInterface({
  uri: '/api/graphql',
  batchInterval: 10,
  opts: {
    // use cookie for authentication
    credentials: 'same-origin'
  }
});
// TODO we need to add an "afterware" to kick the user out if the client detects a not authenticated error
// networkInterface.useAfter([{
//   applyAfterware({ response }, next) {
//     if (response.status === 401) {
//       logout();
//     }
//     next();
//   }
// }]);

const client = new ApolloClient({
  networkInterface
});

const store = createStore(
  combineReducers({
    apollo: client.reducer(),
    form: formReducer,
    auth: authReducer,
    shared: sharedReducer,
    tickets: ticketsReducer
  }),
  {}, // initial state
  composeWithDevTools(
    applyMiddleware(
      // Add additional middleware here
      client.middleware()
    )
  )
);

ReactDOM.render(
  <IntlProvider>
    <ApolloProvider store={store} client={client}>
      <Routes />
    </ApolloProvider>
  </IntlProvider>,
  document.getElementById('root')
);
