import {compose, branch, withProps, renderNothing} from 'recompose';
import Field from 'redux-form/lib/Field';

// Extract properties for a form field from the passed fieldMetadata
// If there is no metadata for given field, hide it.
const FormField = compose(
  branch(({fieldMetadata, name}) => !fieldMetadata[name], renderNothing),
  withProps(({name, fieldMetadata}) => ({
    label: fieldMetadata[name].label,
    fieldMetadata: fieldMetadata[name],
    disabled: !!fieldMetadata[name].disabled
  }))
)(Field);
export const HFormField = withProps({horizontal: true})(FormField);

export default FormField;
