import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

const TextInput = ({
  name = '',
  type = 'text',
  value = '',
  onChange = () => null
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <label htmlFor={name.toLowerCase()} className={classes.label}>
        {name}
      </label>
      <input
        className={classes.input}
        type={type}
        value={value}
        onChange={onChange}
        name={name.toLowerCase()}
      />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    margin: '10px 0 10px 0',
    marginTop: 10,
    marginBottom: 10
  },
  label: {
    color: '#707070',
    fontFamily: 'Nunito',
    fontSize: 12.8
  },
  input: {
    border: 'solid 0.3px #b9b9b9;',
    width: '100%',
    height: 28
  }
}));

export default TextInput;
