import React from 'react';

import ApplicationPage from './ApplicationPage';

export default props => (
  <ApplicationPage {...props}>
    <div className="container-fluid">
      <h4>Coming Soon!</h4>
    </div>
  </ApplicationPage>
);
