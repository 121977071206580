import {graphql} from 'react-apollo';
import mapFieldMetadata from '../../utils/mapFieldMetadata';
import {ACCOUNT_INSERT_META_QUERY} from '../queries';

// HOC to retrieve the metadata for the insert form and map it
export default graphql(ACCOUNT_INSERT_META_QUERY, {
  props: ({ownProps, data}) => ({
    loading: ownProps.loading || data.loading,
    error: ownProps.error || data.error,
    storeFieldMetadata: mapFieldMetadata(data.accountsInsertMeta)
  })
});
