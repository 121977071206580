import React from 'react';
import styled from 'styled-components';

import {PositioningContainer} from '../../shared/components/styles';
import ClearButton from '../../shared/components/ClearButton';
import Glyphicon from '../../shared/components/Glyphicon';
import Checkbox from '../../shared/components/inputs/Checkbox';
import SavedView from './SavedView';

// selection of fields on the export panel
const ExportPanel = ({
  exportList,
  selectAllFields,
  selectDefaultFields,
  selectField,
  unselectField,
  closePanels,
  selectedFields,
  metadata,
  className,
  scrollingPanelRef,
  title,
  helpText,
  fieldSelectionOnly
}) => (
  <PositioningContainer className={className}>
    <div className="panel panel-default panel-save">
      <div className="panel-heading">
        <h3 className="panel-title">{title}</h3>
      </div>
      <ScrollableBody className="panel-body" ref={scrollingPanelRef}>
        <p>{helpText}</p>
        {metadata.map(f =>
          renderFieldCheckbox({
            selectField,
            unselectField,
            selected: !!selectedFields[f.name],
            name: f.name,
            label: f.label
          })
        )}
        <SavedView />
      </ScrollableBody>
      <div className="panel-footer text-right">
        <ClearButton onClick={selectAllFields}>
          <Glyphicon glyph="list" />
          &nbsp;Select All
        </ClearButton>
        <ClearButton onClick={selectDefaultFields}>
          <Glyphicon glyph="erase" />
          &nbsp;Reset
        </ClearButton>
        {!fieldSelectionOnly && (
          <ClearButton onClick={exportList}>
            <Glyphicon glyph="save" />
            &nbsp;Download...
          </ClearButton>
        )}
        <ClearButton onClick={closePanels}>
          <Glyphicon glyph="remove" />
          &nbsp;Close
        </ClearButton>
      </div>
    </div>
  </PositioningContainer>
);

const renderFieldCheckbox = ({
  selectField,
  unselectField,
  selected,
  name,
  label
}) => (
  <div className="col-md-6 col-xs-12" key={name}>
    <Checkbox
      value={selected}
      label={label}
      onChange={sel =>
        sel.target.checked ? selectField(name) : unselectField(name)
      }
    />
  </div>
);

const ScrollableBody = styled.div`
  max-height: 65vh;
  overflow: auto;
`;

export default ExportPanel;
