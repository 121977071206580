import {createAction} from 'redux-actions';

import * as types from './types';

export const toggleFilter = createAction(types.LIST_TOGGLE_FILTER);
export const setFilterField = createAction(
  types.LIST_SET_FILTER_FIELD,
  (i, field) => ({index: i, field})
);
export const setFilterValue = createAction(
  types.LIST_SET_FILTER_VALUE,
  (i, value) => ({index: i, value})
);
export const replaceFilter = createAction(
  types.LIST_REPLACE_FILTER,
  (filter, title) => ({filter, title})
);
export const removeFilterRow = createAction(
  types.LIST_REMOVE_FILTER_ROW,
  i => ({index: i})
);
export const addFilterRow = createAction(types.LIST_ADD_FILTER_ROW);
export const clearFilter = createAction(types.LIST_CLEAR_FILTER);
export const toggleSort = createAction(types.LIST_TOGGLE_SORT);
export const setPage = createAction(types.LIST_SET_PAGE);
export const setSearch = createAction(types.LIST_SET_SEARCH);
export const setSort = createAction(types.LIST_SET_SORT);
export const setView = createAction(types.LIST_SET_VIEW);
export const toggleExport = createAction(types.LIST_TOGGLE_EXPORT);
export const toggleFieldSelection = createAction(
  types.LIST_TOGGLE_FIELDSELECTION
);
export const selectField = createAction(types.LIST_SELECT_FIELD);
export const unselectField = createAction(types.LIST_UNSELECT_FIELD);
export const selectAllFields = createAction(
  types.LIST_SELECT_ALL_FIELDS,
  allFields => ({allFields})
);
export const selectDefaultFields = createAction(
  types.LIST_SELECT_DEFAULT_FIELDS
);
export const unselectAllFields = createAction(types.LIST_UNSELECT_ALL_FIELDS);
export const closePanels = createAction(types.LIST_CLOSE_PANELS);
