import {createAction} from 'redux-actions';

import * as types from './types';

// preventDefault so the browser does not try to do a submit
export const confirmCancel = createAction(types.INSERT_CONFIRM_CANCEL, e =>
  e.preventDefault()
);
export const hideCancel = createAction(types.INSERT_HIDE_CANCEL, e =>
  e.preventDefault()
);
export const onTicketSaved = createAction(
  types.INSERT_TICKET_SAVED,
  ticket => ({ticket})
);
