import some from 'lodash/some';
import isEmpty from 'lodash/isEmpty';

// some utility functions for dealing with the filters

// check if the specified filter object is empty
export const isFilterEmpty = filterRow =>
  isEmpty(filterRow.value) || isEmpty(filterRow.field);

// check if the given value has at least 1 non empty key
export const hasFilterKey = val => {
  const v = some(val, x => {
    return typeof x === 'object' ? !isEmpty(x) : x !== undefined;
  });
  return v;
};

export const getFilterDisplay = value => {
  const formatValue = val => {
    if (val.format)
      // a moment?
      return val.format('ll');
    if (!isNaN(Number(value))) return Number(value).toLocaleString();
    return String(val);
  };
  if (value.in) return value.in.join(', ');
  if (value.eq) return value.eq;
  if (value.like) return value.like;
  if (value.is !== undefined) return String(value.is);
  if (value.lt && value.gt)
    return formatValue(value.lt) + ' ➜➔ ' + formatValue(value.gt);
  if (value.lt) return '< ' + formatValue(value.lt);
  if (value.gt) return '> ' + formatValue(value.gt);
  return JSON.stringify(value);
};

export const addEmptyRow = filter =>
  some(filter, isFilterEmpty) ? filter : filter.concat({field: '', value: {}});
