import React from 'react';

/**
 * Show a validation error.
 * If the text is too long, show it as a tooltip instead.
 * <ValidationHelp />
 */
const ValidationHelp = props => {
  if (props.text.length < 45) {
    return <span className="small validation-help small">{props.text}</span>;
  } else {
    return (
      <span
        className="small validation-help"
        data-toggle="tooltip"
        title={props.text}
        ref={initTooltip}>
        error
      </span>
    );
  }
};

function initTooltip(node) {
  // $(node).tooltip()
}

export default ValidationHelp;
