import React from 'react';
import PropTypes from 'prop-types';
import DebounceInput from 'react-debounce-input';

const TextBoxFilter = ({setFilterValue, filterValue, toggleExpanded}) => {
  return (
    <DebounceInput
      debounceTimeout={300}
      type="text"
      className="form-control"
      onKeyPress={e => e.key === 'Enter' && toggleExpanded()}
      onChange={e => setFilterValue({like: e.target.value})}
      value={filterValue.like || filterValue.eq || ''}
      placeholder="Type to filter..."
    />
  );
};

TextBoxFilter.propTypes = {
  setFilterValue: PropTypes.func.isRequired,
  filterValue: PropTypes.object.isRequired
};

//noinspection JSUnusedGlobalSymbols
export default TextBoxFilter;
