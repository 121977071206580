import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {AppBar, Toolbar, IconButton} from '@material-ui/core';
import {Menu as MenuIcon} from '@material-ui/icons';
import {withRouter} from 'react-router-dom';
import clsx from 'clsx';
import Logo from '../Logo';

const drawerWidth = 140;

const TopAppBar = ({
  open = false,
  handleDrawerOpen = () => null,
  currentUser,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open
      })}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          className={classes.menuButton}>
          <MenuIcon fontSize="large" />
        </IconButton>
        <div className={classes.logoHeader}>
          <Logo userId={currentUser.id} />
        </div>
      </Toolbar>
    </AppBar>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    backgroundColor: '#fff',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      transition: 'none'
    }
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    [theme.breakpoints.down('md')]: {
      width: '100%',
      transition: 'none'
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: '#686b6b'
  },
  hide: {
    display: 'none'
  },
  logoHeader: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  logoLabel: {
    color: '#A6192E',
    fontSize: 28,
    fontFamily: 'Nunito',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: 22
    }
  }
}));

export default withRouter(TopAppBar);
