import React from 'react';
import PropTypes from 'prop-types';

import Input from '../../shared/components/inputs/Input';
import Picklist from '../../shared/components/inputs/Picklist';
import FormField from '../../shared/components/inputs/FormField';
import Dialog from '../../shared/components/Dialog';
import Spinner from '../../shared/components/Spinner';
import {injectIntl} from 'react-intl';

const ManageSelfPerformedDialog = ({
  cancel,
  closeTicket,
  handleSubmit,
  error,
  submitting,
  dirty,
  fieldMetadata,
  selfPerformed,
  intl
}) => (
  <Dialog
    onDismiss={cancel}
    enableConfirm={!submitting && dirty}
    size="small"
    title={intl.formatMessage({id: 'tickets.edit_dialog_title'})}
    extraButton={{
      label: 'Close',
      icon: 'briefcase',
      onClick: closeTicket
    }}
    confirmLabel={intl.formatMessage({id: 'tickets.save'})}
    onConfirm={handleSubmit}
    error={error}>
    {submitting && (
      <Spinner
        loadingText={intl.formatMessage({id: 'tickets.edit_dialog_submitting'})}
      />
    )}

    <FormField
      name="VendorCompletedDate"
      component={Input}
      type="date"
      fieldMetadata={fieldMetadata}
      className="vendorcompleteddate"
    />
    <FormField
      name="Urgency"
      component={Picklist}
      fieldMetadata={fieldMetadata}
      className="urgency"
    />
    <FormField
      name="Area"
      component={Picklist}
      fieldMetadata={fieldMetadata}
      className="area"
    />
    <FormField
      name="Category"
      component={Picklist}
      fieldMetadata={fieldMetadata}
      className="category"
    />
    <FormField
      name="Issue"
      component={Picklist}
      fieldMetadata={fieldMetadata}
      className="issue"
    />
  </Dialog>
);

ManageSelfPerformedDialog.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  closeTicket: PropTypes.func.isRequired,
  fieldMetadata: PropTypes.object.isRequired
};

export default injectIntl(ManageSelfPerformedDialog);
