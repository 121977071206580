import React from 'react';
import Select from 'react-select';
import {injectIntl} from 'react-intl';

// small wrapper for React-Select to address issue with the blur event, and to load the react select css
class ReactSelect extends React.Component {
  constructor(props) {
    super(props);
    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  // hack to get around the fact that react-select does not pass value on blur
  // https://github.com/erikras/redux-form/issues/82
  // https://github.com/JedWatson/react-select/issues/805
  onBlur(e) {
    if (this.props.onBlur) {
      setTimeout(() => {
        this.props.onBlur(this.props.value);
      }, 1);
    }
  }

  onChange(e) {
    if (this.props.isMulti && e.length > 0 && e.find(v => v.value === 'ALL')) {
      // note, they can deselect all options via the "clear" button on the control
      this.props.onChange(this.props.options);
    } else {
      this.props.onChange(e);
    }
  }

  render() {
    let {options, value, isMulti, intl} = this.props;
    if (value) {
      if (isMulti) {
        value = options.filter(o => value.indexOf(o.value) >= 0);
      } else {
        value = options.filter(o => o.value === value)[0];
      }
    }
    if (isMulti) {
      options = [
        {
          label: intl.formatMessage({id: 'tickets.filter_select_all'}),
          value: 'ALL'
        },
        ...options
      ];
    }
    return (
      <Select
        {...this.props}
        value={value}
        options={options}
        onChange={this.onChange}
        onBlur={this.onBlur}
        styles={picklistStyles}
      />
    );
  }
}
// export so that we can share them with the lookup
export const picklistStyles = {
  control: provided => ({
    ...provided,
    borderRadius: 0,
    minHeight: undefined,
    '.has-error &': {
      // copied from bootstrap.css
      borderColor: '#a94442',
      boxShadow: 'inset 0 1px 1px rgba(0, 0, 0, .075)'
    }
  }),
  // style for indicatorContainer (we don't have an extension point for it)
  indicatorsContainer: provided => ({
    ...provided,
    '& > div': {
      paddingTop: '7px',
      paddingBottom: '7px'
    }
  })
};

export default injectIntl(ReactSelect);
