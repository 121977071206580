// Timeline on a ticket
import React, {useState} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {ButtonBase} from '@material-ui/core';
import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import clsx from 'clsx';
import getTZHelpText from '../../utils/getTZHelpText';
import formatTime from '../../utils/formatTime';
import {EntypoTriangleRight} from 'react-entypo-icons';
import {CurtainTransition} from '../../shared/components/styles';
import Glyphicon from '../../shared/components/Glyphicon';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    flexBasis: '100%',
    '& h3': {
      margin: 0,
      color: '#0093b2',
      fontWeight: '600'
    },
    '& h4': {
      margin: 0,
      color: '#686b6b'
    }
  },
  left: {
    minWidth: 36,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
    // minHeight: 109
    // border: 'red 1px solid',
  },
  leftLastStep: {
    width: 36
  },
  stepIcon: {
    cursor: 'pointer',
    backgroundColor: '#e4002b',
    borderRadius: '50%',
    fontSize: 35,
    color: '#fff',
    padding: 5,
    transition: '.3s all'
  },
  incomplete: {
    backgroundColor: '#cecece'
  },
  activityIcon: {
    backgroundColor: '#e4002b',
    borderRadius: '50%',
    width: '16px',
    height: '16px',
    color: 'transparent',
    padding: 5,
    boxShadow: '0 1.5px 3px 0 #00000029'
  },
  content: {
    // minHeight: 109,
    marginLeft: '10px',
    width: '100%',
    '& > h4': {
      fontFamily: 'Nunito',
      color: '#686b6b',
      fontWeight: '600'
    },
    '& > h3': {
      whiteSpace: 'pre-wrap'
    }
  },
  leftBar: {
    backgroundColor: 'black',
    height: 90,
    width: 1
  },
  leftBarShort: {
    height: 10
  },
  subStepLeftBar: {
    borderRight: '1px solid black',
    margin: '0 50%',
    height: '100%'
  },
  shiftup: {
    // hack :(
    transform: 'translateY(-75px)'
  },
  stepContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  activityContainer: {
    flexBasis: '100%',
    display: 'flex',
    minHeight: 109
  },
  expandedStep: {
    '-webkit-transform':
      'rotate(90deg)' /* to support Safari and Android browser */,
    '-ms-transform': 'rotate(90deg)' /* to support IE 9 */,
    transform: 'rotate(90deg)'
  },
  compressedStep: {
    '-webkit-transform':
      'rotate(0deg)' /* to support Safari and Android browser */,
    '-ms-transform': 'rotate(0deg)' /* to support IE 9 */,
    transform: 'rotate(0deg)'
  }
}));

const StepIcon = ({classes, incomplete, expanded}) => (
  <EntypoTriangleRight
    className={clsx(
      classes.stepIcon,
      incomplete && classes.incomplete,
      expanded ? classes.expandedStep : classes.compressedStep
    )}
  />
);

const StepActivity = ({
  timeZone,
  intl,
  isLastStep,
  activity: {date, description, contactName, isApproval},
  classes
}) => {
  return (
    <div className={classes.activityContainer}>
      <div className={clsx(classes.left, {[classes.shiftup]: isLastStep})}>
        <div className={classes.subStepLeftBar} />
        <div className={classes.activityIcon}>&nbsp;
      </div>
        {isApproval ? <Glyphicon glyph="ok" /> : null}
          
        {isLastStep ? null : <div className={classes.subStepLeftBar} />}
      </div>
      <div className={classes.content}>
        <h4 title={getTZHelpText(timeZone, intl)}>

          {formatTime(date)}{contactName ? ` [${contactName}]` : null}</h4>
        <h3>{description}</h3>
      </div>
    </div>
  );
};

const StepHeader = ({
  classes,
  intl,
  isLast,
  timeZone,
  step: {date, step, activities},
  expanded
}) => {
  const stepText = intl.formatMessage({id: 'timeline.' + step}) || step;
  return (
    <div className={classes.header}>
      <div className={classes.left}>
        <StepIcon classes={classes} incomplete={!date} expanded={expanded} />
        {isLast && !isEmpty(activities) ? (
          // a small lead for the left bar, otherwise there is a little gap on the last step
          <CurtainTransition show={expanded}>
            <div className={clsx(classes.leftBar, classes.leftBarShort)} />
          </CurtainTransition>
        ) : null}
        {!isLast ? <div className={classes.leftBar} /> : null}
      </div>
      <div className={classes.content}>
        <h4 title={getTZHelpText(timeZone, intl)}>{formatTime(date)}</h4>
        <h3>{stepText}</h3>
      </div>
    </div>
  );
};

const TimelineStep = props => {
  const {classes, isLast, step, index, handleClick, expanded, ...rest} = props;
  return (
    <div className={classes.stepContainer} onClick={handleClick(index)}>
      <StepHeader {...props} />
      {/* for maxHeight here the 163 is just an average, ideally we should compute a size based on the size of the components */}
      <CurtainTransition
        show={expanded && !isEmpty(step.activities)}
        maxHeight={size(step.activities) * 163 + 'px'}>
        {isLast ? (
          // spacing and line before the first activity.
          // on the other steps, it is part of the step header, but for the last step it is only shown when the
          // step is expanded
          <div className={clsx(classes.leftLastStep, classes.left)}>
            <div className={classes.leftBar} />
          </div>
        ) : null}
        {step.activities &&
          step.activities.map((act, i) => (
            <StepActivity
              key={i}
              activity={act}
              classes={classes}
              isLastStep={isLast}
              {...rest}
            />
          ))}
      </CurtainTransition>
    </div>
  );
};

const TicketTimeline = ({timeline, intl, timeZone}) => {
  const classes = useStyles();
  let lastActiveStep = findIndex(timeline, tl => !tl.date) - 1;
  if (lastActiveStep === -2) lastActiveStep = timeline.length - 1;
  const [expanded, setExpanded] = React.useState(`panel_${lastActiveStep}`);

  const handleChange = panel => e => {
    setExpanded(panel === expanded ? false : panel);
  };
  return (
    <div>
      {timeline.map((t, i) => (
        <TimelineStep
          key={i}
          index={`panel_${i}`}
          step={t}
          intl={intl}
          isLast={i === timeline.length - 1}
          timeZone={timeZone}
          classes={classes}
          handleClick={handleChange}
          expanded={expanded === `panel_${i}`}
        />
      ))}
    </div>
  );
};

export default TicketTimeline;
