import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import {withHandlers} from 'recompose';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import Glyphicon from '../Glyphicon';
// import './DateRangeFilter.css';

const DateRangeFilter = ({filterValue, setFilterValue, futureRanges}) => {
  // const setFilterRangeAction = (range, add) => {
  //   return () => {
  //     const base = moment()
  //     if (add)
  //       base.add(add, range)
  //     const filter = Object.assign({}, filterValue, {
  //       'gt': base.startOf(range).format('YYYY-MM-DD'),
  //       'lt': base.add(1, range).startOf(range).format('YYYY-MM-DD')
  //     })
  //     setFilterValue(filter)
  //   }
  // }
  const start = filterValue && filterValue.gt,
    end = filterValue && filterValue.lt;
  const label = [];
  if (start) label.push(moment(start).format('l'));
  if (end) label.push(moment(end).format('l'));
  if (!label.length) label.push('Select dates...');

  return (
    <DateRangePicker
      startDate={start}
      endDate={end}
      onApply={setFilterValue}
      ranges={futureRanges ? FUTURE : PAST}>
      <button className="btn btn-default" style={{width: '100%'}}>
        <div className="pull-left hidden-sm hidden-xs">
          <Glyphicon glyph="calendar" />
        </div>
        <div className="pull-right">
          <Label>{label[0]}</Label>
          {label.length === 2 && (
            <span>
              <Glyphicon glyph="arrow-right" className="hidden-sm hidden-xs" />
              <span className="visible-sm-inline visible-xs-inline">-</span>
              <Label>{label[1]}</Label>
            </span>
          )}
          <span className="caret hidden-sm hidden-xs" />
        </div>
      </button>
    </DateRangePicker>
  );
};

const PAST = {
  Today: [moment(), moment()],
  Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
  'Last 7 Days': [moment().subtract(6, 'days'), moment()],
  'Last 30 Days': [moment().subtract(29, 'days'), moment()],
  'This Month': [moment().startOf('month'), moment().endOf('month')],
  'Last Month': [
    moment()
      .subtract(1, 'month')
      .startOf('month'),
    moment()
      .subtract(1, 'month')
      .endOf('month')
  ],
  'Last 6 Months': [
    moment()
      .subtract(6, 'month')
      .startOf('month'),
    moment()
  ]
};

const FUTURE = {
  Today: [moment(), moment()],
  Tomorrow: [moment().add(1, 'days'), moment().add(1, 'days')],
  'Next 7 Days': [moment(), moment().add(7, 'days')],
  'Next 30 Days': [moment(), moment().add(30, 'days')],
  'This Month': [moment().startOf('month'), moment().endOf('month')],
  'Next Month': [
    moment()
      .add(1, 'month')
      .startOf('month'),
    moment()
      .add(1, 'month')
      .endOf('month')
  ]
};

const Label = styled.span`
  display: inline-block;
  padding: 0 0.5rem;
`;

DateRangeFilter.propTypes = {
  filterValue: PropTypes.object.isRequired,
  setFilterValue: PropTypes.func.isRequired
};

export default withHandlers({
  setFilterValue: ({setFilterValue}) => (event, picker) => {
    const filter = {
      lt: picker.endDate,
      gt: picker.startDate
    };
    setFilterValue(filter);
  }
})(DateRangeFilter);
