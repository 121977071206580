import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';

import formatReadableFileSize from '../../utils/formatReadableFileSize';
import Dialog from '../../shared/components/Dialog';
import Alert from '../../shared/components/Alert';
import Spinner from '../../shared/components/Spinner';
import styled from 'styled-components';
import {injectIntl, FormattedMessage} from 'react-intl';

const AttachmentUploadDialog = ({
  onCancel,
  onSave,
  files,
  progress,
  isValid,
  updateFileDescription,
  submitting,
  error,
  intl
}) => {
  const classes = useStyles();
  return (
    <Dialog
      onDismiss={onCancel}
      enableConfirm={isValid && !submitting}
      title={intl.formatMessage({id: 'tickets.attachment_upload_title'})}
      confirmLabel={intl.formatMessage({id: 'tickets.save'})}
      onConfirm={onSave}
      error={error}>
      {submitting && <Spinner loadingText="Saving attachments" />}
      <div className="row">
        <div className="col-sm-10 col-xs-9">
          <label>
            <FormattedMessage id="tickets.attachment_upload_description" />
          </label>
        </div>
        <div className="col-sm-2 col-xs-3">
          <label>
            <FormattedMessage id="tickets.attachment_upload_size" />
          </label>
        </div>
      </div>
      {files.map((file, i) => (
        <div className="row" key={i}>
          <div className="col-sm-10 col-xs-9">
            <input
              type="text"
              className={`form-control ${classes.inputs}`}
              value={file.Description}
              onChange={e => updateFileDescription(i, e.target.value)}
            />
          </div>
          <div className="col-sm-2 col-xs-3">
            <input
              type="text"
              readOnly
              className={`form-control ${classes.inputs}`}
              value={formatReadableFileSize(file.FileSize)}
            />
          </div>
        </div>
      ))}
      <br />
      {!error &&
        progress < 100 && (
          <UploadProgress>
            <label>
              <FormattedMessage
                id="tickets.attachment_upload_progress"
                values={{count: files.length}}
              />
            </label>
            <progress min={0} max={100} value={progress} />
          </UploadProgress>
        )}
      {!error &&
        progress === 100 && (
          <Alert
            success={intl.formatMessage(
              {id: 'tickets.attachment_upload_success'},
              {count: files.length}
            )}
          />
        )}
    </Dialog>
  );
};

const UploadProgress = styled.div`
  progress {
    width: 100%;
    display: block;
    margin: 1.5em auto 0 auto;
    height: 1.5em;
  }
`;

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(4)
  },
  inputs: {
    height: 30
  }
}));

AttachmentUploadDialog.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      Description: PropTypes.string.isRequired,
      FileName: PropTypes.string.isRequired
    })
  ).isRequired,
  progress: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired
};

export default injectIntl(AttachmentUploadDialog);
