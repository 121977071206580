import {compose, withProps} from 'recompose';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import formValueSelector from 'redux-form/lib/formValueSelector';
import aciOptions from '../hoc/aciOptions';
import manageSelfPerformedTicketMetadata from '../hoc/manageSelfPerformedTicketMetadata';
import setRegionalManagerContact from '../hoc/setRegionalManagerContact';
import storeInsertMetadata from '../hoc/storeInsertMetadata';
import partsOnSite from '../hoc/partsOnSite';
import loadingSpinner from '../../shared/hoc/loadingSpinner';
import WorkOrderType from '../components/wizard/WorkOrderType';

// logic for the insert fields (this is within the redux form)
const FORM_NAME = 'TicketInsert';
const selector = formValueSelector(FORM_NAME);
export default compose(
  connect(state => ({
    area: selector(state, 'Area'),
    category: selector(state, 'Category'),
    account: selector(state, 'Account'),
    selfPerformedTicket: selector(state, 'SelfPerformedTicket'),
    hasPartsOnSite: selector(state, 'HasPartsOnSite')
  })),
  // XXX do we need this here?
  storeInsertMetadata,
  setRegionalManagerContact,
  aciOptions,
  partsOnSite,
  withProps(({newStore, currentUser}) => ({
    newStore: currentUser.permissions.createStore ? newStore : null
  })),
  loadingSpinner,
  manageSelfPerformedTicketMetadata,
  injectIntl
)(WorkOrderType);
