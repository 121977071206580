import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Paper, StepContent, Stepper, Step, StepLabel} from '@material-ui/core';
import map from 'lodash/map';
import filter from 'lodash/filter';
import findIndex from 'lodash/findIndex';

import FormField from '../../../shared/components/inputs/FormField';
import InsertFields from '../InsertFields';
import GraphqlLookup from '../../../shared/components/inputs/GraphqlLookup';
import WONavigationButtons from './WONavigationButtons';
import StepIconContainer from './StepIconContainer';
import WorkOrdersTopIndicator from './WorkOrdersTopIndicator';
import ProblemSelection from './ProblemSelection';
import TradeSelection from './TradeSelection';
import {StyledStepperConnector, workOrderTypeStyles} from './styles';
import {
  STEP_LOCATION,
  STEP_AREA,
  STEP_CATEGORY,
  STEP_DETAILS
} from './constants';

// Controller for the wizard
// This sits under InsertFieldsCOntainer, so look at that component to see the data connections
// ACI data comes from the aciOptions HOC, it will be provided in the fieldMetadata for the related fields
const WorkOrderType = props => {
  const {intl} = props;
  const classes = workOrderTypeStyles();
  const [state, setState] = useState({
    activeStep: props.account ? STEP_AREA : STEP_LOCATION
  });
  useEffect(
    () => {
      // avoid showing category dropdown if no category to choose from
      if (
        state.activeStep === STEP_CATEGORY &&
        props.disableCategory &&
        // if they have indeed selected a category, we should still show it
        !props.category
      ) {
        handleNext();
      }
    },
    [props.disableCategory]
  );
  useEffect(
    // step to next step when they fill the location
    () => {
      if (props.account && state.activeStep === STEP_LOCATION) {
        handleNext();
      }
    },
    [props.account]
  );

  const handleNext = () => {
    let newStep = state.activeStep + 1;
    if (newStep === STEP_CATEGORY && props.disableCategory) newStep += 1;
    while (newStep < STEP_DETAILS && !steps[newStep]) {
      // if it's a step that's disabled for this account, skip
      newStep += 1;
    }
    setState({...state, activeStep: newStep});
  };

  const handleBack = () => {
    let newStep = state.activeStep - 1;
    if (newStep === STEP_CATEGORY && props.disableCategory) newStep -= 1;
    while (newStep > STEP_LOCATION && !steps[newStep]) {
      // if it's a step that's disabled for this account, skip
      newStep -= 1;
    }
    setState({...state, activeStep: newStep});
  };

  // switch to arbitrary step, but only if they have already completed up to it
  const handleStepClick = step => () => {
    const stepsRequired = {
      [STEP_LOCATION]: props.account,
      [STEP_AREA]: props.area,
      [STEP_CATEGORY]: props.category && !props.disableCategory
    };
    if (step === state.activeStep && stepsRequired[step]) {
      // they are clicking on the current step and have already completed it, take that as a Next
      handleNext();
    } else if (step === 0 || stepsRequired[step - 1]) {
      // they are clicking on another step and have completed at least up to the step below it, switch to it
      setState({
        ...state,
        activeStep: step
      });
    }
  };
  const steps = {
    [STEP_LOCATION]: {
      label: intl.formatMessage({id: 'tickets.insert_select_location'}),
      value: props.account && props.account.label
    },
    [STEP_AREA]: {
      label: intl.formatMessage({id: 'tickets.insert_select_problem_type'}),
      value: props.area
    },
    // if category is suppressed this step is not shown at all
    [STEP_CATEGORY]: props.fieldMetadata.Category && {
      label: intl.formatMessage({id: 'tickets.insert_select_problem'}),
      value: props.category
    },
    [STEP_DETAILS]: {
      label: intl.formatMessage({id: 'tickets.insert_enter_details'}),
      value: ''
    }
  };
  // index just the ones that are active
  const activeSteps = filter(
    map(steps, (stepData, key) => (stepData ? Number(key) : false)),
    v => v !== false
  );
  // map from "real" step index (the key in steps, like STEP_LOCATION etc) to the index in activeSteps
  const activeStepIndex = findIndex(activeSteps, k => k === state.activeStep);
  function handleLoadContent(index) {
    switch (index) {
      case STEP_LOCATION:
        return (
          <div className={classes.categoryContainer}>
            {props.fieldMetadata.StoreHeader && (
              <h6>{props.fieldMetadata.StoreHeader.label}</h6>
            )}
            <FormField
              name="Account"
              component={GraphqlLookup}
              fieldMetadata={props.fieldMetadata}
              lookupType="Store"
              className="store"
              onCreate={props.newStore}
            />
          </div>
        );
      case STEP_AREA: {
        return (
          <div className={classes.categoryContainer}>
            <FormField
              name="Area"
              component={TradeSelection}
              fieldMetadata={props.fieldMetadata}
              onChangeAction={handleNext}
            />
          </div>
        );
      }
      case STEP_CATEGORY: {
        return (
          <div className={classes.categoryContainer}>
            <FormField
              name="Category"
              component={ProblemSelection}
              fieldMetadata={props.fieldMetadata}
              onChangeAction={handleNext}
            />
          </div>
        );
      }
      case STEP_DETAILS: {
        return (
          <div className={classes.formContainer}>
            <InsertFields {...props} />
          </div>
        );
      }
    }
  }
  return (
    <div className={classes.container}>
      <Paper elevation={3} className={classes.paper}>
        <WorkOrdersTopIndicator
          steps={map(activeSteps, k => steps[k].label)}
          activeStep={activeStepIndex}
        />
        <Stepper
          activeStep={activeStepIndex}
          orientation="vertical"
          style={{width: '100%'}}
          connector={<StyledStepperConnector />}>
          {map(activeSteps, key => {
            // key is the key in the steps array
            const {label, value} = steps[key];
            return (
              <Step key={key}>
                <StepLabel
                  StepIconComponent={props => (
                    <StepIconContainer
                      {...props}
                      onClick={handleStepClick(key)}
                    />
                  )}
                  className={classes.stepLabel}>
                  <span>{label}</span>
                  <span className="value">{value}</span>
                </StepLabel>
                <StepContent>
                  {handleLoadContent(key)}
                  {state.activeStep !== 0 ? (
                    <WONavigationButtons
                      activeStep={state.activeStep}
                      handleBack={handleBack}
                      handleSubmit={props.handleSubmit}
                      activeCategory={state.activeCategory}
                    />
                  ) : null}
                </StepContent>
              </Step>
            );
          })}
        </Stepper>
      </Paper>
    </div>
  );
};
WorkOrderType.propTypes = {
  disableCategory: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  fieldMetadata: PropTypes.object.isRequired,
  newStore: PropTypes.func
};

export default WorkOrderType;
