import React from 'react';
import Alert from '../../shared/components/Alert';
import {withRouter} from 'react-router-dom';
import {injectIntl} from 'react-intl';
import {compose} from 'recompose';

const TicketCreatedConfirmation = ({history, match, intl}) => (
  <Alert
    success={intl.formatMessage({
      id: 'tickets.work_order_successfully_created'
    })}
    dismiss={() => history.replace(match.url.replace(/\/[^/]*$/, ''))}
  />
);

export default compose(injectIntl, withRouter)(TicketCreatedConfirmation);
