// convert a list such as 'a', 'b.c', 'b.d' into a string of the form:
//   a
//   b {
//    c
//    d
//   }
import set from 'lodash/set';

export default function makeGraphqlSelect(fields) {
  // transform properties like {"X.Y": 'Z'} into {X: {Y: 'Z'}}
  const keys = fields.reduce((acc, key) => {
    set(acc, key, 1);
    return acc;
  }, {});

  const makeSelect = (obj, prefix) =>
    Object.keys(obj)
      .map(k => {
        if (typeof obj[k] === 'object')
          return (
            prefix +
            k +
            ' {\n' +
            makeSelect(obj[k], prefix + '  ') +
            '\n' +
            prefix +
            '}'
          );
        return prefix + k;
      })
      .join('\n');

  return makeSelect(keys, '');
}
