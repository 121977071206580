import React from 'react';
import clsx from 'clsx';
import {ButtonBase} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

export default function CategoryItem({
  title = 'HVAC',
  isActive,
  onClick = () => null,
  children
}) {
  const classes = useStyles();
  return (
    <ButtonBase
      onClick={() => onClick(title)}
      className={clsx(classes.categoryButton, {
        [classes.activeCategory]: isActive,
        [classes.inactiveCategory]: !isActive
      })}
      name={title}>
      {children}
      {title}
    </ButtonBase>
  );
}

const useStyles = makeStyles(theme => ({
  categoryButton: {
    margin: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    transform: 'translateY(20px) scale(1)',
    transformOrigin: '50% bottom',
    transition: 'transform 300ms cubic-bezier(0.4, 0, 0.2, 1)',
    fontFamily: 'Helvetica Neue',
    boxShadow: '3px 1px 5.5px 2px rgba(0, 0, 0, 0.2)',
    backgroundColor: '#ffffff',
    padding: 10,
    borderRadius: 10,
    minWidth: 83,
    minHeight: 83,
    '&:hover': {
      transform: 'translateY(0) scale(1.2)'
    },
    '& svg': {
      marginBottom: 5
    }
  },
  activeCategory: {
    backgroundImage: 'linear-gradient(to bottom, #e4002b, #a6192e)',
    color: '#fff',
    '& svg': {}
  },
  inactiveCategory: {
    '& svg': {}
  }
}));
