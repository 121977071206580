import {gql, graphql} from 'react-apollo';
import {compose} from 'redux';
import {withHandlers} from 'recompose';

import loadingSpinner from '../../shared/hoc/loadingSpinner';
import SortPanel from '../components/SortPanel';

const SORT_QUERY = gql`
  query TicketFilterMetaQuery {
    ticketsFilterMeta(sortable: true) {
      name
    }
  }
`;

export default compose(
  graphql(SORT_QUERY, {
    props: ({data: {loading, error, ticketsFilterMeta}}) => ({
      loading,
      error,
      metadata: ticketsFilterMeta
    })
  }),
  loadingSpinner,
  withHandlers({
    setSortField: ({sort, setSort}) => field =>
      field && setSort({on: field.value, dir: sort.dir}),
    toggleSortDirection: ({sort, setSort}) => dir =>
      dir && setSort({on: sort.on, dir: sort.dir === 'desc' ? 'asc' : 'desc'})
  })
)(SortPanel);
