import React from 'react';
import styled from 'styled-components';

import Alert from './Alert';

const Banners = ({banners, where, type}) => {
  if (!banners) return null;
  return (
    <Container style={{clear: 'both'}}>
      {banners.filter(where).map((banner, i) => {
        const props = {[banner.Type || type]: banner.Message};
        return <a href={banner.Url}><Alert key={i} {...props} /></a>;
      })}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;

  .alert-danger {
    font-size: 150%;
    color: white;
    background-color: #e4002b;
  }
`;

export default Banners;
