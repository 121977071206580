import React from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import ReactSelect from './ReactSelect';
import InputWrapper from './InputWrapper';
import Input from './Input';

// adapter for a Salesforce picklist
class Picklist extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    const val = e.value;
    if (this.props.onChangeAction) {
      this.props.onChangeAction(val);
    }
    if (this.props.onChange) {
      this.props.onChange(val);
    } else if (this.props.input.onChange) {
      this.props.input.onChange(val);
    }
  }

  render() {
    const props = this.props;
    let metadata = props.fieldMetadata;
    if (!metadata || !metadata.picklist) metadata = {picklist: []};
    if (this.props.hidePicklistIfEmpty && metadata.picklist.length === 0)
      return null;

    const options = metadata.picklist.map(v => ({label: v, value: v}));
    if (props.readOnly) {
      const opt = options && find(options, v => v.value === props.input.value);
      const label = opt ? opt.label : props.input.value;
      return (
        <Input
          readOnly={true}
          label={props.label}
          help={props.help}
          input={{value: label}}
        />
      );
    }

    // wrap the Select and pass props.input (which has the properties from Redux form)
    return (
      <InputWrapper {...props}>
        <ReactSelect
          onBlurResetsInput={props.onBlurResetsInput}
          onInputChange={props.onInputChange}
          clearable={props.clearable !== false}
          multi={metadata.type === 'MultiselectPicklist'}
          options={options}
          value={props.input.value}
          onChange={this.onChange}
          onBlur={props.onBlur || props.input.onBlur}
          simpleValue={true}
          delimiter=";"
          onFocus={props.input.onFocus}
          maxMenuHeight={180}
        />
      </InputWrapper>
    );
  }
}

Picklist.propTypes = {
  fieldMetadata: PropTypes.shape({
    // sometimes when we first render picklist won't be there yet
    picklist: PropTypes.array
  }).isRequired,
  // enable multi select?
  multi: PropTypes.bool,
  // input props (via redux form)
  input: PropTypes.shape({
    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    // will be used as name of input and to get the field metadata
    name: PropTypes.string.isRequired
  }).isRequired,
  // optional change action
  onChangeAction: PropTypes.func,
  // set to false to prevent showing the clear button (defaults to true)
  clearable: PropTypes.bool,
  // optional props that are forwarded to React Select, if provided
  onBlurResetsInput: PropTypes.bool,
  onInputChange: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func
};

export default Picklist;
