import React from 'react';
import PropTypes from 'prop-types';

import GraphqlLookup from '../../shared/components/inputs/GraphqlLookup';
import Checkbox from '../../shared/components/inputs/Checkbox';
import FormField from '../../shared/components/inputs/FormField';
import Dialog from '../../shared/components/Dialog';
import Spinner from '../../shared/components/Spinner';
import {injectIntl} from 'react-intl';

const ManageSelfPerformedDialog = ({
  cancel,
  handleSubmit,
  error,
  submitting,
  dirty,
  fieldMetadata,
  selfPerformed,
  intl
}) => (
  <Dialog
    onDismiss={cancel}
    enableConfirm={!submitting && dirty}
    size="small"
    title={intl.formatMessage({id: 'tickets.self_performed_dialog_title'})}
    confirmLabel={intl.formatMessage({
      id: 'tickets.self_performed_dialog_confirm'
    })}
    onConfirm={handleSubmit}
    error={error}>
    {submitting && (
      <Spinner
        loadingText={intl.formatMessage({
          id: 'tickets.self_performed_dialog_submitting'
        })}
      />
    )}

    <div className="form-group selfperformed">
      <FormField
        name="SelfPerformedTicket"
        component={Checkbox}
        fieldMetadata={fieldMetadata}
      />
    </div>
    {selfPerformed && (
      <FormField
        name="VendorContact"
        component={GraphqlLookup}
        fieldMetadata={fieldMetadata}
        lookupType="ParentContact"
      />
    )}
  </Dialog>
);

ManageSelfPerformedDialog.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  fieldMetadata: PropTypes.object.isRequired
};

export default injectIntl(ManageSelfPerformedDialog);
