import {compose, withHandlers, withState} from 'recompose';
import updateTicketMutation from '../hoc/updateTicketMutation';
import ApproveEstimateDialog from '../components/ApproveEstimateDialog';

export const FORM_NAME = 'ApproveEstimate';

export default compose(
  // provides onSubmit and cancel
  withState('error', 'setError'),
  withState('submitting', 'setLoading'),
  updateTicketMutation,
  withHandlers({
    handleSubmit: ({onSubmit, setError, setLoading}) => () => {
      setError(null);
      setLoading(true);
      onSubmit({AttachCustApproval: true})
        .catch(err => {
          setError((err.errors && err.errors._error) || err.message);
        })
        .then(() => setLoading(false));
    }
  })
)(ApproveEstimateDialog);
