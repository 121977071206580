import React from 'react';
import {Hidden} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

export default function Hero({
  image = '/img/heroes/new_banner.jpg',
  imageSmall = '/img/heroes/new_banner_tall.jpg'
}) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Hidden smUp>
        <img className={classes.heroImage} src={imageSmall} />
      </Hidden>
      <Hidden xsDown>
        <img className={classes.heroImage} src={image} />
      </Hidden>
      <div className={classes.redDecorator} />
      <img
        className={classes.grayDecorator}
        src={
          'https://www.cushmanwakefield.com/Assets/images/card_mix_hero-pageTitle-hr.png'
        }
      />
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    width: '100%',
    height: 380,
    maxWidth: '100%',
    position: 'relative'
  },
  heroImage: {
    width: '100%',
    objectFit: 'cover',
    height: 340,
    objectPosition: 'top'
  },
  grayDecorator: {
    position: 'absolute',
    bottom: -5,
    overflow: 'hidden',
    width: '100%',
    height: 32
  },
  redDecorator: {
    backgroundColor: '#e4002b',
    bottom: 12,
    position: 'absolute',
    width: '100%',
    height: 32,
    overflow: 'hidden',
    opacity: 0.8
  }
}));
