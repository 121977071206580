import {graphql, compose} from 'react-apollo';
import {withHandlers} from 'recompose';
import SubmissionError from 'redux-form/lib/SubmissionError';
import {injectIntl} from 'react-intl';
import {UPDATE_TICKET_MUTATION, TICKET_DETAIL_QUERY} from '../queries';

export default compose(
  injectIntl,
  withHandlers({
    cancel: ({history}) => () => history.goBack()
  }),
  graphql(UPDATE_TICKET_MUTATION, {
    props: ({ownProps: {cancel, intl, ticket}, mutate}) => ({
      onSubmit(values) {
        return mutate({
          variables: {
            ticket: values,
            id: ticket.id
          },
          // easier to just refetch
          refetchQueries: [
            {
              query: TICKET_DETAIL_QUERY,
              variables: {
                ticketId: ticket.id
              }
            }
          ]
          // update(store, {data: {updateTicket}}) {
          //   const q = {
          //     query: TICKET_DETAIL_QUERY,
          //     variables: {
          //       ticketId: ticket.id
          //     }
          //   }
          //   const storeData = store.readQuery(q)
          //   const updated = Object.assign({}, storeData.ticket, updateTicket)
          //   // console.log('new value', updateTicket)
          //   // console.log('updated with', updated)
          //   store.writeQuery({
          //     ...q,
          //     data: {
          //       ...storeData,
          //       ticket: updated
          //     }
          //   })
          // }
        }).then(
          () => {
            cancel();
          },
          r => {
            console.warn('Submission error', r);
            return Promise.reject(
              new SubmissionError({
                _error: intl.formatMessage({
                  id: 'tickets.there_was_an_error_saving_update'
                })
              })
            );
          }
        );
      }
    })
  })
);
