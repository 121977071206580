import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {withState, withHandlers, compose} from 'recompose';
import Authentication from '../utils/Authentication';
import ForgotPasswordPage from '../pages/ForgotPassword';
// Container for resetting the password.
// Use a dedicated (unauthenticated) API call to trigger the password reset

export default compose(
  injectIntl,
  withRouter,
  withState('email', 'setEmail', ''),
  withState('error', 'setError', ''),
  withState('message', 'setMessage', ''),
  withState('loading', 'setLoading', false),
  withHandlers({
    cancel: ({history}) => () => history.goBack(),
    requestPasswordReset: ({
      email,
      intl,
      setError,
      setMessage,
      setLoading
    }) => e => {
      e.preventDefault();
      if (!email) {
        setError(intl.formatMessage({id: 'forgotpassword.email'}));
      } else {
        setLoading(true);
        Authentication.requestPasswordReset(email, intl)
          .then(msg => setMessage(msg), err => setError(err.toString()))
          .then(() => setLoading(false));
      }
    }
  })
)(ForgotPasswordPage);
