import React from 'react';
import styled from 'styled-components';

import ReactSelect from '../../shared/components/inputs/ReactSelect';
import ClearButton from '../../shared/components/ClearButton';
import Glyphicon from '../../shared/components/Glyphicon';
import {FormattedMessage} from 'react-intl';

const SortPanel = ({
  sort,
  setSortField,
  toggleSortDirection,
  toggleSort,
  metadata
}) => (
  <div className="panel panel-default panel-sort">
    <div className="panel-heading">
      <h3 className="panel-title">
        <FormattedMessage id="tickets.sortpanel_heading" />
      </h3>
    </div>
    <div className="panel-body">
      <div className="row no-gutters">
        <div className="col-xs-12 col-sm-8">
          <ReactSelect
            clearable={false}
            options={makeOptions(metadata)}
            value={sort.on}
            onChange={setSortField}
          />
        </div>
        <div className="col-xs-12 col-sm-4">
          <DirButton className="btn btn-default" onClick={toggleSortDirection}>
            <Glyphicon
              glyph={
                sort.dir === 'desc'
                  ? 'sort-by-alphabet-alt'
                  : 'sort-by-alphabet'
              }
            />
            {sort.dir === 'desc' ? 'Desc.' : 'Asc.'}
          </DirButton>
        </div>
      </div>
    </div>
    <div className="panel-footer text-right">
      <ClearButton onClick={toggleSort}>
        <Glyphicon glyph="remove" />
        &nbsp;Close
      </ClearButton>
    </div>
  </div>
);

const makeOptions = metadata =>
  metadata.map(m => ({label: m.name, value: m.name}));

// little bit taller, to match the react-select
const DirButton = styled.button`
  height: 36px;
`;

export default SortPanel;
