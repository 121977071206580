import {withState, withHandlers, compose} from 'recompose';
import {withRouter} from 'react-router-dom';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import Authentication from '../utils/Authentication';
import SignInPage from '../pages/SignInPage';
import {signIn} from '../actions/index';

function getRedirectUrl(location) {
  if (location.search) {
    const qs = location.search.replace(/^\?/, '').split('&');
    for (let q of qs) {
      const parts = q.split('=');
      if (parts[0] === 'redirect') return parts[1];
    }
  }
  return '/';
}

export default compose(
  injectIntl,
  withRouter,
  withState('user', 'setUser', ''),
  withState('password', 'setPassword', ''),
  withState('error', 'setError', ''),
  withState('loading', 'setLoading', false),
  connect(
    null,
    {signIn}
  ),
  withHandlers({
    login: ({
      user,
      intl,
      password,
      setError,
      setLoading,
      history,
      signIn,
      location
    }) => e => {
      e.preventDefault();
      if (!user) {
        setError(intl.formatMessage({id: 'signin_error_login'}));
      } else if (!password) {
        setError(intl.formatMessage({id: 'signin_error_password'}));
      } else {
        setLoading(true);
        Authentication.login(user, password, intl).then(
          userInfo => {
            signIn(userInfo);
            setLoading(false);
            const url = getRedirectUrl(location);
            if (/^\/api/.test(url)) {
              window.location.replace(url);
            } else {
              history.push(url);
            }
          },
          e => {
            setLoading(false);
            setError(e.toString());
          }
        );
      }
    }
  })
)(SignInPage);
