import React from 'react';
import Field from 'redux-form/lib/Field';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {compose, withHandlers, setPropTypes} from 'recompose';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Add as AddIcon} from '@material-ui/icons';

import attachmentUpload from '../hoc/attachmentUpload';
import formatReadableFileSize from '../../utils/formatReadableFileSize';
import ClearButton from '../../shared/components/ClearButton';
import PrimaryButton from '../../shared/components/PrimaryButton';

const renderAttachment = ({
  input: {
    value: {Description, FileSize}
  }
}) => (
  <li className="list-group-item">
    <span className="badge">{formatReadableFileSize(FileSize)}</span>
    <label>{Description}</label>
  </li>
);

// Component for rendering attached files (on an insert form)
//  - upload button to add some files
//  - show info about the files that have been uploaded so far
const AttachmentFieldArray = ({showUploader, fields}) => (
  <Attachments>
    {fields.length > 0 && <h3>Files</h3>}
    <ul className="list-group">
      {fields.map((member, index) => (
        <Field key={index} name={member} component={renderAttachment} />
      ))}
    </ul>
    <PrimaryButton
      onClick={showUploader}
      style={{
        padding: 20,
        width: 150,
        display: 'flex',
        justifyContent: 'space-around'
      }}>
      <FormattedMessage
        id="tickets.attachment_plus_button"
        style={{marginRight: 20}}
      />
      <AddIcon fontSize="large" />
    </PrimaryButton>
  </Attachments>
);

const Attachments = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  align-items: center;
  h3 {
    font-weight: bold;
    padding-left: 15px;
    padding-bottom: 15px;
    font-size: 18px;
  }

  .list-group-item {
    border: none;
    padding-top: 5px;
    padding-bottom: 5px;

    label {
      letter-spacing: 1px;
      font-weight: normal;
    }
  }
`;

AttachmentFieldArray.propTypes = {
  showUploader: PropTypes.func.isRequired
};

const container = compose(
  injectIntl,
  setPropTypes({
    fields: PropTypes.object.isRequired
  }),
  withHandlers({
    // handle when they click "Save" in the dialog
    onSubmitAttachments: ({fields}) => files => {
      files.forEach(f => {
        fields.push(f);
      });
      return Promise.resolve();
    }
  }),
  // include the attachment upload dialog container.
  // Ideally, when uploading attachments for a ticket, we should do the progress within the form itself.
  // But, don't feel like changing it just now
  attachmentUpload
);

export default container(AttachmentFieldArray);
