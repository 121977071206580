import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {withState, withHandlers, compose, lifecycle} from 'recompose';
import Authentication from '../utils/Authentication';
import ResetPasswordPage from '../pages/ResetPassword';
// Container for resetting the password.
// Use a dedicated (unauthenticated) API call to trigger the password reset

export default compose(
  injectIntl,
  withRouter,
  withState('password', 'setPassword', ''),
  withState('passwordConfirmation', 'setPasswordConfirmation', ''),
  withState('error', 'setError', ''),
  withState('tokenVerified', 'setTokenVerified', false),
  withState('message', 'setMessage', ''),
  withState('loading', 'setLoading', false),
  lifecycle({
    componentDidMount() {
      this.props.setLoading(true);
      Authentication.checkPasswordResetToken(
        this.props.match.params.token,
        this.props.intl
      )
        .then(() => this.props.setTokenVerified(true))
        .catch(err => this.props.setError(err.toString()))
        .then(() => this.props.setLoading(false));
    }
  }),
  withHandlers({
    cancel: ({history}) => () => history.replace('/login'),
    resetPassword: ({
      intl,
      password,
      passwordConfirmation,
      match,
      setError,
      setMessage,
      setLoading
    }) => e => {
      e.preventDefault();
      if (password.length < 5 || password.length > 8) {
        setError(intl.formatMessage({id: 'resetpassword.lenght_error'}));
      } else if (password !== passwordConfirmation) {
        setError(intl.formatMessage({id: 'resetpassword.confirmation_error'}));
      } else {
        setLoading(true);
        Authentication.resetPassword(match.params.token, password, intl)
          .then(msg => setMessage(msg), err => setError(err.toString()))
          .then(() => setLoading(false));
      }
    }
  })
)(ResetPasswordPage);
