import React from 'react';
import {compose} from 'recompose';
import {injectIntl} from 'react-intl';
import {makeStyles} from '@material-ui/core/styles';
import {
  AddOutlined as CreateWorkOrderIcon,
  NotificationsOutlined as NotificationsOutlinedIcon,
  ListAltOutlined as ListOutlinedIcon,
  NoteAdd as QuoteIcon
} from '@material-ui/icons';
import ApplicationPage from '../../shared/pages/ApplicationPage';
import Hero from '../../shared/components/Hero';
import DashboardAction from '../components/DashboardAction';
import withTicketsCount from '../../shared/hoc/withTicketsCount';

const Landing = ({ticketsCount, currentUser, intl, ...rest}) => {
  const classes = styles();
  return (
    <ApplicationPage {...{currentUser, ...rest}}>
      <Hero />
      <div className={classes.container}>
        <input
          type="text"
          className={classes.searchInput}
          readOnly
          placeholder={intl.formatMessage({id: 'landing.i_would_like_to'})}
        />
        <div className={classes.actionsContainer}>
          {currentUser.permissions.createTicket ? (
            <DashboardAction
              title={intl.formatMessage({id: 'landing.create_work_order'})}
              actionName={intl.formatMessage({id: 'action.new'})}
              to="/tickets/new-ticket"
              icon={CreateWorkOrderIcon}
            />
          ) : null}
          {currentUser.permissions.viewTickets ? (
            <React.Fragment>
              <DashboardAction
                title={intl.formatMessage({id: 'sidebar.actions_required'})}
                actionName={intl.formatMessage({id: 'action.open'})}
                to="/tickets/action-required"
                icon={NotificationsOutlinedIcon}
                count={ticketsCount.ActionRequired}
              />
              <DashboardAction
                title={intl.formatMessage({id: 'sidebar.workorders'})}
                actionName={intl.formatMessage({id: 'action.view'})}
                to="/tickets/open"
                icon={ListOutlinedIcon}
                count={ticketsCount.Open}
              />
            </React.Fragment>
          ) : null}
        </div>
      </div>
    </ApplicationPage>
  );
};

const styles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  searchTitle: {
    width: 143.5,
    height: 28.5,
    margin: '0 519.7px 5.3px 0.2',
    fontFamily: 'Nunito',
    fontSize: 21,
    lineHeight: 1.36,
    textAlign: 'left',
    color: '#686b6b'
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    marginTop: 20,
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  searchInput: {
    width: '90%',
    border: 'none',
    borderBottom: 'solid 0.5px #acacac',
    backgroundColor: 'transparent',
    fontFamily: 'Nunito',
    fontSize: '3.2rem',
    color: '#686b6b',
    marginTop: 20,
    paddingBottom: 10
  }
}));

export default compose(
  injectIntl,
  withTicketsCount
)(Landing);
