import {withProps, withStateHandlers, compose} from 'recompose';
import Picklist from './Picklist';

export default compose(
  withStateHandlers(
    {
      inputValue: '',
      hasCustomInput: false
    },
    {
      onInputChange: () => v => ({inputValue: v, hasCustomInput: true}),
      // this is when they make a selection in the dropdown.
      // We need to reset the "Custom" flag, so that the value can now be driven by the dropdown
      onChange: (_, {input}) => v => {
        input.onChange(v);
        return {
          hasCustomInput: false
        };
      },
      // if they have made some custom edit, then force the value to that instead of the one that comes from
      // the dropdown (which could be either a blank, or a previous selection)
      onBlur: ({inputValue, hasCustomInput}, {input}) => v => {
        if (inputValue && hasCustomInput) {
          input.onBlur(inputValue);
        } else {
          input.onBlur(v);
          return {
            hasCustomInput: false
          };
        }
      }
    }
  ),
  withProps({
    onBlurResetsInput: false
  })
)(Picklist);
