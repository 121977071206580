import {graphql, compose} from 'react-apollo';
import {withProps} from 'recompose';
import {injectIntl} from 'react-intl';

import errorIfNotFound from '../../shared/hoc/errorIfNotFound';
import loadingSpinner from '../../shared/hoc/loadingSpinner';
import mapFieldMetadata from '../../utils/mapFieldMetadata';
import TicketDetail from '../pages/TicketDetail.js';
import {TICKET_DETAIL_QUERY} from '../queries';

// Container for the Ticket Detail page
const container = compose(
  injectIntl,
  graphql(TICKET_DETAIL_QUERY, {
    options: ({match}) => ({
      variables: {
        ticketId: match.params.ticketId
      }
    }),
    props: ({data}) => ({
      loading: data.loading,
      error: data.error,
      ticket: data.ticket,
      fieldMetadata: mapFieldMetadata(data.ticketsFieldMeta)
    })
  }),
  // TODO make sure this does not show over the entire page
  loadingSpinner,
  errorIfNotFound('ticket'),
  withProps(({ticketsPermissions}) => ({}))
);

export default container(TicketDetail);
