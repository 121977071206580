import {connect} from 'react-redux';
import {compose, lifecycle} from 'recompose';
import formActions from 'redux-form/lib/actions';

import {FORM_NAME} from '../containers/TicketInsertFormContainer';

export default compose(
  connect(null, {
    setParentContact: newContact =>
      formActions.change(FORM_NAME, 'ParentContact', {
        label: newContact.NameLF,
        value: newContact.CrmId
      })
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (
        this.props.storeInformation &&
        this.props.storeInformation.RegionalManagerContact
      ) {
        // when they select a new store that has the regional manager
        if (
          !prevProps.storeInformation ||
          !prevProps.storeInformation.RegionalManagerContact ||
          prevProps.storeInformation.RegionalManagerContact.CrmId !==
            this.props.storeInformation.RegionalManagerContact.CrmId
        ) {
          this.props.setParentContact(
            this.props.storeInformation.RegionalManagerContact
          );
        }
      } else if (
        prevProps.storeInformation &&
        prevProps.storeInformation.RegionalManagerContact
      ) {
        // when they removed the store that had the regional manager
        if (this.props.currentUser.ParentContact) {
          this.props.setParentContact(this.props.currentUser.ParentContact);
        }
      }
    }
  })
);
