import React from 'react';

import styled from 'styled-components';

import Contact from '../components/Contact';
import ApplicationPage from '../../shared/pages/ApplicationPage';
import contactData from '../hoc/contactData';

const ContactPage = ({contacts, ...props}) => (
  <ApplicationPage {...props}>
    <Container>{contacts.map(c => <Contact contact={c} />)}</Container>
  </ApplicationPage>
);

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow: auto;
`;

export default contactData(ContactPage);
