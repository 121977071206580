export const LIST_TOGGLE_FILTER = 'TICKET:LIST_TOGGLE_FILTER';
export const LIST_SET_FILTER_FIELD = 'TICKET:LIST_SET_FILTER_FIELD';
export const LIST_SET_FILTER_VALUE = 'TICKET:LIST_SET_FILTER_VALUE';
export const LIST_REPLACE_FILTER = 'TICKET:LIST_REPLACE_FILTER ';
export const LIST_ADD_FILTER_ROW = 'TICKET:LIST_ADD_FILTER_ROW';
export const LIST_REMOVE_FILTER_ROW = 'TICKET:LIST_REMOVE_FILTER_ROW';
export const LIST_CLEAR_FILTER = 'TICKET:LIST_CLEAR_FILTER';
export const LIST_TOGGLE_SORT = 'TICKET:LIST_TOGGLE_SORT';
export const LIST_SET_PAGE = 'TICKET:LIST_SET_PAGE';
export const LIST_SET_SEARCH = 'TICKET:LIST_SET_SEARCH';
export const LIST_SET_SORT = 'TICKET:LIST_SET_SORT';
export const LIST_SET_VIEW = 'TICKET:LIST_SET_VIEW';
export const LIST_TOGGLE_EXPORT = 'TICKET:LIST_TOGGLE_EXPORT';
export const LIST_TOGGLE_FIELDSELECTION = 'TICKET:LIST_TOGGLE_FIELDSELECTION';
export const LIST_SELECT_FIELD = 'TICKET:LIST_SELECT_FIELD';
export const LIST_UNSELECT_FIELD = 'TICKET:LIST_UNSELECT_FIELD';
export const LIST_SELECT_DEFAULT_FIELDS = 'TICKET:LIST_SELECT_DEFAULT_FIELDS';
export const LIST_SELECT_ALL_FIELDS = 'TICKET:LIST_SELECT_ALL_FIELDS';
export const LIST_UNSELECT_ALL_FIELDS = 'TICKET:LIST_UNSELECT_ALL_FIELDS';
export const LIST_CLOSE_PANELS = 'TICKET:LIST_CLOSE_PANELS';

export const INSERT_CONFIRM_CANCEL = 'TICKET:INSERT_CONFIRM_CANCEL';
export const INSERT_HIDE_CANCEL = 'TICKET:INSERT_HIDE_CANCEL';
export const INSERT_TICKET_SAVED = 'TICKET:INSERT_TICKET_SAVED';

export const ATTACHMENT_UPLOAD_START = 'TICKET:ATTACHMENT_UPLOAD_START';
export const ATTACHMENT_UPLOAD_ERROR = 'TICKET:ATTACHMENT_UPLOAD_ERROR';
export const ATTACHMENT_UPLOAD_COMPLETE = 'TICKET:ATTACHMENT_UPLOAD_COMPLETE';
