import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl, FormattedMessage} from 'react-intl';
import {Route} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';
import {compose} from 'recompose';

import {Paper} from '@material-ui/core';
import TicketCreatedConfirmation from '../components/TicketCreatedConfirmation';
// import CollapsibleSection from '../../shared/components/CollapsibleSection';
import OutputField from '../../shared/components/OutputField';
import Glyphicon from '../../shared/components/Glyphicon';
import {Hr, LinkInForm, styles} from '../../shared/components/styles';
import getTZHelpText from '../../utils/getTZHelpText';

/*
 * <DetailSection/>
 * Main ticket detail section
 * If adding some fields here, remember to add them to the detail query in ../queries.js
 */
const DetailSection = ({
  ticket,
  fieldMetadata,
  urlRoot,
  permissions,
  // the toolbar is not used anymore!
  toolbar,
  intl,
  classes
}) => {
  // return <CollapsibleSection
  //   className="detail"
  //   title={`${intl.formatMessage({id: 'tickets.work_order'})} #${
  //     ticket.TicketNumber
  //   }`}
  //   urlRoot={urlRoot}
  //   toolbar={toolbar}>
  return (
    <Paper elevation={3} className={classes.paperCard} style={{padding: 20}}>
      <Route
        path={`${urlRoot}/created`}
        component={TicketCreatedConfirmation}
      />
      <div className="row">
        <div className="col-xs-12 col-md-6">
          <OutputField
            record={ticket}
            meta={fieldMetadata}
            field="TicketNumber"
          />
          <OutputField record={ticket} meta={fieldMetadata} field="PoNumber" />
          {/*
          <OutputField
            record={ticket}
            meta={fieldMetadata}
            field="ParentAccount.AccountName"
          />
          <OutputField
            record={ticket}
            meta={fieldMetadata}
            field="ParentContact.NameLF"
          />
          <OutputField
            record={ticket}
            meta={fieldMetadata}
            field="ParentContact.WorkPhone"
          />
          <OutputField
            record={ticket}
            meta={fieldMetadata}
            field="ParentContact.Email"
          />
          */}
          {/*
          <OutputField record={ticket} meta={fieldMetadata} field="Location" />
          */}
          <OutputField record={ticket} meta={fieldMetadata} field="Area" />
          <OutputField record={ticket} meta={fieldMetadata} field="Category" />
          <OutputField record={ticket} meta={fieldMetadata} field="Issue" />
          <OutputField record={ticket} meta={fieldMetadata} field="Urgency" />
          <OutputField
            record={ticket}
            meta={fieldMetadata}
            field="RoomFloorWorkstation"
          />
          <OutputField record={ticket} meta={fieldMetadata} field="Status" />
          {/*
          <OutputField
            record={ticket}
            meta={fieldMetadata}
            field="BillabletoClient"
          />
          <OutputField record={ticket} meta={fieldMetadata} field="Invoiced" />
          <OutputField
            record={ticket}
            meta={fieldMetadata}
            field="InvoiceNumber"
          />
          <OutputField
            record={ticket}
            meta={fieldMetadata}
            field="InvoiceDate"
          />
          <OutputField
            record={ticket}
            meta={fieldMetadata}
            field="InvoiceAmount"
          />
          <OutputField
            record={ticket}
            meta={fieldMetadata}
            field="SelfPerformedTicket"
          />
          */}
          {permissions.manageSelfPerformTicket && (
            // allow setting self performed flag, editing vendor contact
            <LinkInForm to={`${urlRoot}/manage-self-performed`}>
              <Glyphicon glyph="wrench" />&nbsp;
              <label>
                <FormattedMessage id="tickets.detail_section" />
              </label>
            </LinkInForm>
          )}
          {permissions.manageSelfPerformTicket && (
            <OutputField
              record={ticket}
              meta={fieldMetadata}
              field="VendorContact.NameLF"
            />
          )}
        </div>
        <div className="col-xs-12 col-md-6">
          {/*
          <OutputField
            record={ticket}
            meta={fieldMetadata}
            field="ReceivedDate"
            help={getTicketTZHelpText(ticket, intl)}
          />
          */}
          {/*
          <OutputField
            record={ticket}
            meta={fieldMetadata}
            field="CustNotToExceed"
          />
          */}
          <OutputField
            record={ticket}
            meta={fieldMetadata}
            field="ProjectManager"
          />
          {/*
          <OutputField
            record={ticket}
            meta={fieldMetadata}
            field="ProjectManagerPhone"
          />
          <OutputField
            record={ticket}
            meta={fieldMetadata}
            field="ProjectManagerMobile"
          />
          */}
          {/* CompletedDate is the ECD, for most customers - Regus has some custom mappings here */}
          <OutputField
            record={ticket}
            meta={fieldMetadata}
            field="CompletedDate"
          />
          {/*
          <OutputField
            record={ticket}
            meta={fieldMetadata}
            field="NeededByDate"
          />
          */}
          {/*
          <OutputField
            record={ticket}
            meta={fieldMetadata}
            field="VendorCompletedDate"
            help={getTicketTZHelpText(ticket, intl)}
          />
          */}
          {fieldMetadata.StoreHeader && (
            <h6>{fieldMetadata.StoreHeader.label}</h6>
          )}
          <OutputField
            record={ticket}
            meta={fieldMetadata}
            field="StoreNumber"
          />
          <OutputField
            record={ticket}
            meta={fieldMetadata}
            field="Account.AccountName"
          />
          <OutputField
            record={ticket}
            meta={fieldMetadata}
            field="Account.Division"
          />
          <OutputField
            record={ticket}
            meta={fieldMetadata}
            field="Account.MainPhone"
          />
          <OutputField
            record={ticket}
            meta={fieldMetadata}
            field="Account.Address.Address1"
          />
          <OutputField
            record={ticket}
            meta={fieldMetadata}
            field="Account.Address.CityStateZip"
          />
          <OutputField
            record={ticket}
            meta={fieldMetadata}
            field="Account.Region"
          />
          <OutputField
            record={ticket}
            meta={fieldMetadata}
            field="CostCenter"
          />
          {fieldMetadata.StoreContactHeader && (
            <h6>{fieldMetadata.StoreContactHeader.label}</h6>
          )}
          <OutputField
            record={ticket}
            meta={fieldMetadata}
            field="RequestedByName"
          />
          {/*
          <OutputField
            record={ticket}
            meta={fieldMetadata}
            field="StoreContact.Email"
          />
          <OutputField
            record={ticket}
            meta={fieldMetadata}
            field="StoreContactPhone"
          />
          <OutputField
            record={ticket}
            meta={fieldMetadata}
            field="StoreContactMobile"
          />
          {fieldMetadata.CintasPartnerHeader && (
            <h6>{fieldMetadata.CintasPartnerHeader.label}</h6>
          )}
          <OutputField
            field="CintasPartnerName"
            record={ticket}
            meta={fieldMetadata}
          />
          <OutputField
            field="CintasPartnerEmail"
            record={ticket}
            meta={fieldMetadata}
          />
          <OutputField
            field="CintasPartnerPhone"
            record={ticket}
            meta={fieldMetadata}
          />
          <OutputField
            field="CintasPartnerMobile"
            record={ticket}
            meta={fieldMetadata}
          />
          {fieldMetadata.CintasBusinessHeader && (
            <h6>{fieldMetadata.CintasBusinessHeader.label}</h6>
          )}
          <OutputField
            field="CintasBusinessName"
            record={ticket}
            meta={fieldMetadata}
          />
          <OutputField
            field="CintasBusinessAddress"
            record={ticket}
            meta={fieldMetadata}
          />
          <OutputField
            field="HasPartsOnSite"
            record={ticket}
            meta={fieldMetadata}
          />
          <OutputField
            field="PartsOnSiteDescription"
            record={ticket}
            meta={fieldMetadata}
          />
          */}
        </div>
        <Hr />
        <div className="col-xs-12">
          {/* rejected comments and subject, desc work, vendor completed, urgency, sub status */}
          <OutputField
            record={ticket}
            meta={fieldMetadata}
            field="DescriptionOfWork"
          />
        </div>
      </div>
    </Paper>
  );
};

DetailSection.propTypes = {
  ticket: PropTypes.object.isRequired,
  fieldMetadata: PropTypes.object.isRequired,
  permissions: PropTypes.object.isRequired,
  urlRoot: PropTypes.string.isRequired,
  toolbar: PropTypes.array.isRequired
};

const getTicketTZHelpText = (ticket, intl) =>
  ticket.Account && ticket.Account.Address && ticket.Account.Address.TimeZone
    ? getTZHelpText(ticket.Account.Address.TimeZone, intl)
    : '';

export default compose(
  withStyles(styles),
  injectIntl
)(DetailSection);
