// HOC to change the metadata based on the self performed ticket permissions

import PropTypes from 'prop-types';
import {withProps, compose, setPropTypes} from 'recompose';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';

export default compose(
  setPropTypes({
    fieldMetadata: PropTypes.object.isRequired,
    currentUser: PropTypes.shape({
      permissions: PropTypes.object.isRequired
    }).isRequired
  }),
  // hide the "Self Performed" field if they don't have the manage permission
  withProps(({currentUser: {permissions}, fieldMetadata}) => ({
    fieldMetadata: !permissions.manageSelfPerformTicket
      ? omit(fieldMetadata, ['SelfPerformedTicket'])
      : fieldMetadata
  })),
  // hide vendor contact fields if it's not self performed.
  // Note that they will be shown, even if the user does not have the "Manage Self Performed Ticket" permission
  withProps(({selfPerformedTicket, fieldMetadata}) => ({
    fieldMetadata: !selfPerformedTicket
      ? omitBy(fieldMetadata, (_, x) => /VendorContact/.test(x))
      : fieldMetadata
  }))
);
