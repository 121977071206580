import {branch, withProps, renderComponent, compose} from 'recompose';
import Alert from '../components/Alert';

// render an error alert if there is an error prop
export default branch(
  ({error}) => error,
  compose(
    withProps(({error}) => ({error: error.toString()})),
    renderComponent(Alert)
  )
);
