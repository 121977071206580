// wrapper to load a lookup options via graphql
import {gql, graphql, compose} from 'react-apollo';
import {setPropTypes, withState} from 'recompose';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import Lookup from './Lookup';

const QUERY = gql`
  query GetLookupData($lookupType: String!, $search: String!) {
    getLookupData(lookupType: $lookupType, search: $search) {
      label
      value
    }
  }
`;

export default compose(
  setPropTypes({
    // what's the type of entity we are loading?
    lookupType: PropTypes.string.isRequired
  }),
  withState('search', 'onSearch', ''),
  graphql(QUERY, {
    options: ({lookupType, search}) => ({
      variables: {
        // TODO we should have an optimization, so that it doesn't run that query if we already have all the data
        lookupType,
        search
      }
    }),
    props: ({data}) => ({
      options: (data.getLookupData || []).map(v => pick(v, ['label', 'value'])),
      loadingOptions: data.loading,
      error: data.error
    })
  })
  // loading spinner?
)(Lookup);
