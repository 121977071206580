import {gql} from 'react-apollo';
import reject from 'lodash/reject';
import {isFilterEmpty} from './utils/filterUtils';

const DEFAULT_FIELDS = [
  'Subject',
  'Area',
  'CompletedDate',
  'ReceivedDate',
  'TicketNumber',
  'Urgency',
  'StoreNumber'
];

export const TICKET_LIST_QUERY = fields => gql`
  query TicketsListQuery($offset: Int!, $limit: Int!, $filter: [FilterCriteria]!, $sort: OrderBy!, $search: String) {
    ticketsList(offset: $offset, limit: $limit, filter: $filter, sort: $sort, search: $search) {
      tickets {
        id
        ${fields || DEFAULT_FIELDS.join('\n')}
      }
      totalCount
    }
    ticketsFilterMeta {
      name
      label
      type
      picklist
    }
  }
`;
export const PAGE_LENGTH = 10;
export const TICKET_LIST_VARIABLES = ({page = 0, filter, sort, search}) => ({
  offset: page * PAGE_LENGTH,
  limit: PAGE_LENGTH,
  filter: reject(filter, isFilterEmpty),
  sort,
  search
});

export const CREATE_STORE_MUTATION = gql`
  mutation CreateStoreMutation($store: AccountInput!) {
    createStore(store: $store) {
      id
    }
  }
`;

export const CREATE_TICKET_MUTATION = gql`
  mutation CreateTicketMutation($ticket: TicketInput!) {
    createTicket(ticket: $ticket) {
      id
      TicketNumber
    }
  }
`;

// Update the ticket, we need to fetch the fields that can be mutated, so we can refresh the screen
export const UPDATE_TICKET_MUTATION = gql`
  mutation UpdateTicketMutation($ticket: TicketInput!, $id: ID!) {
    updateTicket(ticket: $ticket, id: $id) {
      SelfPerformedTicket
      VendorContact {
        id
        NameLF
      }
    }
  }
`;

export const CREATE_TICKETACTIVITY_MUTATION = gql`
  mutation CreateTicketActivityMutation($ticketActivity: TicketActivityInput!) {
    createTicketActivity(ticketActivity: $ticketActivity) {
      id
      AssignedDate
      TimeLocal
      ActivityTypeDesc
      ActivityDescription
      ContactName
      QuoteApproval
    }
  }
`;

export const CREATE_TICKETATTACHMENTS_MUTATION = gql`
  mutation CreateTicketAttachments($attachments: TicketAttachmentsInput!) {
    createTicketAttachments(attachments: $attachments) {
      id
      AttachDate
      TimeLocal
      AttachmentType
      Description
      FileSize
      AttachedBy
    }
  }
`;

// we'll need to add ALL the fields that are needed for the ticket
export const TICKET_DETAIL_QUERY = gql`
  query TicketDetailQuery($ticketId: String!) {
    ticket(id: $ticketId) {
      id
      DescriptionOfWork
      Status
      SubStatus
      BillabletoClient
      Invoiced
      InvoiceAmount
      InvoiceDate
      InvoiceNumber
      Area
      Category
      Issue
      StoreNumber
      RoomFloorWorkstation
      CintasPartnerName
      CintasPartnerEmail
      CintasPartnerPhone
      CintasPartnerMobile
      CintasBusinessName
      CintasBusinessAddress
      Urgency
      RequestedByName
      StoreContact {
        NameLF
        Email
      }
      Account {
        AccountName
        Division
        MainPhone
        Region
        Address {
          Address1
          CityStateZip
          TimeZone
        }
      }
      ParentAccount {
        AccountName
      }
      ParentContact {
        NameLF
        WorkPhone
        Email
      }
      VendorContact {
        id
        NameLF
      }
      PoNumber
      TicketNumber
      CustNotToExceed
      ProjectManager
      ProjectManagerPhone
      ProjectManagerMobile
      StoreContactPhone
      StoreContactMobile
      ReceivedDate
      CompletedDate
      NeededByDate
      VendorCompletedDate
      WhoInitiated
      CostCenter
      Location
      SelfPerformedTicket
      AttachCustApproval
      Attachments {
        id
        AttachDate
        TimeLocal
        Description
        FileSize
        AttachedBy
      }
      Timeline {
        step
        date
        activities {
          date
          description
          contactName
          isApproval
        }
      }
      permissions {
        editTicket
        addActivity
      }
    }
    ticketsFieldMeta {
      name
      label
      type
    }
  }
`;

export const FIELDMETA_QUERY = gql`
  query TicketFieldMeta {
    ticketsFieldMeta {
      name
      label
      type
    }
    ticketsDefaultFilter {
      field
      value
    }
  }
`;

// TODO get the permission stuff
export const TICKET_INSERT_META_QUERY = gql`
  query {
    ticketsInsertMeta {
      name
      label
      disabled
      picklist
      required
    }
  }
`;

export const ACCOUNT_INSERT_META_QUERY = gql`
  query {
    accountsInsertMeta {
      name
      label
      picklist
    }
  }
`;

export const TICKET_VIEWS_QUERY = gql`
  query TicketsViewQuery {
    ticketViews {
      name
      definition
    }
  }
`;

export const SAVE_TICKETVIEW_MUTATION = gql`
  mutation SaveTicketView($name: String!, $definition: JSON!) {
    saveTicketView(name: $name, definition: $definition) {
      name
      definition
    }
  }
`;

export const DELETE_TICKETVIEW_MUTATION = gql`
  mutation DeleteTicketView($name: String!) {
    deleteTicketView(name: $name)
  }
`;
