import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import clsx from 'clsx';
import {Route, Switch} from 'react-router-dom';

import ListHeader from '../components/ListHeader';
import ListDetails from '../components/ListDetails';
import ListItem, {ITEM_HEIGHT} from '../components/ListItem';
import TicketGridContainer from '../containers/TicketGridContainer';
import SortPanelContainer from '../containers/SortPanelContainer';
import FilterPanelContainer from '../containers/FilterPanelContainer';
import ExportPanelContainer from '../containers/ExportPanelContainer';
import {
  PositioningContainer,
  ModalPanel,
  Overlay
} from '../../shared/components/styles';

// top level container for list
class TicketList extends React.Component {
  render() {
    const {
      isExportExpanded,
      isFilterExpanded,
      isSortExpanded,
      isFieldSelectionExpanded
    } = this.props;
    const props = this.props;
    return (
      <Section className={clsx(props.className, 'ticket-list')}>
        <ListHeader {...props} />
        <PositioningContainer>
          <ModalPanel
            show={isFilterExpanded}
            onClick={e => e.stopPropagation()}>
            {isFilterExpanded && <FilterPanelContainer {...props} />}
          </ModalPanel>
          <ModalPanel show={isSortExpanded} onClick={e => e.stopPropagation()}>
            {isSortExpanded && <SortPanelContainer {...props} />}
          </ModalPanel>
          <ModalPanel
            show={isExportExpanded}
            onClick={e => e.stopPropagation()}>
            {isExportExpanded && <ExportPanelContainer {...props} />}
          </ModalPanel>
          <ModalPanel
            show={isFieldSelectionExpanded}
            onClick={e => e.stopPropagation()}>
            {isFieldSelectionExpanded && (
              <ExportPanelContainer fieldSelectionOnly {...props} />
            )}
          </ModalPanel>
          <Overlay
            show={isFilterExpanded || isSortExpanded || isExportExpanded}
          />
          <div className="list-container">
            <ListSwitch {...props} />
          </div>
        </PositioningContainer>
      </Section>
    );
  }
}

// this will be used to alternate between grid and list display
const ListSwitch = props => (
  <Switch>
    <Route path="/tickets" render={() => <TicketGridContainer {...props} />} />
    <Route
      path=""
      render={() => (
        <ListDetails
          {...props}
          ItemComponent={ListItem}
          itemHeight={ITEM_HEIGHT}
        />
      )}
    />
  </Switch>
);

// position the loading spinner under the modals
const Section = styled.section`
  .spinner-container {
    z-index: 15;
  }
  display: flex;
  flex-direction: column;
  // want to span the whole height.
  // For some reason 100% does not work on iOS
  height: 100vh;
  & > header {
    flex: 0 0 auto;
  }
  & > div {
    flex: 1;
    .list-container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: auto;
    }
  }
`;

TicketList.propTypes = {
  totalCount: PropTypes.number.isRequired,
  records: PropTypes.array.isRequired,
  isFilterExpanded: PropTypes.bool.isRequired,
  isFilterActive: PropTypes.bool.isRequired,
  isFieldSelectionExpanded: PropTypes.bool.isRequired,
  isExportExpanded: PropTypes.bool.isRequired,
  isSortExpanded: PropTypes.bool.isRequired
};

export default TicketList;

// vim ft=javascript.jsx
