import React from 'react';
import PropTypes from 'prop-types';
import './Toggle.css';

// a material-ui type toggle
const Toggle = props => {
  const inputProps = props.input ? props.input : props;
  const val =
    typeof inputProps.value === 'string' ? inputProps.value : undefined;
  const checked =
    typeof inputProps.value === 'boolean'
      ? inputProps.value
      : inputProps.checked;
  const onChange = props.input
    ? evt => props.input.onChange(evt.target.checked)
    : props.onChange;

  return (
    <div className={'mat-toggle ' + (checked ? 'checked' : 'unchecked')}>
      <p>{props.label}</p>
      <label className="switch">
        <input
          type="checkbox"
          checked={checked}
          disabled={props.disabled}
          onChange={onChange}
          value={val}
          name={props.name}
        />
      </label>
    </div>
  );
};

Toggle.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  // optional name/value will be applied to the input element
  name: PropTypes.string,
  value: PropTypes.string
};

//noinspection JSUnusedGlobalSymbols
export default Toggle;
