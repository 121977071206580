import React from 'react';
import clsx from 'clsx';
import {ButtonBase} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

export default function PrimaryButton({children, className = '', ...rest}) {
  const classes = useStyles();
  return (
    <ButtonBase
      type="submit"
      className={clsx(classes.button, className)}
      {...rest}>
      {children}
    </ButtonBase>
  );
}

const useStyles = makeStyles(theme => ({
  button: {
    padding: '0 1em',
    height: 35,
    borderRadius: 17.5,
    background: 'linear-gradient(#e4002b 0%, #a6192e 100%)',
    boxShadow: '3px 0px 6px rgba(0, 0, 0, 0.16)',
    color: '#FFFFFF',
    fontFamily: 'Helvetica Neue',
    borderColor: '#e4002b',
    fontSize: 16,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    '&:disabled': {
      background: 'none',
      backgroundColor: '#d6d6d6'
    }
  }
}));
