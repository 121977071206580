import moment from 'moment';
import pick from 'lodash/pick';

export default currentUser => ({
  Area: '',
  Category: '',
  Issue: '',
  // this is not actually saved in CRM!  Looks like a totally unbound field in the current system.
  DateRequested: moment().format('YYYY-MM-DD'),
  SelfPerformedTicket: false,
  ParentContact: currentUser.ParentContact
    ? {
        label: currentUser.ParentContact.NameLF,
        // need to use CRM contactid, because the user id (being in the customers collection)
        // is actually not the same as the parent contact id
        value: currentUser.ParentContact.CrmId
      }
    : null,
  Account:
    currentUser.defaultStore &&
    pick(currentUser.defaultStore, ['label', 'value']),
  RequestedByName:
    currentUser.NameLF && !currentUser.permissions.doNotDefaultRequestedByName
      ? currentUser.NameLF.split(', ')
          .reverse()
          .join(' ')
      : ''
});
