import {gql, graphql} from 'react-apollo';
import {compose} from 'redux';
import {withHandlers, withProps} from 'recompose';
import ReactDOM from 'react-dom';
import {injectIntl} from 'react-intl';

import loadingSpinner from '../../shared/hoc/loadingSpinner';
import ExportPanel from '../components/ExportPanel';

const META_QUERY = gql`
  query TicketFilterMetaQuery {
    ticketsFieldMeta(excludeSubProperties: true) {
      name
      label
    }
  }
`;

// scroll position HACK
// make sure we have this outside of state, since state will be lost once component remounts
let scrollPosition = 0;

export default compose(
  injectIntl,
  graphql(META_QUERY, {
    props: ({data: {loading, error, ticketsFieldMeta}}) => ({
      loading,
      error,
      metadata: ticketsFieldMeta && ticketsFieldMeta
    })
  }),
  loadingSpinner,
  withHandlers({
    exportList: ({selectedFields, filter, sort}) => e => {
      const fields = Object.keys(selectedFields);
      window.open(
        '/api/tickets/export?' +
          `filter=${JSON.stringify(filter)}&sort=${JSON.stringify(
            sort
          )}&fields=${JSON.stringify(fields)}`
      );
    },
    selectAllFields: ({metadata, selectAllFields}) => {
      selectAllFields(metadata.map(f => f.name));
    }
  }),

  // handling for scroll
  // because the whole container will re-render when they make a selection, it will reset the scroll in the panel
  // (because the panel is within the list container...)
  // ideally the panel should be outside of the data container!
  withHandlers({
    scrollingPanelRef: () => ref => {
      if (ref) {
        const element = ReactDOM.findDOMNode(ref);
        element.addEventListener('scroll', e => {
          scrollPosition = e.target.scrollTop;
        });
        if (scrollPosition) {
          element.scrollTop = scrollPosition;
        }
      }
    }
  }),
  withProps(({intl, fieldSelectionOnly, metadata}) => ({
    helpText:
      (fieldSelectionOnly
        ? intl.formatMessage({
            id: 'tickets.export_panel.select_columns_you_would_like'
          })
        : intl.formatMessage({
            id: 'tickets.export_panel.select_fields_you_would_like'
          })) +
      intl.formatMessage({id: 'tickets.export_panel.to_save_your_selection'}),
    title: fieldSelectionOnly
      ? intl.formatMessage({id: 'tickets.export_panel.select_columns_title'})
      : intl.formatMessage({
          id: 'tickets.export_panel.download_work_orders_title'
        }),
    metadata:
      metadata &&
      metadata.filter(
        m =>
          !m.name.startsWith('VendorContact') && m.name !== 'DescriptionOfWork'
      )
  }))
)(ExportPanel);
