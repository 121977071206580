import React from 'react';
import {FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import classNames from 'classnames';
import Alert from './Alert';
import ClearButton from './ClearButton';
import Glyphicon from './Glyphicon';
import {Button} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

// avoid letting the form submit... we'll never want to do a "real" post!
const preventDefault = e => e.preventDefault();

const Dialog = props => {
  const useColor = props.size !== 'small';

  return (
    <ReactModal
      isOpen={true}
      onRequestClose={props.onDismiss}
      contentLabel={props.title}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={false}
      style={{
        content: {
          padding: 0,
          ...contentStyle(props),
          marginTop: 100
        },
        overlay: {
          // should have a transition, ideally...
          backgroundColor: 'rgba(0, 0, 0, .7)',
          zIndex: 15,
          ...overlayStyle(props)
        }
      }}>
      <Panel
        className={classNames(
          'panel',
          {'panel-default': !useColor},
          props.className
        )}
        onSubmit={preventDefault}
        size={props.size}>
        <header>
          <h4 className="panel-title">{props.title}</h4>
        </header>
        <div className="panel-body">
          {props.children}
          {props.error && <Alert error={props.error} />}
        </div>
        <footer className="panel-footer text-right">
          {props.extraButton && (
            <ClearButton
              type="button"
              className="extra"
              onClick={props.extraButton.onClick}>
              <Glyphicon glyph={props.extraButton.icon} />
              &nbsp;{props.extraButton.label}
            </ClearButton>
          )}
          <FooterButtons {...props} />
        </footer>
      </Panel>
    </ReactModal>
  );
};

function FooterButtons(props) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        onClick={props.onConfirm}
        disabled={props.enableConfirm === false}
        className={classes.nextButton}>
        <Glyphicon glyph={props.confirmIcon || 'ok'} />
        {props.confirmLabel}
      </Button>
      {props.showCancel && (
        <Button onClick={props.onDismiss} className={classes.button}>
          <Glyphicon glyph="remove" />
          <FormattedMessage id="shared.cancel" />
        </Button>
      )}
    </React.Fragment>
  );
}

const useStyles = makeStyles(theme => ({
  button: {
    fontFamily: 'Nunito',
    fontSize: '1.2rem',
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    border: 'solid 0.4px #f7f7f7',
    '& > span > i': {
      top: -2,
      lineHeight: 1,
      marginRight: 3
    }
  },
  nextButton: {
    fontFamily: 'Nunito',
    fontSize: '1.2rem',
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: '#e4002b',
    '&:hover': {
      backgroundColor: '#a6192e'
    },
    '& > span > i': {
      top: -2,
      lineHeight: 1,
      marginRight: 3
    }
  }
}));

const contentStyle = ({size}) => {
  // we need this to auto size vertically (to match the content)
  if (size === 'small') {
    return {
      position: 'absolute',
      margin: '10vh auto',
      width: '40vw',
      minWidth: '300px',
      maxWidth: '450px',
      overflow: 'visible',
      bottom: 'auto'
    };
  }
  return {
    position: 'static',
    // spacing for header
    margin: '70px auto',
    width: '90vw',
    maxWidth: '900px',
    border: 'none'
  };
};

const overlayStyle = ({size}) => {
  if (size === 'small') {
    return {
      // should NOT happen, because we only use that with small enough dialog
      // but "just in case" they have a very short screen
      overflow: 'auto'
    };
  }
  return {};
};

const Panel = styled.form`
  //position: absolute;
  //top: 0; bottom: 0; right: 0; left: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  border: none !important;
  // leave room for header
  // this is a bit of a hack and it wont resize if they change the phone orientation
  // max-height: ${() => window.innerHeight - 50}px;
  // Need to check if this works in Safari
  max-height: calc(100vh - 50px);
  // adjust styles a bit for smaller size dialogs (which are usually using a vertical form)
  ${({size}) =>
    size === 'small' &&
    `
    .form-group {
      margin: 0;
    }
    .alert {
      margin-top: 15px;
      margin-bottom: 0;
    }
  `}

  .panel-body {
    flex-grow: 1;
    overflow: ${({size}) => (size === 'small' ? 'visible' : 'auto')};
    padding: 32px;
  }
  .panel {
    border: none;
  }

  header {
    background-color: #17a2b8;
    padding: 15px 15px;
    h4 {
      color: white;
    }
  }
  footer.panel-footer {
    display: flex;
    justify-content: flex-end;
  }
  .button-base {
    font-family: Nunito;
    font-size: 1.2rem;
  }
  .submit-button {
    background-color: '#e4002b',
    &:hover: {
      background-color: '#a6192e'
    }
  }
`;

Dialog.propTypes = {
  // when dialog is closed or cancelled
  onDismiss: PropTypes.func.isRequired,
  // when OK button is clicked
  onConfirm: PropTypes.func.isRequired,
  // set to false to disable the OK button (i.e, when validation fails)
  enableConfirm: PropTypes.bool,
  // label for the OK button
  confirmLabel: PropTypes.string.isRequired,
  // optional icon, default is checkmark
  confirmIcon: PropTypes.string,
  // dialog title
  title: PropTypes.string.isRequired,
  // set large / small size for dialog
  size: PropTypes.string,
  showCancel: PropTypes.bool,
  // optional error message to be displayed as alert in the dialog
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  // optional additional button to display
  extraButton: PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    icon: PropTypes.string.isRequired
  })
};
Dialog.defaultProps = {
  showCancel: true
};

export default Dialog;
