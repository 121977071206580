import React from 'react';

import styled from 'styled-components';

import Video from '../components/Video';
import ApplicationPage from '../../shared/pages/ApplicationPage';
import videoData from '../hoc/videoData';

const VideoPage = ({videos, ...props}) => (
  <ApplicationPage {...props}>
    <Scrolling>
      <Container className="row">
        {videos.map(v => <Video key={v.id} video={v} />)}
      </Container>
    </Scrolling>
  </ApplicationPage>
);

const Container = styled.div`
  margin: 15px auto;
  max-width: 1024px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const Scrolling = styled.div`
  width: 100%;
  overflow: auto;
`;

export default videoData(VideoPage);
