import {connect} from 'react-redux';
import {graphql, compose} from 'react-apollo';
import update from 'immutability-helper';
import reduxForm from 'redux-form/lib/reduxForm';
import actions from 'redux-form/lib/actions';
import SubmissionError from 'redux-form/lib/SubmissionError';
import formValueSelector from 'redux-form/lib/formValueSelector';
import {setPropTypes} from 'recompose';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import findLastIndex from 'lodash/findLastIndex';

import {CREATE_TICKETACTIVITY_MUTATION, TICKET_DETAIL_QUERY} from '../queries';
import TicketActivityInput from '../components/TicketActivityInput';

const FORM_NAME = 'TicketActivityInsert';

const selector = formValueSelector(FORM_NAME);

export default compose(
  setPropTypes({
    currentUser: PropTypes.object.isRequired,
    ticketId: PropTypes.string.isRequired,
    needApproval: PropTypes.bool.isRequired
  }),
  injectIntl,
  graphql(CREATE_TICKETACTIVITY_MUTATION, {
    props({ownProps: {intl, ticketId}, mutate}) {
      return {
        onSubmit(vals, dispatch) {
          return mutate({
            variables: {
              ticketActivity: {
                ...vals,
                ticketId
              }
            },
            update(store, {data}) {
              // update query result cache for the ticket detail
              const q = {
                query: TICKET_DETAIL_QUERY,
                variables: {
                  ticketId
                }
              };
              const storeData = store.readQuery(q);
              let stepIndex = findLastIndex(storeData.ticket.Timeline, 'date');
              if (stepIndex === -1) stepIndex = 0;
              const activitiesExists = !!storeData.ticket.Timeline[stepIndex]
                .activities;
              const operation = {
                ticket: {
                  Timeline: {
                    [stepIndex]: {
                      activities: {
                        [activitiesExists ? '$push' : '$set']: [
                          {
                            __typename: 'TicketTimelineActivity',
                            date:
                              data.createTicketActivity.TimeLocal ||
                              new Date().toISOString(),
                            description:
                              data.createTicketActivity.ActivityDescription,
                            contactName: data.createTicketActivity.ContactName,
                            isApproval: data.createTicketActivity.QuoteApproval
                          }
                        ]
                      }
                    }
                  }
                }
              };
              const updated = update(storeData, operation);
              store.writeQuery({
                ...q,
                data: updated
              });
            }
          }).then(
            r => {
              dispatch(actions.reset(FORM_NAME));
            },
            r => {
              console.warn('Submission error', r);
              return Promise.reject(
                new SubmissionError({
                  _error: intl.formatMessage({
                    id: 'tickets.there_was_an_error_saving_update'
                  })
                })
              );
            }
          );
        }
      };
    }
  }),
  reduxForm({
    form: FORM_NAME,
    initialValues: {
      ActivityDescription: '',
      QuoteApproval: false
    },
    validate: values => {
      const errors = {};
      if (!values.ActivityDescription) errors.ActivityDescription = 'Required';
      return errors;
    },
    onChange: (values, dispatch, {currentUser}, previousValues) => {
      if(values.QuoteApproval !== previousValues.QuoteApproval) {
        let description = values.ActivityDescription;
        if (values.QuoteApproval) {
          if(description.indexOf('Estimate is approved') === -1) {
            description = (description.trimEnd() + '\nEstimate is approved by ' + currentUser.NameLF + '\n').trimStart();
          }
        } else {
          description = description.replace(/\s*Estimate is approved.*$/m, '');
        }
        if(description !== values.ActivityDescription) {
          dispatch(actions.change(FORM_NAME, 'ActivityDescription', description));
        }
      }
    }
  }),
  connect(state => ({
    // values from redux form...
    currentValue: selector(state, 'ActivityDescription'),
    quoteApproval: selector(state, 'QuoteApproval'),
  }))
)(TicketActivityInput);
