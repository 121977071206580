/*
 * Client side validation of selections on the Insert Ticket  / Create Store form
 */
export default function validateNewStore(values, {intl}) {
  const errors = {};
  ['AccountName', 'StoreNumber', 'City', 'State'].forEach(field => {
    // TODO need to figure out how to pass intl prop
    if (!values[field])
      errors[field] = intl.formatMessage({
        id: 'tickets.utils_validate_store_errors'
      });
  });
  return errors;
}
