import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {ButtonBase} from '@material-ui/core';
import clsx from 'clsx';

export const BadgeButton = ({title = '', amount = 10}) => {
  const classes = useStyles();
  return (
    <div className={classes.button}>
      <h2 className={classes.buttonTitle}>{title}</h2>
      {amount ? <Badge>{amount}</Badge> : null}
    </div>
  );
};

export const Badge = ({children, className, inverse = false}) => {
  const classes = useStyles();
  return (
    <div
      className={clsx(
        classes.badge,
        {[classes.badgeInverse]: inverse},
        className
      )}>
      <span
        className={clsx(classes.badTitle, {
          [classes.badTitleInverse]: inverse
        })}>
        {children}
      </span>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  badge: {
    height: 20,
    width: 20,
    backgroundImage: 'linear-gradient(to bottom, #e4002b, #a6192e)',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  badgeInverse: {
    backgroundColor: '#fff',
    backgroundImage: 'none'
  },
  badTitle: {
    color: '#ffffff',
    fontFamily: 'Nunito',
    fontSize: '1rem'
  },
  badTitleInverse: {
    color: '#a6192e'
  },
  button: {
    padding: '20px 4px 20px 4px',
    boxShadow: '0 1.5px 3px 0 #00000029',
    border: 'solid 0.5px #707070',
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    height: 35
  },
  buttonTitle: {
    fontFamily: 'Nunito',
    fontSize: '1.2rem',
    color: '#686b6b',
    margin: 0,
    marginRight: 5
  }
}));
