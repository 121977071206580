export const EMPTY = {};

export const priorityColors = {
  urgent: 'red',
  medium: 'yellow',
  low: 'green'
};
// QSI blue
export const primaryColor = '#0e66b4';
// QSI orange
export const secondaryColor = '#f17325';
export const black = '#333';
export const white = '#ffffff';
export const thinBorder = '0.5px solid #e8e7e7';

// Completed status for tickets
export const STATUS_COMPLETED = 'Completed';
export const KPI_DAYS = process.env.KPI_DAYS || '90';
