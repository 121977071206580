import React from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core/';
// import {makeStyles} from '@material-ui/core/styles';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Cleaning,
  HVAC,
  Landscaping,
  Painting,
  Plumbing,
  Electrical,
  // FireLifeSafety,
  Handyman,
  KeysLock,
  // Lighting,
  OtherIcon
  // PestControl,
  // ProjectWork,
  // AudioVisual
} from '../../icons';
import CategoryItem from './CategoryItem';
import ProblemItem from './ProblemItem';
import {CurtainTransition} from '../../../shared/components/styles';
import SelectionList from '../../../shared/components/inputs/SelectionList';

// import map from 'lodash/map';
// import sortBy from 'lodash/sortBy';
import filter from 'lodash/filter';

const ICONS = {
  HVAC: props => <HVAC {...props} />,
  Plumbing: props => <Plumbing {...props} />,
  Cleaning: props => <Cleaning {...props} />,
  Painting: props => <Painting {...props} />,
  Landscaping: props => <Landscaping {...props} />,
  Handyman: props => <Handyman {...props} />,
  Electrical: props => <Electrical {...props} />,
  Lock: props => <KeysLock {...props} />,
  Other: props => <OtherIcon {...props} />
};

// const useStyles = makeStyles(theme => ({
//   root: {
//     width: '100%'
//   },
//   heading: {
//     fontSize: theme.typography.pxToRem(15),
//     fontWeight: theme.typography.fontWeightRegular
//   }
// }));

const TradeSelection = props => {
  const {picklist} = props.fieldMetadata;
  if (!picklist || !picklist.length) return null;
  const catsWithIcon = filter(picklist, p => p in ICONS);
  const catsNoIcon = filter(picklist, p => !ICONS[p]);
  const [othersActive, setOthersActive] = React.useState(false);
  React.useEffect(
    () => {
      // ensure the "Others" list is shown if the current selection belongs to it
      if (props.input.value && catsNoIcon.includes(props.input.value)) {
        setOthersActive(true);
      }
    },
    [props.input.value]
  );

  function onChange(val) {
    if (val) {
      if (props.onChangeAction) {
        props.onChangeAction(val);
      }
      if (props.input.onChange) {
        props.input.onChange(val);
      }
    }
  }
  // const categories = sortBy(
  //   map(picklist, pkl => ({
  //     title: pkl,
  //     icon: ICONS[pkl] ? ICONS[pkl] : ICONS['Other']
  //   })),
  //   ['icon', 'title']
  // ).filter(item => ICONS[item.title] !== undefined);

  // const otherCategories = sortBy(
  //   map(picklist, pkl => ({
  //     title: pkl,
  //     hasIcon: !!ICONS[pkl]
  //   })),
  //   ['hasIcon', 'title']
  // ).filter(pl => ICONS[pl.title] === undefined);
  const OtherIcon = ICONS['Other'];
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap'
      }}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          paddingBottom: 20
        }}>
        {catsWithIcon.map((item, index) => {
          const Icon = ICONS[item];
          const isActive = !othersActive && props.input.value === item;
          return (
            <CategoryItem
              key={item}
              onClick={onChange}
              title={item}
              isActive={isActive}>
              {Icon && <Icon color={isActive ? '#fff' : '#e4002b'} />}
            </CategoryItem>
          );
        })}
        <CategoryItem
          key={'OTHERS'}
          onClick={() => setOthersActive(!othersActive)}
          title={'Others'}
          isActive={othersActive}>
          <OtherIcon color={othersActive ? '#fff' : '#e4002b'} />
        </CategoryItem>
      </div>
      <CurtainTransition show={othersActive}>
        <SelectionList
          items={catsNoIcon}
          onChange={onChange}
          value={props.input.value}
        />
      </CurtainTransition>
    </div>
  );
};

TradeSelection.propTypes = {
  fieldMetadata: PropTypes.shape({
    picklist: PropTypes.array.isRequired
  }).isRequired,
  // optional change action
  onChangeAction: PropTypes.func,
  // input props (via redux form)
  input: PropTypes.shape({
    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    // will be used as name of input and to get the field metadata
    name: PropTypes.string.isRequired
  }).isRequired
};

export default TradeSelection;
