import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Custom checkbox component
const Checkbox = props => {
  const inputProps = props.input ? props.input : props;
  const val =
    typeof inputProps.value === 'string' ? inputProps.value : undefined;
  const checked =
    typeof inputProps.value === 'boolean'
      ? inputProps.value
      : inputProps.checked;
  const onChange = props.input
    ? evt => props.input.onChange(evt.target.checked)
    : props.onChange;
  return (
    <div className={classNames('checkbox', props.className)}>
      <label className="control-label">
        <input
          type="checkbox"
          checked={checked}
          value={val}
          name={props.name}
          disabled={props.disabled}
          onChange={onChange}
        />
        {props.label}
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  labelOnLeft: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  className: PropTypes.string,
  // optional name will be applied to the input element
  name: PropTypes.string,
  // for redux form
  input: PropTypes.object
};

export default Checkbox;
