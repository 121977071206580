import React from 'react';
import PropTypes from 'prop-types';

import FormField from '../../shared/components/inputs/FormField';
import Input from '../../shared/components/inputs/Input';
import Picklist from '../../shared/components/inputs/Picklist';
import EditablePicklist from '../../shared/components/inputs/EditablePicklist';
import Dialog from '../../shared/components/Dialog';
import Spinner from '../../shared/components/Spinner';
import Alert from '../../shared/components/Alert';
import {injectIntl} from 'react-intl';

// store creation form
const StoreInsertForm = ({
  handleSubmit,
  cancel,
  error,
  submitting,
  intl,
  storeFieldMetadata,
  ...otherProps
}) => (
  <Dialog
    title={intl.formatMessage({id: 'tickets.store_insert_title'})}
    confirmLabel={intl.formatMessage({id: 'tickets.save'})}
    onConfirm={handleSubmit}
    onDismiss={cancel}
    className="new-store"
    size="small">
    {submitting && <Spinner loadingText="Saving Location..." />}

    <FormField
      name="AccountName"
      fieldMetadata={storeFieldMetadata}
      component={Input}
      required
    />
    <FormField
      name="StoreNumber"
      fieldMetadata={storeFieldMetadata}
      component={Input}
      required
    />
    <FormField
      name="Address1"
      fieldMetadata={storeFieldMetadata}
      component={Input}
    />
    <FormField
      name="Address2"
      fieldMetadata={storeFieldMetadata}
      component={Input}
    />
    <FormField
      name="City"
      fieldMetadata={storeFieldMetadata}
      component={EditablePicklist}
    />
    <div className="row">
      <div className="col-xs-6">
        <FormField
          name="State"
          fieldMetadata={storeFieldMetadata}
          component={Picklist}
        />
      </div>
      <div className="col-xs-6">
        <FormField
          name="PostalCode"
          fieldMetadata={storeFieldMetadata}
          component={Input}
        />
      </div>
    </div>
    <div className="row">
      <div className="col-xs-6">
        <FormField
          name="MainPhone"
          fieldMetadata={storeFieldMetadata}
          component={Input}
          required
        />
      </div>
      <div className="col-xs-6">
        <FormField
          name="Country"
          fieldMetadata={storeFieldMetadata}
          component={Input}
        />
      </div>
    </div>

    <Alert error={error} />
  </Dialog>
);

StoreInsertForm.propTypes = {
  cancel: PropTypes.func.isRequired,
  // from redux form
  handleSubmit: PropTypes.func.isRequired,
  fieldMetadata: PropTypes.object.isRequired
};

export default injectIntl(StoreInsertForm);
