import React from 'react';
import {Link} from 'react-router-dom';
import clsx from 'clsx';
import styled from 'styled-components';
import formatRelativeDate from '../../utils/formatRelativeDate';
import formatDate from '../../utils/formatDate';
import {FormattedMessage, injectIntl} from 'react-intl';

export const ITEM_HEIGHT = 76;

const Item = styled.article`
  &:hover {
    background-color: #f5f5f5;
  }
  height: ${ITEM_HEIGHT}px;
  @media (max-width: 768px) {
    line-height: 1.1em;
  }
`;
const ListTitle = styled.span`
  font-weight: bold;
`;
const ListBody = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
const ListSubtitle = styled.small`
  color: #a9a9a9;
`;

// NOTE that if this element is updated in a way that makes the item change height,
// the itemHeight needs to be updated in ListDetails
const ListItem = ({ticket, selected, fieldMetadata, intl, permissions}) => (
  <Link to={`/ticket/${ticket.id}`}>
    <Item className={clsx({active: selected}, 'list-group-item')}>
      <ListTitle>
        {fieldMetadata.TicketNumber.label}: {ticket.TicketNumber}
      </ListTitle>
      <ListTitle className="pull-right">
        {fieldMetadata.StoreNumber.label}: {ticket.StoreNumber}
      </ListTitle>
      <ListBody>
        {ticket.Subject ||
          intl.formatMessage({id: 'tickets.list_item_no_subject'})}
      </ListBody>
      <ListSubtitle>{ticket.Area}</ListSubtitle>
      <ListSubtitle className="pull-right" style={{marginLeft: '10px'}}>
        <FormattedMessage id="tickets.list_item_due" />:{' '}
        {formatRelativeDate(ticket.CompletedDate, intl)}
      </ListSubtitle>
      {permissions.viewTickets_ReceivedDate && (
        <ListSubtitle className="pull-right" style={{marginLeft: '10px'}}>
          <FormattedMessage id="tickets.list_item_received" />:{' '}
          {formatDate(ticket.ReceivedDate)}
        </ListSubtitle>
      )}
    </Item>
  </Link>
);

export default injectIntl(ListItem);
