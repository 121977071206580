import {withHandlers, compose} from 'recompose';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {signOut} from '../actions/index';

export default compose(
  withRouter,
  connect(
    null,
    {signOut}
  ),
  withHandlers({
    signOut: ({signOut, history}) => e => {
      // we need to add the history component to be able to access the router...
      signOut(history);
    }
  })
);
