import {connect} from 'react-redux';
import {compose} from 'recompose';
import {rootClick, toggleMenu, toggleAccountSwitcher} from '../actions';
import withAuth from '../../auth/hoc/withAuth';

// HOC for the application-level page
export default compose(
  withAuth,
  connect(
    state => ({
      isMenuExtended: state.shared.isMenuExtended,
      isAccountSwitcherOpen: state.shared.isAccountSwitcherOpen
    }),
    {
      rootClick,
      toggleAccountSwitcher,
      toggleMenu
    }
  )
);
