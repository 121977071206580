import React from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Banners from '../components/Banners';
import DrawerMenu from '../components/drawer_menu/DrawerMenu';
import TopAppBar from '../components/drawer_menu/TopAppBar';
import {white} from '../../constants';

const drawerWidth = 140;

const ApplicationPage = ({
  // match,
  currentUser,
  // rootClick,
  // toggleMenu,
  toggleAccountSwitcher,
  isAccountSwitcherOpen,
  children
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleDrawer = () => setOpen(!open);
  return (
    <div className={classes.root}>
      <TopAppBar
        open={open}
        handleDrawerOpen={handleDrawer}
        currentUser={currentUser}
      />
      <DrawerMenu
        open={open}
        handleDrawerClose={handleDrawer}
        currentUser={currentUser}
      />
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open
        })}>
        <div className={classes.drawerHeader} />
        <Banners
          banners={currentUser.ParentAccount.BannerConfig}
          where={x => x.IsHomeScreen && !x.IsBottom}
          type="warning"
        />
        {children}
      </main>
    </div>
  );
};

ApplicationPage.propTypes = {
  currentUser: PropTypes.object.isRequired
  // match: PropTypes.object.isRequired,
  // toggleMenu: PropTypes.func.isRequired,
  // rootClick: PropTypes.func.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    // prevent scrolling on the root component, it causes interface to be "jiggly" on mobiles
    overflow: 'hidden'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth,
    [theme.breakpoints.down('md')]: {
      transition: 'none',
      marginLeft: 'initial',
      padding: theme.spacing(1)
    },
    backgroundColor: white
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0,
    [theme.breakpoints.down('md')]: {
      transition: 'none'
    }
  }
}));

export default ApplicationPage;

// vim ft=javascript.jsx
