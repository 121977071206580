import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Select from 'react-select';
import FranceFlag from './flags/fr.png';
import USFlag from './flags/us.png';

const StyledContainer = styled.div`
  margin: 0.2rem 0.3rem 0;
  text-align: right;
  width: 180px;
`;

const languages = [
  {
    value: 'fr',
    locale: 'fr-FR',
    label: (
      <div style={{display: 'flex'}}>
        <img
          src={FranceFlag}
          style={{height: 20, width: 25, marginRight: 10}}
          alt="France flag"
        />
        <span>Français</span>
      </div>
    )
  },
  {
    value: 'en',
    locale: 'en-US',
    label: (
      <div style={{display: 'flex'}}>
        <img
          src={USFlag}
          style={{height: 20, width: 25, marginRight: 10}}
          alt="USA flag"
        />
        <span>English US</span>
      </div>
    )
  }
];

const LanguageSwitcher = ({intl, style = {}}) => {
  function handleOnChange({locale}) {
    window.location.replace('/login?locale=' + locale);
  }
  const defaultValue = languages.find(item => item.locale === intl.locale);
  return (
    <StyledContainer style={{...style}}>
      <Select
        options={languages}
        style={{width: 230}}
        defaultValue={defaultValue}
        placeholder={intl.formatMessage({id: 'pages.sign_in_language'})}
        onChange={handleOnChange}
      />
    </StyledContainer>
  );
};

LanguageSwitcher.propTypes = {
  intl: PropTypes.object.isRequired
};

export default LanguageSwitcher;
