import {handleActions} from 'redux-actions';

import * as types from '../actions/types';

const defaultState = {
  showCancel: false,
  createdTicket: null
};

export default handleActions(
  {
    [types.INSERT_CONFIRM_CANCEL]: state => ({...state, showCancel: true}),
    [types.INSERT_HIDE_CANCEL]: state => ({...state, showCancel: false}),
    [types.INSERT_TICKET_SAVED]: (state, {payload: {ticket}}) => ({
      ...state,
      createdTicket: ticket
    }),
    '@@redux-form/DESTROY': state => ({...state, createdTicket: null})
  },
  defaultState
);
