import {createAction} from 'redux-actions';
import Authentication from '../utils/Authentication';

import * as types from './types';

export const toggleMenu = e => {
  e.stopPropagation();
  return {
    type: types.TOGGLE_MENU
  };
};
export const signOut = (history, reason = '') => {
  Authentication.logout();
  // ideally we should clear the apollo store, but this causes a bug when logging back in
  // setTimeout(() => {
  //   // in timeout so that the queries don't try to re-run right away
  //   apolloClient.resetStore()
  // });
  history.replace('/login');
  // we could do this to make sure we have cleared everything, but this is a lot slower
  window.location.replace('/login');
  return {
    type: types.AUTH_SIGNOUT,
    payload: reason
  };
};

export const signIn = createAction(types.AUTH_SIGNIN);
