import React from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import {injectIntl} from 'react-intl';
import Glyphicon from '../../shared/components/Glyphicon';

class Video extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      playing: false
    };
  }

  play = () => {
    // console.log('PLAY');
    this.setState({
      playing: true
    });
  };

  stop = () => {
    // console.log('STOP');
    this.setState({
      playing: false
    });
  };

  render() {
    const {video} = this.props;
    return (
      <Container className="col-md-4 col-sm-6 col-xs-12">
        <div style={{position: 'relative'}}>
          <div className="overlay" onClick={this.play}>
            <Glyphicon glyph="play" />
          </div>
          <img
            src={video.thumbnailUrl}
            alt={this.props.intl.formatMessage({id: 'tickets.utils_video_img'})}
          />
        </div>
        <h6>{video.title}</h6>
        <ReactModal
          shouldCloseOnOverlayClick
          ariaHideApp={false}
          onRequestClose={this.stop}
          isOpen={this.state.playing}
          style={{
            content: {
              width: '590px',
              height: '345px',
              padding: '15px',
              overflow: 'hidden',
              position: 'static'
            },
            overlay: {
              // should have a transition, ideally...
              backgroundColor: 'rgba(0, 0, 0, .7)',
              zIndex: 15,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }
          }}>
          <iframe
            title={video.title}
            width="560"
            height="315"
            src={video.videoUrl}
            frameBorder="0"
            allowFullScreen
          />
        </ReactModal>
      </Container>
    );
  }
}

const Container = styled.div`
  padding: 20px;
  position: relative;

  img {
    width: 100%;
  }

  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.2);
    // opacity: 0.3;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    cursor: pointer;
  }
`;

export default injectIntl(Video);
