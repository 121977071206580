import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';

const Authentication = {
  login(user, password, intl) {
    return Authentication.call('login?locale=' + intl.locale, intl, 'POST', {
      user,
      password
    }).then(response => {
      // Should we save anything?
      // currentUser = response.userInfo
      // save the token for future requests
      // localStorage.apiToken = response.token
      return response.userInfo;
    });
  },

  logout() {
    Cookies.remove('jwt');
  },

  requestPasswordReset(email, intl) {
    return Authentication.call('reset-password/request', intl, 'POST', {
      email
    }).then(response => response.message);
  },

  checkPasswordResetToken(token, intl) {
    return Authentication.call('reset-password/verify', intl, 'POST', {
      token
    }).catch(err =>
      Promise.reject(
        new Error(
          'Password reset token could not be verified - please try the "Forgot Password" link again and make sure you are not using an older token'
        )
      )
    );
  },

  resetPassword(token, password, intl) {
    return Authentication.call('reset-password/reset', intl, 'POST', {
      token,
      password
    })
      .then(response => response.message)
      .catch(err =>
        Promise.reject(
          new Error(intl.formatMessage({id: 'authentication.resetting_error'}))
        )
      );
  },

  // current user info (from token), or null if not logged in
  getCurrentUser() {
    const token = Cookies.get('jwt');
    if (token) {
      const decoded = jwtDecode(token);
      if (decoded && decoded.exp > new Date().valueOf() / 1000) {
        return decoded;
      }
    }
    return null;
  },

  // for Apollo client
  // getToken() {
  //   return localStorage.apiToken
  // },

  // make an authenticated API call
  call(endpoint, intl, method = 'GET', data = null) {
    const params = {
      method,
      // include cookies with request, and save returned cookies
      credentials: 'include',
      headers: {}
    };
    if (data) {
      params.body = JSON.stringify(data);
      params.headers['Content-Type'] = 'application/json';
    }
    // if (localStorage.apiToken) {
    //   params.headers['X-API-Token'] = localStorage.apiToken
    // }

    // XXX do we need a polyfill for IE?
    return fetch('/api/' + endpoint, params)
      .then(response => {
        // console.log(response);
        if (!response.ok) {
          if (response.status === 500) {
            return Promise.reject('Error communicating with the server');
          }
          return response.text().then(text => Promise.reject(text));
        }
        return response.text().then(text => text && JSON.parse(text));
      }, err => {
        console.warn('Network error', err);
        return Promise.reject('Network error, please try again');
      })
      .catch(err => {
        console.warn('API Request failed', err);
        return Promise.reject(err);
      });
  }
};
export default Authentication;
