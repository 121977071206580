import React from 'react';
import {LogoutButton, LoginHeader} from '../../shared/components/styles';
import {FormattedMessage} from 'react-intl';

// This page is normally not displayed since we redirect right away to
// the /login page
const SignOutPage = ({reason}) => (
  <form className="form-signin">
    <LoginHeader className="form-signin-heading">
      <FormattedMessage id="pages.sign_out_title" />
    </LoginHeader>
    <div className="login-wrap">
      <h4>
        <FormattedMessage id="pages.sign_out" />
      </h4>

      {reason && <p className="error">{reason}</p>}
      <LogoutButton className="btn btn-lg btn-login btn-block" href="/login">
        <FormattedMessage id="pages.sign_out_sign_in_again" />
      </LogoutButton>
    </div>
  </form>
);

export default SignOutPage;
