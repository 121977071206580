import React from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import {compose, withHandlers} from 'recompose';
import classNames from 'classnames';
import DateRangeFilter from '../../shared/components/inputs/DateRangeFilter';
import TextBoxFilter from '../../shared/components/inputs/TextBoxFilter';
import PickListFilter from '../../shared/components/inputs/PickListFilter.js';
import TrueFalseFilter from '../../shared/components/inputs/TrueFalseFilter.js';
import ReactSelect from '../../shared/components/inputs/ReactSelect';
import {EMPTY} from '../../constants';
import {hasFilterKey} from '../utils/filterUtils';
import {injectIntl} from 'react-intl';
import styled from 'styled-components';

const FilterControl = ({
  metadata,
  field,
  value,
  setFilterField,
  setFilterValue,
  intl
}) => (
  <div className="row no-gutters">
    <div className={classNames('col-md-12', {'col-lg-5': !!field})}>
      <ReactSelect
        className="filter-field"
        options={makeOptions(metadata)}
        value={field}
        onChange={setFilterField}
      />
    </div>
    {field && (
      <FilterPane className="col-lg-7 col-md-12">
        {renderControl(
          find(metadata, {name: field}),
          value || EMPTY,
          setFilterValue,
          intl
        )}
      </FilterPane>
    )}
  </div>
);
const makeOptions = metadata =>
  metadata.map(m => ({label: m.label, value: m.name}));

// for rendering the specific input field according to the filter type
const renderControl = (fieldMeta, filterValue, setFilterValue, intl) => {
  if (!fieldMeta) return <div />;
  const filterComponent = selectFilter[fieldMeta.type];
  if (!filterComponent)
    throw new Error(
      intl.formatMessage(
        {
          id: 'tickets.filter_control_error'
        },
        {type: fieldMeta.type}
      )
    );
  const filterProps = {
    filterValue,
    setFilterValue,
    pickListValues: fieldMeta.picklist
  };
  // Completed Date = Customer ECD
  // NeededByDate = Needed By (for Regus ONLY)
  if (fieldMeta.name === 'NeededByDate' || fieldMeta.name === 'CompletedDate')
    filterProps.futureRanges = true;
  if (fieldMeta.type === 'SinglePicklist') filterProps.multi = false;
  return React.createElement(filterComponent, filterProps);
};

const FilterPane = styled.div`
  & > div:first-of-type > div:first-of-type {
    // to avoid having the list out of screen if too many options selected
    max-height: 35vh;
    overflow: auto;
  }
`;

const selectFilter = {
  Date: DateRangeFilter,
  DateTime: DateRangeFilter,
  Picklist: PickListFilter,
  SinglePicklist: PickListFilter,
  String: TextBoxFilter,
  Boolean: TrueFalseFilter
};

FilterControl.propTypes = {
  // the available fields
  metadata: PropTypes.array.isRequired,
  // the name of the currently selected field
  field: PropTypes.string,
  // the current value - this includes the operator, but would only makes sense if the field is selected
  value: PropTypes.object
};

export default compose(
  injectIntl,
  withHandlers({
    // add the index parameter for the setFilterField / setFilterValue handlers
    setFilterField: ({setFilterField, index}) => evt =>
      setFilterField(index, evt && evt.value),
    setFilterValue: ({setFilterValue, index}) => (val, debounce) => {
      // console.log('setFilterValue', val)
      // console.log('hasFilterKey', hasFilterKey(val))
      return setFilterValue(index, hasFilterKey(val) ? val : EMPTY, debounce);
    }
  })
)(FilterControl);
