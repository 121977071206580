import {graphql} from 'react-apollo';
import {compose, withHandlers, withStateHandlers, withState} from 'recompose';
import {connect} from 'react-redux';
import reduxForm from 'redux-form/lib/reduxForm';
import find from 'lodash/find';
import formValueSelector from 'redux-form/lib/formValueSelector';
import SubmissionError from 'redux-form/lib/SubmissionError';
import loadingSpinner from '../../shared/hoc/loadingSpinner';
import {setView} from '../actions/list';
import {
  DELETE_TICKETVIEW_MUTATION,
  SAVE_TICKETVIEW_MUTATION,
  TICKET_VIEWS_QUERY
} from '../queries';
import {injectIntl} from 'react-intl';

const FORM_NAME = 'TicketSavedView';

function validate(values) {
  if (!values.name) return {name: 'Required'};
  return {};
}

export default compose(
  injectIntl,
  // state for the save / load panels
  withStateHandlers(
    {isSavePanelOpen: false, isLoadPanelOpen: false},
    {
      openSavePanel: () => () => ({
        isSavePanelOpen: true,
        isLoadPanelOpen: false
      }),
      openLoadPanel: () => () => ({
        isSavePanelOpen: false,
        isLoadPanelOpen: true
      }),
      closePanel: () => e => {
        if (e) e.preventDefault();
        return {isSavePanelOpen: false, isLoadPanelOpen: false};
      }
    }
  ),

  // query the existing views, with their metadata.
  // We do that when showing the component, this way they won't have to wait if they want to use one.
  graphql(TICKET_VIEWS_QUERY, {
    props: ({data}) => ({
      loading: data.loading,
      error: data.error,
      savedViews: data.ticketViews || []
    })
  }),
  loadingSpinner,

  // redux data and actions
  connect(
    state => ({
      filter: state.tickets.list.filter,
      sort: state.tickets.list.sort,
      selectedFields: state.tickets.list.selectedFields,
      includeFilters: formValueSelector(FORM_NAME)(state, 'includeFilters')
    }),
    {
      setView
    }
  ),

  // handler for applying a view
  withState('selectedView', 'setSelectedView', ''),
  withStateHandlers(
    {selectedView: ''},
    {
      setSelectedView: () => e => ({
        selectedView: e.target ? e.target.value : e
      })
    }
  ),
  withHandlers({
    useView: ({setView, savedViews, selectedView}) => e => {
      if (selectedView) {
        const view = find(savedViews, v => v.name === selectedView);
        if (view && view.definition) {
          setView(JSON.parse(view.definition));
        }
      }
    }
  }),

  // save the view for the current user.  We get the current view metadata from redux and save it with a Graphql mutation
  graphql(SAVE_TICKETVIEW_MUTATION, {
    props: ({
      ownProps: {
        intl,
        closePanel,
        selectedFields,
        includeFilters,
        filter,
        sort
      },
      mutate
    }) => ({
      onSubmit(values, dispatch) {
        return mutate({
          variables: {
            name: values.name,
            definition: JSON.stringify({
              fields: selectedFields,
              filter: includeFilters && filter,
              sort: includeFilters && sort
            })
          },
          refetchQueries: [
            {
              query: TICKET_VIEWS_QUERY
            }
          ]
        }).then(
          ({data}) => {
            closePanel();
          },
          r => {
            console.warn('Submission error', r);
            return Promise.reject(
              new SubmissionError({
                _error: intl.formatMessage({
                  id: 'tickets.there_was_an_error_saving_update'
                })
              })
            );
          }
        );
      }
    })
  }),
  reduxForm({
    form: FORM_NAME,
    validate
  }),

  // handler for deleting the view
  withState('deleting', 'setDeleting', false),
  withState('deleteError', 'setDeleteError', ''),
  graphql(DELETE_TICKETVIEW_MUTATION, {
    props: ({
      ownProps: {
        intl,
        selectedView,
        setSelectedView,
        setDeleting,
        setDeleteError,
        closePanel
      },
      mutate
    }) => ({
      deleteView(e) {
        e.preventDefault();
        const name = selectedView;
        setDeleting(true);
        mutate({
          variables: {name},
          refetchQueries: [{query: TICKET_VIEWS_QUERY}]
        })
          .then(
            () => {
              closePanel();
              setSelectedView('');
            },
            r => {
              console.warn('Submission error', r);
              setDeleteError(
                intl.formatMessage({id: 'tickets.could_not_delete_view'})
              );
            }
          )
          .then(() => setDeleting(false));
      }
    })
  })
);
