import {branch, withProps, renderComponent, compose} from 'recompose';
import Alert from '../components/Alert';

// render an error alert if the specified prop does not exist in the props
export default function(propName) {
  return branch(
    props => !props[propName],
    compose(
      withProps(() => ({error: 'The specified record cannot be located'})),
      renderComponent(Alert)
    )
  );
}
