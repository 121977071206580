import React from 'react';
import InputWrapper from './InputWrapper';
import {withStyles} from '@material-ui/core/styles';
import {styles} from '../styles';

/**
 * <Input />
 */
const Input = props => {
  const classes = props.classes;
  // props.input is used for redux form fields
  let inputProps = props.input ? props.input : props;
  // carry over some of the props, even if we were given an "input" prop
  if (props.readOnly) inputProps.readOnly = props.readOnly;
  if (props.maxLength) inputProps.maxLength = props.maxLength;
  if (props.type) inputProps.type = props.type;
  // allow for a bag of additional props.. this is used when the component is included as a redux form field
  if (props.inputProps) inputProps = {...inputProps, ...props.inputProps};
  return (
    <InputWrapper {...props}>
      <input
        type={inputProps.type || 'text'}
        {...inputProps}
        className={classes.input}
      />
    </InputWrapper>
  );
};

export default withStyles(styles)(Input);
