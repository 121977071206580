import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import ReactSelect from './ReactSelect';

// A picklist filter implemented as a list of checkboxes
const PickListFilter = ({
  className,
  pickListValues,
  filterValue,
  setFilterValue,
  valueProp,
  labelProp,
  multi = true
}) => {
  const options = pickListValues.map(pv => ({
    label: labelProp ? pv[labelProp] : pv,
    value: valueProp ? pv[valueProp] : pv
  }));
  const onChange = multi
    ? values => setFilterValue({in: map(values, 'value')})
    : value => setFilterValue({eq: value ? value.value : undefined});

  return (
    <ReactSelect
      isMulti={multi}
      value={multi ? filterValue.in || [] : filterValue.eq || ''}
      options={options}
      onChange={onChange}
      className={className}
    />
  );
};

PickListFilter.propTypes = {
  pickListValues: PropTypes.array.isRequired,
  // the currently selected values (can be null)
  filterValue: PropTypes.object.isRequired,
  setFilterValue: PropTypes.func.isRequired,
  // optional property name to use to extract value from the picklistValues (used for lookup filter)
  // if they are not provided we'll just use the values as is
  valueProp: PropTypes.string,
  labelProp: PropTypes.string
};

//noinspection JSUnusedGlobalSymbols
export default PickListFilter;
