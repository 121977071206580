import {graphql, compose} from 'react-apollo';
import {gql} from 'react-apollo';

import loadingSpinner from '../../shared/hoc/loadingSpinner';
import errorIfNotFound from '../../shared/hoc/errorIfNotFound';

const QUERY = gql`
  query {
    getVideos {
      id
      title
      videoUrl
      thumbnailUrl
    }
  }
`;

const container = compose(
  graphql(QUERY, {
    props: ({data}) => ({
      loading: data.loading,
      error: data.error,
      videos: data.getVideos
    })
  }),
  loadingSpinner,
  errorIfNotFound('videos')
);

export default container;
