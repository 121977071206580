import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {compose, withStateHandlers, setPropTypes} from 'recompose';
import {Link} from 'react-router-dom';
import classNames from 'classnames';
import {CurtainTransition} from './styles';
import ClearButton from './ClearButton';
import Glyphicon from './Glyphicon';

const CollapsibleSection = ({
  title,
  helpText,
  collapsed,
  toggleSection,
  children,
  calcHeight,
  calcHeightRef,
  toolbar,
  urlRoot,
  className
}) => (
  <Section className={classNames('panel', 'panel-primary', className)}>
    <header className="panel-heading">
      <Title className="panel-title">{title}</Title>
      <div className="pull-right toolbar">
        {toolbar && toolbar.map(tool => renderToolbarItem(tool, urlRoot))}
        <Toggle
          label="Toggle Section"
          onClick={toggleSection}
          collapsed={collapsed}>
          <Glyphicon glyph="chevron-down" />
        </Toggle>
      </div>
    </header>
    {/* can we calculate this height? */}
    <CurtainTransition show={!collapsed} maxHeight={calcHeight + 'px'}>
      <div className="panel-body" ref={calcHeightRef}>
        {children}
      </div>
    </CurtainTransition>
  </Section>
);

const renderToolbarItem = (tool, urlRoot) =>
  tool.path ? (
    <Link
      key={tool.path}
      className={classNames('btn', tool.btnClass)}
      label={tool.label}
      to={`${urlRoot}/${tool.path}`}>
      <Glyphicon glyph={tool.icon} />
      {tool.showLabel ? <span>&nbsp;{tool.label}</span> : ''}
    </Link>
  ) : (
    <ClearButton key={tool.label} label={tool.label} onClick={tool.action}>
      <Glyphicon glyph={tool.icon} />
    </ClearButton>
  );

// styles
const Section = styled.section`
  margin: 1rem 0;

  & > header {
    padding: 0;

    .toolbar {
      margin-top: 1px;
    }
  }
  & > div {
    width: 100%;
  }
`;

const Title = styled.h3`
  display: inline-block;
  margin: 10px;
`;

const Toggle = styled(ClearButton)`
  transition: transform 0.3s;
  ${props => props.collapsed && 'transform: rotate(180deg);'};
`;

// state
const container = compose(
  setPropTypes({
    title: PropTypes.string.isRequired,
    // required if we are using toolbar
    urlRoot: PropTypes.string,
    toolbar: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
        path: PropTypes.string,
        action: PropTypes.func,
        showLabel: PropTypes.bool
      })
    )
  }),
  withStateHandlers(
    {collapsed: false, calcHeight: 300},
    {
      toggleSection: ({collapsed}) => e => ({collapsed: !collapsed}),
      calcHeightRef: () => ref => {
        //const node = ReactDOM.findDOMNode(ref)
        return ref && {calcHeight: Math.max(300, ref.offsetHeight)};
      }
    }
  )
);

export default container(CollapsibleSection);
