import {connect} from 'react-redux';
import reduxForm from 'redux-form/lib/reduxForm';
import formValueSelector from 'redux-form/lib/formValueSelector';
import {compose, withProps} from 'recompose';
import updateTicketMutation from '../hoc/updateTicketMutation';
import ManageSelfPerformedDialog from '../components/ManageSelfPerformedDialog';
import {injectIntl} from 'react-intl';

export const FORM_NAME = 'ManageSelfPerformed';

export default compose(
  updateTicketMutation,
  connect(state => ({
    // current values, so we can hide / show the parent lookup
    selfPerformed: formValueSelector(FORM_NAME)(state, 'SelfPerformedTicket')
  })),
  withProps(({ticket}) => ({
    initialValues: {
      VendorContact: ticket.VendorContact && {
        label: ticket.VendorContact.NameLF,
        value: ticket.VendorContact.id
      },
      SelfPerformedTicket: ticket.SelfPerformedTicket || false
    }
  })),
  injectIntl,
  reduxForm({
    form: FORM_NAME,
    validate: (values, {intl}) => {
      const errors = {};
      if (
        values.SelfPerformedTicket &&
        (!values.VendorContact || !values.VendorContact.value)
      ) {
        errors.VendorContact = intl.formatMessage({id: 'shared.required'});
      }
      return errors;
    }
  })
)(ManageSelfPerformedDialog);
