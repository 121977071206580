import moment from 'moment';

// format a date as in "a day ago", "13 hours ago", etc. relative to today (local user time)
//
// otherDate: string representation of the date (ISO8601)
//      include timezone offset or "Z" for UTC
// intl: just used for translation of "Today"
// withTime: if false, it will round both date to start of day
//      this is useful if we are not sure of the timezone the event refers to
export default function formatRelativeDate(otherDate, intl, withTime = false) {
  if (!otherDate) return 'N/A';
  const other = moment(otherDate);
  if (withTime) {
    return other.from(moment());
  }
  if (other.isSame(moment(), 'day')) {
    return intl.formatMessage({id: 'shared.today'});
  }
  // for a future date
  return other.startOf('day').from(moment().startOf('day'));
}
