import React from 'react';
import {Stepper, Step, StepLabel, StepConnector} from '@material-ui/core';
import {withStyles, makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';

export default function WorkOrdersTopIndicator({steps, activeStep}) {
  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      connector={<ColoredConnector />}
      style={{width: '100%'}}>
      {steps.map(label => (
        <Step key={label}>
          <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const {active, completed} = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })}>
      {String(props.icon)}
    </div>
  );
}

const ColoredConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)'
  },
  active: {
    '& $line': {
      borderColor: '#289672'
    }
  },
  completed: {
    '& $line': {
      borderColor: '#289672'
    }
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1
  }
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 30,
    height: 30,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  active: {
    backgroundColor: '#17a2b8',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
  },
  completed: {
    backgroundColor: '#289672'
  }
});
