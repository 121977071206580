import {graphql} from 'react-apollo';
import {compose, withProps, withHandlers} from 'recompose';
import reduxForm from 'redux-form/lib/reduxForm';
import actions from 'redux-form/lib/actions';
import SubmissionError from 'redux-form/lib/SubmissionError';
import storeInsertMetadata from '../hoc/storeInsertMetadata';
import loadingSpinner from '../../shared/hoc/loadingSpinner';
import StoreInsertForm from '../components/StoreInsertForm.js';
import validateNewStore from '../utils/validateNewStore.js';
import {CREATE_STORE_MUTATION} from '../queries';
import {FORM_NAME as INSERT_TICKET_FORM} from './TicketInsertFormContainer';
import {injectIntl} from 'react-intl';

const FORM_NAME = 'StoreInsert';

export default compose(
  withHandlers({
    cancel: ({history}) => e => history.goBack()
  }),
  storeInsertMetadata,
  injectIntl,
  graphql(CREATE_STORE_MUTATION, {
    props: ({ownProps: {cancel, intl}, mutate}) => ({
      onSubmit(values, dispatch) {
        return mutate({
          variables: {
            store: values
          }
        }).then(
          ({data}) => {
            dispatch(
              actions.change(INSERT_TICKET_FORM, 'Account', {
                label: values.AccountName,
                value: data.createStore.id
              })
            );
            cancel();
          },
          r => {
            console.warn('Submission error', r);
            return Promise.reject(
              new SubmissionError({
                _error: intl.formatMessage({
                  id: 'tickets.there_was_an_error_saving_update'
                })
              })
            );
          }
        );
      }
    })
  }),
  withProps(({match}) => ({
    initialValues: {
      AccountName: match.params.name
    }
  })),
  loadingSpinner,
  reduxForm({
    form: FORM_NAME,
    validate: validateNewStore
  })
)(StoreInsertForm);
