// something to get the counts of open / action required tickets
import {gql, graphql} from 'react-apollo';

const TICKETS_COUNT_QUERY = gql`
  query {
    ticketsCount {
      ActionRequired
      Open
    }
  }
`;
export default graphql(TICKETS_COUNT_QUERY, {
  props({data}) {
    return {
      ticketsCount: data.ticketsCount || {}
    };
  }
});
