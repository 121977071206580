import React from 'react';
import PropTypes from 'prop-types';
import InputWrapper from './InputWrapper';

/**
 * <Select />
 * Wrapper for a dropdown (eventually to be changed into something
 * better looking)
 * This uses the native select control which allows for an optimal experience on mobile devices
 * (should we use react-select on desktops??)
 */
const Select = props => {
  const inputProps = props.input ? props.input : props;
  const value = props.valueLink ? props.valueLink.value : inputProps.value;
  var onChange = props.valueLink
    ? e => props.valueLink.requestChange(e.target.value)
    : inputProps.onChange;
  var hasSelection = false;
  var renderOptions = options => {
    return options.map(i => {
      const opt = typeof i === 'string' ? {label: i, value: i} : i;
      hasSelection = hasSelection || opt.value === value;
      return (
        <option value={opt.value} key={opt.value}>
          {opt.label}
        </option>
      );
    });
  };
  var renderGroup = (gname, items) => {
    return (
      <optgroup label={gname} key={gname}>
        {renderOptions(items)}
      </optgroup>
    );
  };
  var renderOptionGroups = () => {
    if (props.optionGroups) {
      return Object.keys(props.optionGroups).map(k =>
        renderGroup(k, props.optionGroups[k])
      );
    } else if (props.options) {
      return renderOptions(props.options);
    } else {
      throw new Error('Either options or optionGroups must be provided');
    }
  };
  var options = renderOptionGroups();
  // if no selection, add an empty option, unless we were already given one
  // This will only get selected if the value is really empty (i.e. not undefined)
  if (!value && !hasSelection) {
    options.unshift(
      <option key={-1} value="" disabled>
        {props.placeholder || ''}
      </option>
    );
  }

  // TODO: if they select a value and it causes an error we should show validation error
  // This should be handled in InputWrapper ideally, but how can we get it to listen to errors?
  return (
    <InputWrapper {...props}>
      <select
        className="form-control"
        value={value}
        onChange={onChange}
        disabled={props.disabled}>
        {options}
      </select>
    </InputWrapper>
  );
};
Select.propTypes = {
  // array of { label, value } objects
  options: PropTypes.array,
  // object whose properties are arrays of options
  optionGroups: PropTypes.object,
  // optional label for the control itself
  label: PropTypes.string,
  // change handler (this will be invoked with an event parameter)
  onChange: PropTypes.func,
  // help text
  help: PropTypes.string,
  // selected value
  value: PropTypes.string,
  // optional placeholder if they don't have a value selected
  placeholder: PropTypes.string,
  disabled: PropTypes.bool
};

export default Select;
