import React from 'react';
import PropTypes from 'prop-types';
import Infinite from 'react-infinite';
import styled from 'styled-components';

import Spinner from '../../shared/components/Spinner';

// <ListDetails />: Content of the ticket list
// we're decorated by graphql so we get data, and fetchMore
// we need a container that will abstract apollo
class ListDetails extends React.Component {
  render() {
    const {
      records,
      selectedId,
      totalCount,
      fieldMetadata,
      loading,
      loadMoreRows,
      height,
      itemHeight,
      ItemComponent,
      currentUser
    } = this.props;
    // console.log('render, height', height)
    // little hack to make sure that the infinite list does not display a spinner if the # of elements
    // is below the container height
    const hasAllElements =
      !loading &&
      (totalCount * itemHeight < height || records.length >= totalCount);

    // if you find that the list position is being reset when the user selects an item,
    // check to make sure that there is not a component that is getting re-mounted
    return (
      <InfiniteList
        elementHeight={itemHeight}
        containerHeight={height}
        infiniteLoadBeginEdgeOffset={hasAllElements ? undefined : 200}
        onInfiniteLoad={loadMoreRows}
        loadingSpinnerDelegate={<Spinner />}
        isInfiniteLoading={loading}
        className="list-group">
        {records.map(t => (
          <ItemComponent
            key={t.id}
            ticket={t}
            selected={t.id === selectedId}
            permissions={currentUser.permissions}
            fieldMetadata={fieldMetadata}
          />
        ))}
      </InfiniteList>
    );
  }
}

// function calcHeight() {
//   // can we make not hardcoded?
//   // TODO we need to handle window resize event
//   return (document.getElementById('root').offsetHeight || window.innerHeight) - 41
// }

const InfiniteList = styled(Infinite)`
  margin-bottom: 0;
  width: 100%;
  overflow-y: auto !important;
`;

ListDetails.propTypes = {
  records: PropTypes.array.isRequired,
  loadMoreRows: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  height: PropTypes.number.isRequired
};

export default ListDetails;

// vim ft=javascript.jsx
