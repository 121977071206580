import React from 'react';
import PropTypes from 'prop-types';
import Select, {Creatable} from 'react-select';
import {injectIntl} from 'react-intl';
import InputWrapper from './InputWrapper';
import Input from './Input';
import {picklistStyles} from './ReactSelect';
import debounce from '../../../utils/debounce';

// Lookup control (for looking up an external entity)
// This is the UI component only, it does not have the logic to load the options
class Lookup extends React.Component {
  constructor(props) {
    super(props);
    this.unmounted = false;
    this.promptTextCreator = this.promptTextCreator.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    // this is probably fine with a big debounce, because in most of the case we'll have all the values from the get-go anyway
    this.onInputChange = debounce(this.onInputChange.bind(this), 500);
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  promptTextCreator(label) {
    return this.props.intl.formatMessage(
      {id: 'lookup.create'},
      {type: this.props.label, label: label}
    );
  }

  onInputChange(inputValue) {
    if (this.props.onSearch && !this.unmounted) this.props.onSearch(inputValue);
    return inputValue;
  }

  onChange(option) {
    // intercept changes to "New" to launch the Create Action
    if (option && option.__isNew__ && this.props.onCreate) {
      this.props.onCreate({label: option.label}, this.getFormOptions());
    } else {
      if (this.props.input && this.props.input.onChange)
        this.props.input.onChange(option);
    }
  }

  // hack to get around the fact that react-select does not pass value on blur
  // https://github.com/erikras/redux-form/issues/82
  // https://github.com/JedWatson/react-select/issues/805
  onBlur(e) {
    if (this.props.input.onBlur) {
      setTimeout(() => {
        this.props.input.onBlur(this.props.input.value);
      }, 1);
    }
  }

  getFormOptions() {
    if (this.context._reduxForm) {
      return {
        form: this.context._reduxForm.form,
        field: this.props.input.name
      };
    }
    return null;
  }

  render() {
    const props = this.props;
    const Component = props.onCreate ? Creatable : Select;
    if (props.readOnly) {
      return (
        <Input
          readOnly={true}
          label={props.label}
          help={props.help}
          input={{value: props.input.value ? props.input.value.Name : ''}}
        />
      );
    }
    return (
      <InputWrapper {...props}>
        <Component
          clearable={props.clearable !== false}
          options={props.options}
          onInputChange={this.onInputChange}
          ignoreCase={true}
          cache={null}
          disabled={props.disabled}
          formatCreateLabel={this.promptTextCreator}
          isOptionUnique={isOptionUnique}
          value={props.input.value}
          onChange={this.onChange}
          onBlur={this.onBlur}
          onFocus={props.input.onFocus}
          styles={picklistStyles}
        />
      </InputWrapper>
    );
  }
}

// hard code the unique option check to true, so that there is always an option to create a new account
const isOptionUnique = () => true;

Lookup.propTypes = {
  // array of {label, value} objects
  options: PropTypes.array.isRequired,
  // when search string is edited
  onSearch: PropTypes.func.isRequired,
  // are we loading the options (we have to be a little careful with this one, because it may be
  // set repeatedly when doing searches while typing some text, so we don't want to cause it to re-render the lookup)
  loadingOptions: PropTypes.bool.isRequired,
  // invoked to create a new record
  // This will be passed the user input
  // once the record has been created it needs to be set as value on the subject of the form
  // so that the lookup binds itself to it
  // If it is not specified the lookup will not allow creates
  onCreate: PropTypes.func,
  // pass false to avoid showing the clear button
  clearable: PropTypes.bool,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired
    // pass label / value
    // value: PropTypes.object
  }).isRequired,
  intl: PropTypes.object.isRequired
};
// this is a bit of a hack, but it will enable us to dispatch the create action using the proper field path
Lookup.contextTypes = {
  _reduxForm: PropTypes.object
};
export default injectIntl(Lookup);
