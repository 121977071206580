import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import map from 'lodash/map';
import AttachmentUploadDialog from '../components/AttachmentUploadDialog';
import Attachments from '../utils/Attachments';
// import styled from 'styled-components'

// add handlers for the attachment upload:
//  - showUploader - function that will be passed to the wrapped component to show the file picker
//            - must be called from a "trusted" browser event
//  - onSubmitAttachments - function that must be passed as a prop and will be invoked once they have selected the files and confirmed the description etc.
//            - this will be invoked with an array of elements with (FileName, Description)
//            - it must return a promise that will resolve when the save is complete
const attachmentUpload = WrappedComponent => {
  class AttachmentUpload extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        // current progress of upload
        uploadProgress: 0,
        // if there is an error, we'll display it in the modal
        uploadError: '',
        // when they have selected some files, we'll show the edit form for them
        selectedFiles: null,
        // when the form is being submitted (this happens AFTER the attachments have been uploaded)
        submitting: false
      };
    }

    makePickerRef = ref => {
      if (ref) this.pickerRef = ref;
    };

    // handler for the file picker change event
    // when they select the file, we need to start the upload process, and show the dialog so they can edit the description / types
    onFilesSelected = e => {
      if (e.target.files && e.target.files.length) {
        for (let f of e.target.files) {
          const extension = f.name.replace(/.*\./, '').toUpperCase();
          if (e.target.accept.indexOf(extension) === -1) {
            this.setState({
              uploadError:
                this.props.intl.formatMessage({
                  id: 'tickets.attachment_invalid_type'
                }) + f.name
            });
            return;
          }
        }
        this.setState({
          uploadProgress: 0,
          uploadError: '',
          selectedFiles: map(e.target.files, f => ({
            Description: f.name,
            FileName: '',
            FileSize: f.size
          }))
        });
        Attachments.uploadFiles(
          e.target.files,
          uploadProgress => this.setState({uploadProgress}),
          this.props.intl
        ).then(
          response => {
            if (this.state.selectedFiles) {
              this.setState({
                uploadProgress: 100,
                // update the FileName property in each file
                // (they will be returned in the same order)
                selectedFiles: update(
                  this.state.selectedFiles,
                  response.reduce(
                    (acc, f, i) => ({
                      ...acc,
                      [i]: {FileName: {$set: f.FileName}}
                    }),
                    {}
                  )
                )
              });
            }
          },
          error =>
            this.setState({
              uploadError:
                error.message ||
                this.props.intl.formatMessage({
                  id: 'tickets.attachment_upload_error'
                })
            })
        );
      }
    };

    // call the onSubmit prop, to let the parent component handle the save
    // (either save them in the redux form, or upload them as attachment records)
    // this only happens once the files have been uploaded
    onSave = () => {
      console.assert(this.isValid(), 'Invalid state'); // do not translate
      this.setState({
        submitting: true
      });
      this.props.onSubmitAttachments(this.state.selectedFiles).then(
        () => {
          this.setState({
            submitting: false,
            // close dialog
            selectedFiles: null
          });
        },
        err => {
          console.warn('Error in upload', err);
          this.setState({
            uploadError:
              err.message ||
              this.props.intl.formatMessage({
                id: 'tickets.attachment_upload_error'
              }),
            submitting: false
          });
        }
      );
    };

    onCancel = () => {
      // close dialog
      this.setState({
        selectedFiles: null,
        uploadError: '',
        submitting: false
      });
    };

    updateFileDescription = (i, description) => {
      this.setState({
        selectedFiles: update(this.state.selectedFiles, {
          [i]: {
            Description: {$set: description}
          }
        })
      });
    };

    isValid = () =>
      this.state.uploadProgress === 100 && !this.state.uploadError;

    // to be invoked by the wrapped component when the upload button is clicked
    // (ideally we should also wrap the component with a dropzone type thing so users could drop files into it)
    showUploader = () => {
      this.pickerRef.value = null;
      this.pickerRef.click();
    };

    // accept='.csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/plain, image#<{(|, text/html, video#<{(|, .pdf, .doc, .docx, audio#<{(|'
    render() {
      return (
        <React.Fragment>
          <input
            className="hidden"
            type="file"
            ref={this.makePickerRef}
            onChange={this.onFilesSelected}
            accept=".JPG,.JPEG,.GIF,.MOV,.PNG,.BMP,.MPG,.PDF,.DOC, .DOCX, .XLS, .XLSX,.CSV, .HTM, .HTML, .PDF"
            multiple
          />
          {this.state.selectedFiles && (
            <AttachmentUploadDialog
              onSave={this.onSave}
              updateFileDescription={this.updateFileDescription}
              onCancel={this.onCancel}
              files={this.state.selectedFiles}
              progress={this.state.uploadProgress || 0}
              error={this.state.uploadError}
              submitting={this.state.submitting}
              isValid={this.isValid()}
            />
          )}
          <WrappedComponent {...this.props} showUploader={this.showUploader} />
        </React.Fragment>
      );
    }
  }
  AttachmentUpload.propTypes = {
    onSubmitAttachments: PropTypes.func.isRequired
  };
  return AttachmentUpload;
};

export default attachmentUpload;
