import styled from 'styled-components';
import CollapsibleSection from './CollapsibleSection';

export default styled(CollapsibleSection)`
  .panel-body {
    padding: 0;
    max-height: 300px;
    overflow: auto;

    & > p {
      padding: 15px;
    }

    & > .list-group,
    & > table {
      margin: 0;
    }
  }
`;
