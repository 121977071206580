import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from './ReactSelect';

// A picklist filter implemented as a list of checkboxes
const TrueFalseFilter = ({className, filterValue, setFilterValue}) => {
  const options = [{label: 'Yes', value: true}, {label: 'No', value: false}];
  const onChange = v => {
    if (v) {
      setFilterValue({is: v.value});
    } else {
      setFilterValue({});
    }
  };

  return (
    <ReactSelect
      value={filterValue.is}
      options={options}
      onChange={onChange}
      className={className}
    />
  );
};

TrueFalseFilter.propTypes = {
  // the currently selected values (can be null)
  filterValue: PropTypes.object.isRequired,
  setFilterValue: PropTypes.func.isRequired
};

//noinspection JSUnusedGlobalSymbols
export default TrueFalseFilter;
