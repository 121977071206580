import React from 'react';
import Dialog from '../../shared/components/Dialog';
import Spinner from '../../shared/components/Spinner';
import Alert from '../../shared/components/Alert';
import {injectIntl} from 'react-intl';

const ResetPassword = ({
  cancel,
  resetPassword,
  loading,
  password,
  setPassword,
  passwordConfirmation,
  setPasswordConfirmation,
  message,
  error,
  tokenVerified,
  intl
}) => (
  <Dialog
    onConfirm={resetPassword}
    onDismiss={cancel}
    enableConfirm={!loading && !!password && !!passwordConfirmation && !message}
    size="small"
    confirmLabel="Submit"
    title={intl.formatMessage({id: 'pages.reset_password_title'})}>
    {tokenVerified && (
      <div>
        <input
          className="form-control"
          placeholder="New Password"
          type="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <br />
        <input
          className="form-control"
          placeholder="Confirm Password"
          type="password"
          value={passwordConfirmation}
          onChange={e => setPasswordConfirmation(e.target.value)}
        />
      </div>
    )}
    <Alert error={error} success={message} />
    {loading && <Spinner />}
  </Dialog>
);

export default injectIntl(ResetPassword);
