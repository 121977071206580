import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

import {Route} from 'react-router-dom';

import ApplicationPage from '../../shared/pages/ApplicationPage';
import TicketListContainer from '../containers/TicketListContainer';
import TicketInsert from './TicketInsert';

const useStyles = makeStyles(theme => ({
  listContainer: {
    flexGrow: 1
  },
  inner: {
    display: 'flex',
    flexWrap: 'wrap',
    flexGrow: 1
  }
}));

const VIEWS = {
  'action-required': [
    {
      field: 'SubStatus',
      // TODO: localize...   How can we get this stuff from the metadata?
      value: 'Action Required'
    }
  ],
  open: [
    {
      field: 'Status',
      value: 'Open Work Orders'
    }
  ]
};
const FullTicketList = props => {
  const classes = useStyles();
  // const renderWithProps = C => ({history, match}) => (
  //   <C history={history} match={match} currentUser={props.currentUser} />
  // );
  const {
    currentUser: {permissions},
    match: {
      params: {view}
    }
  } = props;

  // the URL does not change if they edit the filter, so if they reload the page it will reset to the default
  // for the view.  But that may not be a bad thing.
  return (
    <ApplicationPage {...props}>
      <div className={classes.inner}>
        <TicketListContainer
          className={classes.listContainer}
          querySelectedFields={true}
          currentUser={props.currentUser}
          routeFilter={VIEWS[view]}
        />
      </div>
    </ApplicationPage>
  );
};
export default FullTicketList;
