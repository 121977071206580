import {graphql, compose} from 'react-apollo';
import {gql} from 'react-apollo';

import loadingSpinner from '../../shared/hoc/loadingSpinner';
import errorIfNotFound from '../../shared/hoc/errorIfNotFound';

const QUERY = gql`
  query {
    getDirectors {
      DisplayName
      Email
      Phone
      Mobile
      Title
      ProfilePicture
    }
  }
`;

const container = compose(
  graphql(QUERY, {
    props: ({data}) => ({
      loading: data.loading,
      error: data.error,
      contacts: data.getDirectors
    })
  }),
  loadingSpinner,
  errorIfNotFound('contacts')
);

export default container;
