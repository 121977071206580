import React from 'react';
import Field from 'redux-form/lib/Field';
import styled from 'styled-components';
import Spinner from '../../shared/components/Spinner';
import Alert from '../../shared/components/Alert';
import Select from '../../shared/components/inputs/Select';
import Input from '../../shared/components/inputs/Input';
import Toggle from '../../shared/components/inputs/Toggle';
import ClearButton from '../../shared/components/ClearButton';
import Glyphicon from '../../shared/components/Glyphicon';
import savedViewContainer from '../containers/SavedViewContainer';
import {ModalPanel, Overlay} from '../../shared/components/styles';
import {FormattedMessage} from 'react-intl';

// a way for users to persist a view (column / filter / sort selection)
const SavedView = ({
  savedViews,
  useView,
  setSelectedView,
  handleSubmit,
  error,
  submitting,
  isSavePanelOpen,
  selectedView,
  deleteView,
  deleteError,
  deleting,
  isLoadPanelOpen,
  closePanel,
  openSavePanel,
  openLoadPanel,
  intl
}) => (
  <StyleContainer>
    <div>
      <ClearButton onClick={openSavePanel}>
        <Glyphicon glyph="floppy-disk" />
        &nbsp; <FormattedMessage id="tickets.saved_view_floppy" />
      </ClearButton>
      <ClearButton onClick={openLoadPanel}>
        <Glyphicon glyph="bookmark" />
        &nbsp; <FormattedMessage id="tickets.saved_view_bookmark" />
      </ClearButton>
    </div>
    <ModalPanel show={isSavePanelOpen}>
      <form className="form-horizontal">
        {submitting && (
          <Spinner
            loadingText={intl.formatMessage({
              id: 'tickets.saved_view_submitting'
            })}
          />
        )}
        <Field
          component={Input}
          label={intl.formatMessage({id: 'tickets.saved_view_horizontal'})}
          horizontal
          name="name"
        />
        <Field
          component={Toggle}
          label={intl.formatMessage({
            id: 'tickets.saved_view_modal_include_filters'
          })}
          horizontal
          name="includeFilters"
        />
        <Alert error={error} />
        <div>
          <ClearButton onClick={handleSubmit}>
            <Glyphicon glyph="floppy-disk" />
            &nbsp;<FormattedMessage id="tickets.save" />
          </ClearButton>
          <ClearButton onClick={closePanel}>
            <Glyphicon glyph="remove" />
            &nbsp;<FormattedMessage id="tickets.saved_view_cancel" />
          </ClearButton>
        </div>
      </form>
    </ModalPanel>
    <ModalPanel show={isLoadPanelOpen}>
      <form className="form-horizontal">
        {deleting && (
          <Spinner
            loadingText={intl.formatMessage({
              id: 'tickets.saved_view_deleting'
            })}
          />
        )}
        <Select
          label={intl.formatMessage({id: 'tickets.saved_view_saved'})}
          horizontal
          value={selectedView}
          options={savedViews.map(v => ({label: v.name, value: v.name}))}
          onChange={setSelectedView}
        />
        <Alert error={deleteError} />
        <div>
          {selectedView && (
            <ClearButton onClick={useView}>
              <Glyphicon glyph="list" />
              &nbsp;<FormattedMessage id="tickets.saved_view_list" />
            </ClearButton>
          )}
          {selectedView && (
            <ClearButton onClick={deleteView}>
              <Glyphicon glyph="trash" />
              &nbsp;<FormattedMessage id="tickets.saved_view_delete" />
            </ClearButton>
          )}
          <ClearButton onClick={closePanel}>
            <Glyphicon glyph="remove" />
            &nbsp;<FormattedMessage id="tickets.saved_view_close" />
          </ClearButton>
        </div>
      </form>
    </ModalPanel>
    <Overlay show={isLoadPanelOpen || isSavePanelOpen} />
  </StyleContainer>
);

const StyleContainer = styled.div`
  text-align: right;

  form {
    background: white;
    padding: 10px;
    border-radius: 4px;
  }
`;

export default savedViewContainer(SavedView);
