import {handleActions} from 'redux-actions';
import {AUTH_SIGNIN, AUTH_SIGNOUT, TOGGLE_MENU} from '../actions/types';
import {ROOT_CLICK} from '../../shared/actions/types';

const defaultState = {
  authenticated: false,
  isMenuExtended: false,
  signOutReason: ''
};

export default handleActions(
  {
    [AUTH_SIGNIN]: () => ({
      ...defaultState,
      authenticated: true
    }),
    [AUTH_SIGNOUT]: (state, {payload}) => ({
      ...defaultState,
      authenticated: false,
      signOutReason: payload
    }),
    [TOGGLE_MENU]: state => ({
      ...state,
      isMenuExtended: !state.isMenuExtended
    }),
    [ROOT_CLICK]: state => ({
      ...state,
      isMenuExtended: false
    })
  },
  defaultState
);
