import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Field from 'redux-form/lib/Field';
import { FormattedMessage } from 'react-intl';
// import {Email as SendIcon} from '@material-ui/icons';
import SendIcon from 'react-entypo-icons/lib/entypo/PaperPlane';

import Checkbox from '../../shared/components/inputs/Checkbox';
import ValidationHelp from '../../shared/components/inputs/ValidationHelp';
import PrimaryButton from '../../shared/components/PrimaryButton';

const TicketActivityInput = ({
  classes,
  intl,
  currentValue,
  quoteApproval,
  needApproval,
  handleSubmit,
  submitting,
  error,
  valid
}) => {
  const onKeyDown = e => {
    if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
      handleSubmit();
    }
  };
  return (
    <div
      className={classNames(classes.container, {
        'has-error': !!error
      })}>
      <div className={classes.commentsHeader}>
        <h2>Comments{needApproval}</h2>
        <div className={classes.line} />
      </div>
      {error && <ValidationHelp text={error} />}
      <div className={classes.inputContainer}>
        <Field
          component="textarea"
          className="form-control"
          placeholder={intl.formatMessage({ id: 'tickets.activity_insert_dialog_title' })}
          name="ActivityDescription"
          readOnly={submitting || quoteApproval}
          onKeyDown={onKeyDown}
        />
        <div className={classNames(classes.sizer, 'form-control')}>
          {currentValue}&nbsp;
        </div>
      </div>
      {needApproval && <div className={classes.inputContainer}>
        <Field
          component={Checkbox}
          name="QuoteApproval"
          readOnly={submitting}
          label={intl.formatMessage({ id: 'tickets.approve_estimate' })}
        />
      </div>}
      <PrimaryButton
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={submitting || !valid}
        className={classes.button}>
        {/*<FormattedMessage id="tickets.save" />*/}
        <SendIcon />
      </PrimaryButton>
    </div>
  );
};
TicketActivityInput.propTypes = {};

export default withStyles({
  container: {
    textAlign: 'right',
    marginTop: 20,
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  inputContainer: {
    // TODO
    position: 'relative',
    width: '100%',
    '& > textarea': {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0
    }
  },
  sizer: {
    whiteSpace: 'pre-wrap',
    visibility: 'hidden',
    padding: '20px 10px',
    height: 'auto'
  },
  button: {
    marginTop: 10
  },
  commentsHeader: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    marginBottom: 20
  },
  line: {
    backgroundColor: '#acacac',
    height: 1,
    width: '100%'
  }
})(TicketActivityInput);
