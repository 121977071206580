import React from 'react';
import {Route} from 'react-router-dom';
import {Paper} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import moment from 'moment';
import Hero from '../../shared/components/Hero';
import {FormattedMessage} from 'react-intl';

import ApplicationPage from '../../shared/pages/ApplicationPage';
import TicketInsertFormContainer from '../containers/TicketInsertFormContainer';
import StoreInsertContainer from '../containers/StoreInsertContainer';

const TicketInsert = props => {
  const classes = useStyles();
  return (
    <ApplicationPage currentUser={props.currentUser}>
      <Hero />
      <h1>
        <FormattedMessage id="tickets.insert_forms_title" />
      </h1>
      <div className={classes.divider} />
      <TicketInsertFormContainer {...props} />
      <Route
        path={`${props.match.url}/new-store/:name`}
        component={StoreInsertContainer}
      />
    </ApplicationPage>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    width: '90%',
    padding: 30
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 20
  },
  line: {
    width: 5,
    height: 28,
    backgroundColor: '#e4002b',
    borderRadius: 1,
    marginRight: 10
  },
  divider: {
    backgroundColor: '#acacac',
    height: 1,
    width: '90%',
    marginTop: 15,
    marginBottom: 35
  }
}));

export default TicketInsert;
