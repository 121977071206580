import {graphql} from 'react-apollo';
import update from 'immutability-helper';
import {compose, withHandlers} from 'recompose';
import {injectIntl} from 'react-intl';
import TicketAttachmentSection from '../components/TicketAttachmentSection';
import attachmentUpload from '../hoc/attachmentUpload';
import Attachments from '../utils/Attachments';
import loadingSpinner from '../../shared/hoc/loadingSpinner';
import {
  CREATE_TICKETATTACHMENTS_MUTATION,
  TICKET_DETAIL_QUERY
} from '../queries';

// container for the ticket attachment section
export default compose(
  injectIntl,
  graphql(CREATE_TICKETATTACHMENTS_MUTATION, {
    props({ownProps: {intl, ticketId}, mutate}) {
      return {
        onSubmitAttachments(vals) {
          return mutate({
            variables: {
              attachments: {
                attachments: vals,
                ticketId
              }
            },
            update(store, {data}) {
              const q = {
                query: TICKET_DETAIL_QUERY,
                variables: {
                  ticketId
                }
              };
              const storeData = store.readQuery(q);
              const updated = update(storeData, {
                ticket: {
                  Attachments: {
                    $unshift: data.createTicketAttachments
                  }
                }
              });
              // console.log('data received', data)
              // console.log('updated', updated)
              store.writeQuery({
                ...q,
                data: updated
              });
            }
          }).catch(r => {
            console.warn('Submission error', r);
            let message;
            if (r.message) {
              message = r.message.replace(/^GraphQL error: /, '');
            } else {
              message = intl.formatMessage(
                {
                  id: 'tickets.error_saving_attachment'
                },
                {count: vals.length}
              );
            }
            return Promise.reject(new Error(message));
          });
        }
      };
    }
  }),
  withHandlers({
    downloadFile: ({setError, setLoading}) => attachId => {
      // needs to be synchronous, or we'll get a popup blocker
      window.open(Attachments.getDownloadURL(attachId));
      // setLoading(true)
      // setError('')
      // Attachments.getDownloadURL(attachId).then(url => {
      //   window.location.href = url
      // }, err => {
      //   console.warn(err)
      //   setError(err.message || err.toString())
      // }).then(() => setLoading(false))
    }
  }),
  attachmentUpload,
  loadingSpinner
)(TicketAttachmentSection);
