import React from 'react';
import PropTypes from 'prop-types';

import InsertFieldsContainer from '../containers/InsertFieldsContainer';
import Dialog from '../../shared/components/Dialog';
import Spinner from '../../shared/components/Spinner';
import Alert from '../../shared/components/Alert';
import {FormattedMessage, injectIntl} from 'react-intl';
import Banners from '../../shared/components/Banners';

// Ticket creation page
const TicketInsertForm = ({
  createdTicket,
  showCancel,
  hideCancel,
  confirmCancel,
  goToTicket,
  cancel,
  error,
  submitting,
  intl,
  ...otherProps
}) => (
  <div
    title={intl.formatMessage({id: 'tickets.insert_forms_title'})}
    className="new-ticket">
    {submitting && <Spinner loadingText="Saving Work Order..." />}
    <Banners
      banners={otherProps.currentUser.ParentAccount.BannerConfig}
      where={x => x.IsNewWorkOrderScreen && !x.IsBottom}
      type="error"
    />
    <InsertFieldsContainer {...otherProps} />
    <Banners
      banners={otherProps.currentUser.ParentAccount.BannerConfig}
      where={x => x.IsNewWorkOrderScreen && x.IsBottom}
      type="error"
    />

    <Alert error={error} />
    {showCancel && (
      <Dialog
        className="new-ticket-cancel"
        title={intl.formatMessage({id: 'tickets.insert_forms_cancel'})}
        size="small"
        confirmLabel={intl.formatMessage({
          id: 'tickets.insert_forms_confirm_cancel'
        })}
        onDismiss={hideCancel}
        onConfirm={cancel}>
        {intl.formatMessage({id: 'tickets.insert_forms_on_confirm_cancel'})}
      </Dialog>
    )}
    {createdTicket && (
      <Dialog
        className="new-ticket-confirm"
        title={intl.formatMessage({id: 'tickets.insert_forms_saved'})}
        size="small"
        onConfirm={cancel}
        showCancel={false}
        extraButton={{
          label: intl.formatMessage({
            id: 'tickets.insert_forms_go_to_ticket'
          }),
          icon: 'eye-open',
          onClick: goToTicket
        }}
        confirmIcon="home"
        confirmLabel={intl.formatMessage({
          id: 'tickets.insert_forms_saved_close'
        })}>
        <FormattedMessage
          id="tickets.insert_forms_saved_message"
          values={{ticketnumber: createdTicket.TicketNumber}}
        />
      </Dialog>
    )}
  </div>
);

TicketInsertForm.propTypes = {
  // show the confirmation dialog for cancel
  confirmCancel: PropTypes.func.isRequired,
  hideCancel: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired
};

export default injectIntl(TicketInsertForm);
