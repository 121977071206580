import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ValidationHelp from './ValidationHelp';

/**
 * <InputWrapper/>
 * Wrapper for an input control including label and validation state.
 */
const InputWrapper = props => {
  const meta = props.meta || props;
  const error = meta.error;
  const classes = useStyles();
  let parentCss = classNames(classes.container, props.className);
  let validationHelp = null;
  if (
    error &&
    !props.readOnly &&
    (!meta || meta.touched || meta.submitFailed)
  ) {
    parentCss += ' has-error';
    validationHelp = <ValidationHelp text={error} />;
  } else if (error) {
    // a smaller one until they try and actually submit it
    validationHelp = <ValidationHelp text="*" />;
  }
  const label = props.label ? (
    <label className={classes.label}>
      {props.label}&nbsp;
      {validationHelp}
      {props.help && (
        <i
          data-toggle="tooltip"
          className="fa fa-question-circle"
          title={props.help}
        />
      )}
    </label>
  ) : null;

  // TODO figure out how to set label "for" attribute
  return (
    <div className={parentCss}>
      {label}
      {props.children}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: '10px 0px 10px',
    flex: '0 0 50%;',
    [theme.breakpoints.down('md')]: {
      flex: 1
    },
    fontSize: '1.8rem'
  },
  label: {
    fontFamily: 'Nunito',
    fontSize: '1.8rem',
    lineHeight: 1.41,
    color: '#686b6b'
  }
}));

InputWrapper.propTypes = {
  // optional label for the control itself
  label: PropTypes.string,
  // help text
  help: PropTypes.string
};

export default InputWrapper;
