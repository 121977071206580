import React from 'react';
import {branch, lifecycle} from 'recompose';
import {Redirect, withRouter} from 'react-router-dom';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {withApollo, graphql} from 'react-apollo';

import {signOut, signIn} from '../actions';
import Authentication from '../utils/Authentication';
import loadingSpinner from '../../shared/hoc/loadingSpinner';
import {CURRENT_USER_QUERY} from '../queries';

const redirectToLogin = () => props => (
  <Redirect
    to={{
      pathname: '/login',
      state: {from: props.location}
    }}
  />
);

// for an authenticated route
// this will redirect users who are not logged in to the signin page, relying on local state to
// determine that.
export default compose(
  // if they don't have a current user (meaning there is no JWT cookie, or it has expired),
  // don't bother with running the rest of the stuff (otherwise they'll run a graphql query,
  // but since they don't have a cookie they'll get a 401 anyway)
  branch(() => !Authentication.getCurrentUser(), redirectToLogin),
  connect(
    state => ({
      authenticated: state.auth.authenticated
    }),
    {signOut, signIn}
  ),
  // need those 2 for the sign out
  withRouter,
  withApollo,
  graphql(CURRENT_USER_QUERY, {
    props: ({data}) => ({
      loading: data.loading,
      // don't pass the error.  Otherwise, if they are not logged in, they'll get an error instead of being redirected...
      error: null,
      currentUser: data.currentUser
    })
  }),
  loadingSpinner,
  // redirect if the currentUser query failed, likely their cookie is not valid
  // ideally we should have caught it in the first branch, but this will address the case of their user being
  // invalidated server side
  branch(({currentUser}) => !currentUser, redirectToLogin),
  lifecycle({
    componentDidMount() {
      // make sure their cookie is still valid, every minute
      // ideally we could have a warning so they can extend the session
      this.timer = setInterval(() => {
        // how can we accomplish this without looking at the cookie (if it was httpOnly)?
        if (Authentication.getCurrentUser() == null) {
          this.props.signOut(
            this.props.history,
            this.props.client,
            'Session timed out'
          );
        }
      }, 60 * 1000);
      if (!this.props.authenticated) {
        // make sure the store reflects the authenticated state
        this.props.signIn();
      }
    },

    componentWillUnmount() {
      if (this.timer) {
        clearInterval(this.timer);
      }
    }
  })
);
