import React from 'react';
import {addLocaleData, IntlProvider} from 'react-intl';
import en from 'react-intl/locale-data/en';
import fr from 'react-intl/locale-data/fr';
import * as moment from 'moment';
import 'moment/locale/fr';
import frMessages from './fr.json';
import enMessages from './en.json';
import Authentication from '../auth/utils/Authentication';

addLocaleData([...en, ...fr]);
const messages = {
  en: enMessages,
  fr: frMessages
};

const user = Authentication.getCurrentUser();
const queryStringLang = /[&?]locale=(\w+-\w+)/.exec(window.location.search);
const locales = [
  user && user.locale, // configured in user cookie?
  queryStringLang && queryStringLang[1], // passed in query string?
  navigator.language // default to the browser config
];
const locale = locales.find(x => x) || 'en-US';
const language = locale.split(/[-_]/)[0];
moment.locale(locale);

export default ({children}) => (
  <IntlProvider locale={locale} messages={messages[language] || enMessages}>
    {children}
  </IntlProvider>
);
