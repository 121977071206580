import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {injectIntl} from 'react-intl';
import {LabelInForm} from './styles';
import HelpButton from './HelpButton';
import formatDate from '../../utils/formatDate';
import formatTime from '../../utils/formatTime';

// read-only output used in detail form
// TODO: formatting and stuff
const OutputField = ({record, meta, field, help, intl}) => {
  if (!meta[field]) {
    // hidden field?
    if (process.env.NODE_ENV === 'development')
      console.log('Missing metadata for ' + field);
    return null;
  }
  const val = get(record, field);
  if (!val) return null;
  return (
    <div>
      <LabelInForm>{meta[field].label}</LabelInForm>
      <span>{formatValue(val, meta[field].type, intl)}</span>
      {help && <HelpButton content={help} tooltipPlacement="bottom" />}
    </div>
  );
};

const formatValue = (value, type, intl) => {
  if (type === 'Boolean') {
    return value
      ? intl.formatMessage({id: 'Yes'})
      : intl.formatMessage({id: 'No'});
  }
  if (type === 'Date') {
    return formatDate(value);
  }
  if (type === 'DateTime') {
    return formatTime(value);
  }
  return value;
  //
  // switch(type) {
  //   case 'DateTime':
  //     return moment(value).format('YYYY-MM-DD')
  //   case 'Phone':
  //
  //   default:
  //     return value
  // }
};

OutputField.propTypes = {
  record: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired
};

export default injectIntl(OutputField);
