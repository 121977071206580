import {handleActions} from 'redux-actions';
import {TOGGLE_ACCOUNT_SWITCHER, TOGGLE_MENU} from '../actions/types';

const defaultState = {
  // on mobile, show collapsed by default
  isMenuExtended: window.innerWidth > 1023,
  isAccountSwitcherOpen: false
};

export default handleActions(
  {
    [TOGGLE_MENU]: state => ({
      ...state,
      isMenuExtended: !state.isMenuExtended
    }),
    [TOGGLE_ACCOUNT_SWITCHER]: state => ({
      ...state,
      isAccountSwitcherOpen: !state.isAccountSwitcherOpen
    })
  },
  defaultState
);
