import React from 'react';
import PropTypes from 'prop-types';
import * as DOMPurify from 'dompurify';

/**
 * <Alert />
 * Wrapper for Bootstrap alert box
 */
class Alert extends React.Component {
  render() {
    var className, message;

    if (this.props.error) {
      className = 'alert-danger';
      message = this.props.error;
    } else if (this.props.warning) {
      className = 'alert-warning';
      message = this.props.warning;
    } else if (this.props.success) {
      className = 'alert-success';
      message = this.props.success;
    } else {
      return null;
    }
    var closeButton = null;
    if (this.props.dismiss) {
      closeButton = (
        <button
          type="button"
          className="close"
          onClick={this.props.dismiss}
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      );
    }
    const html = DOMPurify.sanitize(message, {
      ALLOWED_TAGS: ['a', 'img'],
      ALLOWED_ATTR: ['href', 'target', 'title', 'src', 'alt'],
    });
    return (
      <div className={className + ' alert'}>
        {closeButton}
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    );
  }
}
Alert.propTypes = {
  error: PropTypes.string,
  success: PropTypes.string,
  dismiss: PropTypes.func
};
export default Alert;
