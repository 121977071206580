import {createAction} from 'redux-actions';
import {ROOT_CLICK, TOGGLE_MENU, TOGGLE_ACCOUNT_SWITCHER} from './types';

// used to detect clicks outside of menus / modals
export const rootClick = createAction(ROOT_CLICK);
export const toggleMenu = createAction(TOGGLE_MENU);
export const toggleAccountSwitcher = e => {
  e.stopPropagation();
  return {
    type: TOGGLE_ACCOUNT_SWITCHER
  };
};
