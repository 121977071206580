import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {makeStyles} from '@material-ui/core/styles';
import {HFormField} from '../../shared/components/inputs/FormField';
import FieldArray from 'redux-form/lib/FieldArray';
import GraphqlLookup from '../../shared/components/inputs/GraphqlLookup';
import Checkbox from '../../shared/components/inputs/Checkbox';
import Input from '../../shared/components/inputs/Input';
import Textarea from '../../shared/components/inputs/Textarea';
import Picklist from '../../shared/components/inputs/Picklist';
import AttachmentFieldArray from './AttachmentFieldArray';

/*
 * Fields for the Create Ticket screen.
 * Remember that those are not automatically mapped to Ticket,
 * they need to either be specified in fieldMap, or included explicitly in models/tickets.js
 */

const InsertFields = ({fieldMetadata, storeFieldMetadata, showUploader}) => {
  // const classes = useStyles();
  return (
    <Container className={'form-horizontal'}>
      <HFormField
        name="DescriptionOfWorkInput"
        component={Textarea}
        fieldMetadata={fieldMetadata}
        className="description"
      />
      <FieldArray component={AttachmentFieldArray} name="Attachments" />
      <HFormField
        name="Urgency"
        component={Picklist}
        fieldMetadata={fieldMetadata}
        className="urgency"
      />

      <HFormField
        name="DateRequested"
        component={Input}
        readOnly={true}
        fieldMetadata={fieldMetadata}
      />
      <HFormField
        name="ParentContact"
        component={GraphqlLookup}
        fieldMetadata={fieldMetadata}
        lookupType="ParentContact"
        label="Main Contact"
      />
      {/*
      <StoreInfo
        store={storeInformation}
        loading={loadingStoreInformation}
        fieldMetadata={storeFieldMetadata}
      />
      */}
      <HFormField
        name="PoNumber"
        component={Input}
        maxLength={32}
        fieldMetadata={fieldMetadata}
        className="ponumber"
      />
      {fieldMetadata.CintasPartnerHeader && (
        <h6>{fieldMetadata.CintasPartnerHeader.label}</h6>
      )}
      <HFormField
        name="CintasPartnerName"
        component={Input}
        maxLength={80}
        fieldMetadata={fieldMetadata}
      />
      <HFormField
        name="CintasPartnerEmail"
        component={Input}
        maxLength={50}
        fieldMetadata={fieldMetadata}
      />
      <HFormField
        name="CintasPartnerPhone"
        component={Input}
        maxLength={32}
        fieldMetadata={fieldMetadata}
      />
      <HFormField
        name="CintasPartnerMobile"
        component={Input}
        maxLength={32}
        fieldMetadata={fieldMetadata}
      />
      {fieldMetadata.CintasBusinessHeader && (
        <h6>{fieldMetadata.CintasBusinessHeader.label}</h6>
      )}
      <HFormField
        name="CintasBusinessName"
        component={Input}
        maxLength={80}
        fieldMetadata={fieldMetadata}
      />
      <HFormField
        name="CintasBusinessAddress"
        component={Textarea}
        maxLength={200}
        fieldMetadata={fieldMetadata}
      />
      <HFormField
        name="HasPartsOnSite"
        component={Picklist}
        fieldMetadata={fieldMetadata}
      />
      <HFormField
        name="PartsOnSiteDescription"
        component={Textarea}
        MaxLength={500}
        fieldMetadata={fieldMetadata}
      />
      {fieldMetadata.StoreContactHeader && (
        <h6>{fieldMetadata.StoreContactHeader.label}</h6>
      )}
      <HFormField
        name="RequestedByName"
        component={Input}
        fieldMetadata={fieldMetadata}
        maxLength={48}
        className="requestedby"
      />
      <HFormField
        name="StoreContactEmail"
        component={Input}
        maxLength={200}
        fieldMetadata={fieldMetadata}
        className="email"
      />
      <HFormField
        name="StoreContactPhone"
        component={Input}
        maxLength={32}
        fieldMetadata={fieldMetadata}
        className="phone"
      />
      <HFormField
        name="StoreContactMobile"
        component={Input}
        maxLength={32}
        fieldMetadata={fieldMetadata}
        className="phone"
      />
      {fieldMetadata.SelfPerformedTicket && (
        <div className="form-group selfperformed">
          <div className="col-sm-offset-3 col-sm-9">
            <HFormField
              name="SelfPerformedTicket"
              component={Checkbox}
              fieldMetadata={fieldMetadata}
            />
          </div>
        </div>
      )}
      <HFormField
        name="VendorContact"
        component={GraphqlLookup}
        fieldMetadata={fieldMetadata}
        lookupType="ParentContact"
      />
      {/*
          those are already selected on the "wizard", don't show them again
      <HFormField
        name="Area"
        component={Picklist}
        fieldMetadata={fieldMetadata}
        className="area"
      />
      <HFormField
        name="Category"
        component={Picklist}
        fieldMetadata={fieldMetadata}
        className="category"
        hidePicklistIfEmpty
      />
      */}
      <HFormField
        name="Issue"
        component={Picklist}
        fieldMetadata={fieldMetadata}
        className="issue"
        hidePicklistIfEmpty
      />
      <HFormField
        name="RoomFloorWorkstation"
        component={Input}
        maxLength={32}
        fieldMetadata={fieldMetadata}
        className="roomfloorworkstation"
      />
      {/* <HFormField name='Location' component={Picklist} fieldMetadata={fieldMetadata} /> */}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 767px) {
    flex-direction: column;
  }
  .checkbox {
    padding-top: 0;
  }
  h6 {
    text-align: center;
  }
  textarea {
    min-height: 120px;
  }
  & > div {
    padding-left: 5px;
    padding-right: 5px;
  }
`;

InsertFields.propTypes = {
  fieldMetadata: PropTypes.object.isRequired
};

export default InsertFields;
