// Container for the create ticket page
// Here we retrieve the lookup values, etc.
import {graphql, compose} from 'react-apollo';
import {connect} from 'react-redux';
import {withHandlers, withProps} from 'recompose';
import reduxForm from 'redux-form/lib/reduxForm';
import SubmissionError from 'redux-form/lib/SubmissionError';
import TicketInsertForm from '../components/TicketInsertForm';
import {confirmCancel, hideCancel, onTicketSaved} from '../actions/insert';
import defaultTicketValues from '../utils/defaultTicketValues';
import validateNewTicket from '../utils/validateNewTicket';
import insertFieldsMetadata from '../hoc/insertFieldsMetadata';
import loadingSpinner from '../../shared/hoc/loadingSpinner';
import {
  TICKET_LIST_QUERY,
  CREATE_TICKET_MUTATION,
  TICKET_LIST_VARIABLES
} from '../queries';
import {injectIntl} from 'react-intl';

export const FORM_NAME = 'TicketInsert';

// Container for the Ticket Insert Form (including the Redux Form container)
// Stuff that needs to sit outside of the form itself (because it wraps redux-form)

export default compose(
  connect(
    state => ({
      // list state that we need to be able to update the query
      sort: state.tickets.list.sort,
      filter: state.tickets.list.filter,
      showCancel: state.tickets.insert.showCancel,
      createdTicket: state.tickets.insert.createdTicket
    }),
    {
      confirmCancel,
      hideCancel,
      onTicketSaved
    }
  ),
  withHandlers({
    cancel: ({hideCancel, history}) => e => {
      hideCancel(e);
      history.push('/');
    },
    newStore: ({history, match}) => ({label}) => {
      console.log('IN NEWSTORE');
      history.push(match.url + '/new-store/' + encodeURIComponent(label));
    },
    goToTicket: ({createdTicket, history}) => e => {
      history.push('/ticket/' + createdTicket.id);
    }
  }),
  withProps(({currentUser}) => ({
    initialValues: defaultTicketValues(currentUser)
  })),
  injectIntl,
  insertFieldsMetadata,
  loadingSpinner,
  graphql(CREATE_TICKET_MUTATION, {
    options: {},
    props({ownProps: {intl, onTicketSaved}, mutate}) {
      return {
        // submit handler for redux-form
        onSubmit(vals) {
          return mutate({
            variables: {ticket: vals}
            // this ensures we refresh the ticket list
            // (not needed since we don't cache the query)
            // refetchQueries: [
            //   {
            //     query: TICKET_LIST_QUERY(),
            //     variables: TICKET_LIST_VARIABLES({filter, sort})
            //   }
            // ],
          }).then(
            r => {
              onTicketSaved(r.data.createTicket);
              // history.push('/ticket/' + r.data.createTicket.id + '/created');
            },
            r => {
              console.warn('Submission error', r);
              return Promise.reject(
                new SubmissionError({
                  _error: intl.formatMessage({id: 'tickets.insert_forms_error'})
                })
              );
            }
          );
        }
      };
    }
  }),
  reduxForm({
    form: FORM_NAME,
    validate: validateNewTicket
  })
  // withProps(props => {
  //   console.log('Got props', props);
  //   return {};
  // })
)(TicketInsertForm);
