import React from 'react';
import {compose} from 'recompose';
import {injectIntl} from 'react-intl';
import {Drawer, Divider, List, IconButton, ListItem} from '@material-ui/core';
import {
  AddOutlined as CreateWorkOrderIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  HomeOutlined as HomeIcon,
  NotificationsOutlined as NotificationsIcon,
  AssignmentOutlined as ListOutlinedIcon,
  HelpOutlineOutlined as HelpIcon,
  ExitToAppOutlined as LogoutIcon
} from '@material-ui/icons';
import {NavLink} from 'react-router-dom';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import withTicketsCount from '../../hoc/withTicketsCount';
import {Badge} from '../../components/Badge';

const drawerWidth = 140;

function DrawerMenu({
  open = false,
  handleDrawerClose = () => null,
  ticketsCount,
  currentUser,
  intl
}) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Drawer
      className={classes.drawer}
      variant={matches ? 'temporary' : 'persistent'}
      anchor="left"
      open={matches ? !open : open}
      classes={{paper: classes.drawerPaper}}>
      <div className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'ltr' ? (
            <ChevronLeftIcon fontSize="large" />
          ) : (
            <ChevronRightIcon fontSize="large" />
          )}
        </IconButton>
      </div>
      <Divider />
      <List>
        {MENU_DATA.filter(
          m => !m.permission || currentUser.permissions[m.permission]
        ).map(({title, icon, to, name}, key) => (
          <NavLink
            key={key}
            to={to}
            className={classes.link}
            exact
            activeClassName={classes.activeLink}>
            <ListItem button className={classes.listItem}>
              {icon}
              <div className={classes.listItemLabel}>
                {intl.formatMessage({id: title})}
                {name && ticketsCount[name] ? (
                  <Badge className={classes.badge}>{ticketsCount[name]}</Badge>
                ) : null}
              </div>
            </ListItem>
          </NavLink>
        ))}
      </List>
    </Drawer>
  );
}
export default compose(
  injectIntl,
  withTicketsCount
)(DrawerMenu);

const useStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  },
  badge: {
    position: 'absolute',
    top: 0,
    right: 5
  },
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 65,
    marginTop: 10,
    marginBottom: 10,
    borderBottom: 'solid 0.5px #ededed',
    position: 'relative'
  },
  listItemLabel: {
    fontFamily: 'Nunito',
    color: '#686b6b',
    fontSize: '1.2em',
    textAlign: 'center'
  },
  link: {
    marginTop: 10,
    marginBottom: 10
  },
  logoHeader: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  activeLink: {
    '& svg': {
      color: '#e4002b !important'
    }
  }
}));

const MENU_DATA = [
  {
    title: 'sidebar.home',
    icon: (
      <HomeIcon
        style={{color: '#686b6b', fontSize: '2.6rem'}}
        fontSize={'large'}
      />
    ),
    to: '/'
  },
  // TODO localize
  {
    title: 'sidebar.newwork',
    icon: (
      <CreateWorkOrderIcon
        style={{color: '#686b6b', fontSize: '2.6rem'}}
        fontSize={'large'}
      />
    ),
    to: '/tickets/new-ticket',
    permission: 'createTicket'
  },
  {
    title: 'sidebar.workorders',
    icon: (
      <ListOutlinedIcon
        style={{color: '#686b6b', fontSize: '2.6rem'}}
        fontSize={'large'}
      />
    ),
    to: '/tickets/open',
    permission: 'viewTickets'
  },
  {
    title: 'sidebar.actions_required',
    icon: (
      <NotificationsIcon
        style={{color: '#686b6b', fontSize: '2.6rem'}}
        fontSize={'large'}
      />
    ),
    to: '/tickets/action-required',
    name: 'ActionRequired',
    permission: 'viewTickets'
  },
  {
    title: 'sidebar.contact',
    icon: (
      <HelpIcon
        style={{color: '#686b6b', fontSize: '2.6rem'}}
        fontSize={'large'}
      />
    ),
    to: '/contact'
  },
  {
    title: 'sidebar.logout',
    icon: (
      <LogoutIcon
        style={{color: '#686b6b', fontSize: '2.6rem'}}
        fontSize={'large'}
      />
    ),
    to: '/logout'
  }
];
