import React from 'react';
import {branch} from 'recompose';
import {compose} from 'redux';
import {Redirect} from 'react-router-dom';
import appPage from './appPage';

export default compose(
  appPage,
  branch(
    ({
      currentUser: {
        permissions: {viewDashboard}
      }
    }) => !viewDashboard,
    () => props => <Redirect to="/tickets" />
  )
);
