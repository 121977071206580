import React from 'react';
import {Paper, ButtonBase} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

export default function ProblemItem({
  title = 'Drains',
  description = '',
  onClick = () => null
}) {
  const classes = useStyles();
  const handleClick = () => onClick(title);
  return (
    <div className={classes.container} onClick={handleClick}>
      <div className={classes.line} />
      <div className={classes.textContainer}>
        <h3 className={classes.title}>{title}</h3>
        {description && <p className={classes.description}>{description}</p>}
      </div>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
    // marginTop: 10,
    // marginBottom: 10
  },
  line: {
    marginLeft: 3,
    height: '90%',
    width: 3,
    backgroundColor: '#e4002b'
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: 10
  },
  title: {
    fontFamily: 'Nunito',
    fontSize: '1.3rem',
    lineHeight: 1.36,
    color: '#686b6b',
    margin: 0
  },
  description: {
    fontFamily: 'Nunito',
    fontSize: '1rem',
    lineHeight: 1.38,
    color: '#686b6b'
  },
  selectButton: {
    minHeight: 18,
    padding: '2.5px 9.5px 2.5px 10px',
    borderRadius: 12.5,
    border: 'solid 0.5px #e4002b',
    backgroundColor: '#ffffff',
    fontFamily: 'Nunito',
    fontSize: '1.2rem',
    lineHeight: 1.36,
    color: '#e4002b'
  }
}));
