import React from 'react';
import PropTypes from 'prop-types';

import SelectionList from '../../../shared/components/inputs/SelectionList';

class ProblemSelection extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(val) {
    if (val) {
      if (this.props.onChangeAction) {
        this.props.onChangeAction(val);
      }
      if (this.props.input.onChange) {
        this.props.input.onChange(val);
      }
    }
  }

  render() {
    const props = this.props;
    const {picklist} = props.fieldMetadata;
    if (!picklist || !picklist.length) return null;
    return (
      <div style={{width: '100%'}}>
        <SelectionList
          items={picklist}
          onChange={this.onChange}
          value={props.input.value}
        />
      </div>
    );
  }
}
ProblemSelection.propTypes = {
  fieldMetadata: PropTypes.shape({
    picklist: PropTypes.array
  }).isRequired,
  // optional change action
  onChangeAction: PropTypes.func,
  // input props (via redux form)
  input: PropTypes.shape({
    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    // will be used as name of input and to get the field metadata
    name: PropTypes.string.isRequired
  }).isRequired
};

export default ProblemSelection;
